import React, { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import apiService from "../services/ApiService";
import formatDate from "../utils/Dateformat";
import TableLoader from "./TableLoader";
import CardLoader from "./CardLoader";
import Header2 from "./Header2";
import Footer from "./Footer";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Bottomheader from "../Components/Bottomheader"
export default function Portfolio() {
  const [Data, setData] = useState([]);
  const [UserPlanList, setUserPlanList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [LoaderPlanList, setLoaderPlanList] = useState(false);
  const [LoaderUser, setLoaderUser] = useState(false);
  const [perPageItem, setperPageItem] = useState(0);
  const [PortfolioDetails,setPortfolioDetails] = useState([])
  const navigate = useNavigate();

  const fetchPlanList = async () => {
    try {
      setLoaderPlanList(true);
      setLoaderUser(true);
      const res = await apiService().get(`planList`);
      setLoaderPlanList(false);
      setData(res.data.data);
    } catch (error) {
      setLoaderPlanList(false);
      setLoaderUser(false);
      console.log(error);
      toast.error(error);
    }
  };

  const fetchUserPlanList = async () => {
    try {
      setLoaderUser(true);
      const res = await apiService().get(`userPlanPortfolio?page=${page}`);
      setLoaderUser(false);
      setUserPlanList(res.data.data);
      setperPageItem(res.data.perPage);
      setTotalPage(res.data.lastPage);
    } catch (error) {
      setLoaderUser(false);
      console.log(error);
      toast.error(error);
    }
  };

  const UserPortfolioDetail = async () => {
    try {
      const res = await apiService().get(`userPortfolioDetail`);
      setPortfolioDetails(res.data)
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };

  useEffect(() => {
    fetchPlanList();
    UserPortfolioDetail()
  }, []);

  useEffect(() => {
    fetchUserPlanList();
  }, [page]);

  const pageChange = (event, value) => {
    setPage(value);
  };

  return (
    <>
      <div>
        <Header2></Header2>
        {/* header */}

        {/*=======Banner-Section Starts Here=======*/}

        <section className="hero-section-2 home-2banner">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-6 col-xl-8">
                <div className="call-item text-center text-sm-left justify-content-center">
                  <div className="call-content text-center">
                    <h1>Hi {localStorage.getItem("name") || ""}</h1>
                    <p className="title">
                      You haven’t made any investments yet. Explore our expertly
                      curated strategies to begin your investment journey.
                    </p>
                    <Link to="/Strategies" className="custom-button mt-3">
                      Explore Strategies <i className="flaticon-right "></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="get-section padding-top ">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12 col-lg-10">
                <div className="card px-4 p-3">
                  <h3 className="text-center mb-3">Your WYscale Portfolio</h3>
                  <div className="row">
                    <div className="col-4 col-sm-4 col-md-4 ">
                      <div className="d-flex justify-content-center">
                        <img
                          src="./assets/images/icon/invested.png"
                          className="investimage"
                        ></img>
                        <div className="">
                          <p className="text-xl text-purple-1000 invested">
                            Invested Value
                          </p>

                          <p className=" investcontent">${PortfolioDetails?.invested_value ? PortfolioDetails?.invested_value.toFixed(2) : 0}</p>
                          {/* <p className="font-semibold investcontent1">$86.41</p> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-4 col-sm-4 col-md-4 ">
                      <div className="d-flex  justify-content-center">
                        <img
                          src="./assets/images/icon/current.png"
                          className="investimage"
                        ></img>
                        <div className="">
                          <p
                            className="text-xl text-purple-1000 invested1"
                            style={{ color: "#ffb100" }}
                          >
                            Current Interest
                          </p>

                          <p className=" investcontent">$ 
                          {PortfolioDetails?.current_intrest ? PortfolioDetails?.current_intrest.toFixed(2) : 0}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-4 col-sm-4 col-md-4">
                      <div className="d-flex  justify-content-center">
                        <img
                          src="./assets/images/icon/gain.png"
                          className="investimage"
                        ></img>
                        <div className="">
                          <p
                            className="text-xl text-purple-1000 invested2"
                            style={{ color: "green" }}
                          >
                            Total Gain
                          </p>

                          <p className=" investcontent">
                          {PortfolioDetails?.total_gain ? PortfolioDetails?.total_gain.toFixed(2) : 0}% </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center mt-4">
              <Link to="/Dashboard">
                <button className="custom-button">View Investment </button>
              </Link>
            </div>
          </div>
        </section>
        {/*=======Banner-Section Ends Here=======*/}
        {/*=======Feature-Section Starts Here=======*/}
        <section className="offer-section padding-top padding-bottom pb-max-md-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-xl-7">
                <div className="section-header padding-bottom">
                  <span className="cate">Top Performing Strategies</span>
                  {/* <h2 className="title">OUR INVESTMENT PLANS</h2> */}
                  <p>
                    Investing in the Crypto market can be a daunting task, but
                    it can also be a lucrative one if you have a solid
                    investment plan.
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              {!LoaderPlanList && Data?.length > 0 ? (
                Data.map((item, idx) => (
                  <div
                    key={idx}
                    className=" col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4  "
                  >
                    <div className="card fundcard">
                      <div className="card-header1">
                        <img
                          src={item.name.toLowerCase()==="weekly" ? './assets/images/icon/weekly.png':item.name.toLowerCase()==="monthly"?"./assets/images/icon/monthly.png":"./assets/images/icon/long-term.png"}
                          className="strategyimage"
                          style={{ width: "60px" }}
                        ></img>
                      </div>
                      <div className="row offer-header1">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0">
                          <h6 className="text-center" >{item.name}</h6>
                          {/* <p className="text-truncate">{item.description}</p> */}
                        </div>
                      </div>
                      <div className="row offer-header1 py-0">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0">
                          <div className="row">
                            <div className=" col-6 col-sm-6  col-md-6 col-lg-6 col-xl-6 ">
                              <div className="w-[calc(50%_-_12px)]">
                                <div className="bg-gray-3000 rounded-xl py-2 px-2 w-full">
                                  <p className="text-xxs opacity-60 text-center">
                                    Base APY
                                  </p>
                                  <div className="flex items-center gap-1 font-semibold text-green-1200">
                                    <span className="fundcard-span">
                                     {item.base_apy}%
                                    </span>
                                    <svg
                                      stroke="currentColor"
                                      fill="currentColor"
                                      strokeWidth="0"
                                      viewBox="0 0 1024 1024"
                                      height="1em"
                                      width="1em"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M868 545.5L536.1 163a31.96 31.96 0 0 0-48.3 0L156 545.5a7.97 7.97 0 0 0 6 13.2h81c4.6 0 9-2 12.1-5.5L474 300.9V864c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V300.9l218.9 252.3c3 3.5 7.4 5.5 12.1 5.5h81c6.8 0 10.5-8 6-13.2z"></path>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className=" col-6 col-sm-6  col-md-6 col-lg-6 col-xl-6">
                              <div className="w-[calc(50%_-_12px)]">
                                <div className="bg-gray-3000 rounded-xl py-2 px-2 w-full">
                                  <p className="text-xxs opacity-60 text-center">
                                    Max APY
                                  </p>
                                  <div className="flex items-center gap-1 font-semibold text-green-1200">
                                    <span className="fundcard-span">
                                      {item.max_apy}%
                                    </span>
                                    <svg
                                      stroke="currentColor"
                                      fill="currentColor"
                                      strokeWidth="0"
                                      viewBox="0 0 1024 1024"
                                      height="1em"
                                      width="1em"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M868 545.5L536.1 163a31.96 31.96 0 0 0-48.3 0L156 545.5a7.97 7.97 0 0 0 6 13.2h81c4.6 0 9-2 12.1-5.5L474 300.9V864c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V300.9l218.9 252.3c3 3.5 7.4 5.5 12.1 5.5h81c6.8 0 10.5-8 6-13.2z"></path>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row offer-header1">
                        <div className="col-md-12 d-flex p-0 justify-content-center">
                          <img
                            src="./assets/images/icon/AAVE.png"
                            className="w-8 h-8 rounded-lg"
                          ></img>
                          <img
                            src="./assets/images/icon/LINK.png"
                            className="w-8 h-8 rounded-lg"
                          ></img>
                          <img
                            src="./assets/images/icon/MKR.png"
                            className="w-8 h-8 rounded-lg"
                          ></img>
                          <img
                            src="./assets/images/icon/UNI.png"
                            className="w-8 h-8 rounded-lg"
                          ></img>
                          <img
                            src="./assets/images/icon/USDC.png"
                            className="w-8 h-8 rounded-lg"
                          ></img>
                        </div>
                      </div>
                      <div className="row offer-header1 py-2">
                        <div className="offer-footer mb-2 w-100">
                          <div className="d-flex flex1">
                            <div className="d-block my-auto mx-0">
                              <p className="ppp">Minimum Investment:</p>
                              <p className="pppp">${item.amount}</p>
                            </div>

                            <button
                              onClick={() =>
                                navigate("/Innerstrategy", {
                                  state: { data: item },
                                })
                              }
                              className="investa investnowbtn"
                            >
                              View Details<i className="flaticon-right m-2"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <>
                  {!LoaderPlanList && Data?.length == 0 ? (
                    <tr>
                      <td colSpan="3">No data available</td>
                    </tr>
                  ) : (
                    <CardLoader />
                  )}
                </>
              )}
            </div>
          </div>
        </section>
        {/*=======Feature-Section Ends Here=======*/}
        <section className="padding-bottom">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10 col-lg-8">
                <div className="section-header">
                  <span className="cate">Your Transactions</span>
                  {/* <h2 className="title">how we work?</h2>
    <p>Follow these simple steps and make profit!</p> */}
                </div>
              </div>
            </div>
            <div className="table-wrapper">
              <table className="transaction-table">
                <thead>
                  <tr>
                    <th>Sr.No.</th>
                    <th>Time</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Remark</th>
                    {/* <th>Strategy</th> */}
                  </tr>
                </thead>
                <tbody>
                  {!LoaderUser && UserPlanList?.length > 0 ? (
                    UserPlanList.map((item, idx) => (
                      <tr key={idx}>
                        <td className="text-truncate" > {page * perPageItem - perPageItem + idx + 1}</td>
                        <td className="text-truncate">{formatDate(item.start_date)}</td>
                        <td className="text-truncate">{item.amount}</td>
                        <td className="text-truncate">
                          {item.is_completed == 1 ? (
                            <p className="text-success">Success </p>
                          ) : (
                            <span className=" progresstab">Active </span>
                          )}
                        </td>
                        <td className="text-truncate">Deposit</td>
                        {/* <td>
                          <button
                            onClick={() =>
                              navigate("/Innerstrategy", {
                                state: { data: item },
                              })
                            }
                            className="text-info"
                          >
                            View Strategy
                          </button>
                        </td> */}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5">
                        {!LoaderUser && UserPlanList?.length == 0 ? (
                          "No data available"
                        ) : (
                          <TableLoader />
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {!LoaderUser && UserPlanList?.length > 0 ? (
                <Pagination
                  page={page}
                  count={totalPage}
                  onChange={pageChange}
                />
              ) : null}
            </div>
          </div>
        </section>
        <section className=" padding-bottom bgchange">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10 col-lg-8">
                <div className="section-header">
                  <span className="cate">Your KYC Status</span>
                  {/* <h2 className="title">how we work?</h2>
    <p>Follow these simple steps and make profit!</p> */}
                </div>
              </div>
            </div>
            <div className="hover-tab">
              <div className="row justify-content-center">
                <div className="col-lg-6 d-lg-block d-none">
                  <div className="hover-tab-area">
                    <div className="tab-area">
                      <div className="tab-item active first">
                        <img src="./assets/images/how/how01.png" alt="how" />
                      </div>
                      <div className="tab-item second">
                        <img src="./assets/images/how/how02.png" alt="how" />
                      </div>
                      <div className="tab-item third">
                        <img src="./assets/images/how/how03.png" alt="how" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-9">
                  <div className="hover-tab-menu">
                    <ul className="tab-menu">
                      <li className="active">
                        <div className="menu-thumb">
                          <span>01</span>
                        </div>
                        <div className="menu-content">
                          <h5 className="title">Sign Up</h5>
                          <p className="emailpara">
                            Sign up and get verified with our KYC process
                          </p>
                          <p>
                            <a href="/signup" className="custom-button1">
                              Sign Up <i className="flaticon-right"></i>
                            </a>
                            <span className="bg-green-1200 text-green-1200 rounded-3xl py-1 px-2 text-xs bg-opacity-10">
                              Completed
                            </span>
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="menu-thumb">
                          <span>02</span>
                        </div>
                        <div className="menu-content">
                          <h5 className="title"> Email</h5>
                          <p className="emailpara">Email Address :</p>
                          <a
                            type="email"
                            className="email"
                          >
                            {localStorage.getItem('email') ? localStorage.getItem('email'):'--'}
                          </a>
                          {/* <p>
                            <a href="/signup" className="custom-button1">
                              Send Confrim Email
                              <i className="flaticon-right"></i>
                            </a>
                          </p> */}
                        </div>
                      </li>
                      <li>
                        <div className="menu-thumb">
                          <span>03</span>
                        </div>
                        <div className="menu-content">
                          <h5 className="title">Verify Identity</h5>
                          <p className="emailpara">Go to the KVC Process</p>
                          <p>
                            <a href="/signup" className="custom-button1 ">
                              Provide KYC<i className="flaticon-right"></i>
                            </a>
                            <span className="bg-green-1200 text-green-1200 rounded-3xl py-1 px-2 text-xs bg-opacity-10">
                              Completed
                            </span>
                            <span className="bg-red-1200 text-red-1200 rounded-3xl py-1 px-2 text-xs bg-opacity-10">
                              Pending
                            </span>
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="faq-section pt-3  padding-bottom mb-xl-5">
          <div
            className="ball-group-1"
            data-paroller-factor="-0.30"
            data-paroller-factor-lg="0.60"
            data-paroller-type="foreground"
            data-paroller-direction="horizontal"
          >
            <img src="./assets/images/balls/ball-group7.png" alt="balls" />
          </div>
          <div
            className="ball-group-2 rtl"
            data-paroller-factor="0.30"
            data-paroller-factor-lg="-0.30"
            data-paroller-type="foreground"
            data-paroller-direction="horizontal"
          >
            <img src="./assets/images/balls/ball-group8.png" alt="balls" />
          </div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-10">
                <div className="section-header padding-bottom">
                  <span className="cate">You have questions</span>

                  <p className="mw-100">
                    Do not hesitate to send us an email if you can't find what
                    you're looking for.
                  </p>
                </div>
              </div>
            </div>
            <div className="tab faq-tab">
              <ul className="tab-menu">
                <li>BASIC </li>
                <li className="active">FINANCIAL</li>
              </ul>
              <div className="tab-area">
                <div className="tab-item">
                  <div className="faq-wrapper">
                    <div className="faq-item">
                      <div className="faq-title">
                        <h5 className="title">What is WYscale?</h5>
                        <span className="right-icon"></span>
                      </div>
                      <div className="faq-content">
                        <p>
                          WyScale is a DApp based Crypto Mutual Fund manager.
                          Wyscale is fully decentralised and provides
                          transparent investing options without a hassle.
                          Directly connect your wallet and get started with
                          crypto SIP and other funds.
                        </p>
                      </div>
                    </div>
                    <div className="faq-item active open">
                      <div className="faq-title">
                        <h5 className="title">
                          Is there a SIP in cryptocurrency?
                        </h5>
                        <span className="right-icon"></span>
                      </div>
                      <div className="faq-content">
                        <p>
                          Like stocks and mutual funds, you can now invest in
                          cryptos as well through SIP. WYscale lets you invest
                          in crypto baskets through SIP, try it here!
                        </p>
                      </div>
                    </div>
                    <div className="faq-item">
                      <div className="faq-title">
                        <h5 className="title">What does SIP mean in crypto?</h5>
                        <span className="right-icon"></span>
                      </div>
                      <div className="faq-content">
                        <p>
                          SIP in crypto means investing in a crypto or a basket
                          of cryptos on a daily, weekly or monthly basis.
                        </p>
                      </div>
                    </div>
                    <div className="faq-item">
                      <div className="faq-title">
                        <h5 className="title">
                          Is it good to do SIP in cryptocurrency?
                        </h5>
                        <span className="right-icon"></span>
                      </div>
                      <div className="faq-content">
                        <p>
                          SIP can be a great approach to average the purchase
                          price and, to some extent, lessen the exposure to
                          volatility, especially for newcomers to the
                          cryptocurrency market and risk-averse investors.
                        </p>
                      </div>
                    </div>
                    <div className="faq-item">
                      <div className="faq-title">
                        <h5 className="title">
                          How do I invest in crypto SIP in a decentralised way?
                        </h5>
                        <span className="right-icon"></span>
                      </div>
                      <div className="faq-content">
                        <p>
                          WYscale have launched SIP in crypto. You can connect
                          your wallet and start investing with as low as $50.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-item active">
                  <div className="faq-wrapper">
                    <div className="faq-item">
                      <div className="faq-title">
                        <h5 className="title">
                          What is decentralised finance (DeFi)?
                        </h5>
                        <span className="right-icon"></span>
                      </div>
                      <div className="faq-content">
                        <p>
                          Decentralised finance—often called DeFi refers to the
                          economic paradigm shift enabled by blockchain
                          networks. From streamlined and secure payment networks
                          to automated loans to USD-pegged stable coins,
                          decentralised finance has emerged as one of the most
                          active sectors in the blockchain space.
                        </p>
                      </div>
                    </div>
                    <div className="faq-item active open">
                      <div className="faq-title">
                        <h5 className="title">What is a wallet?</h5>
                        <span className="right-icon"></span>
                      </div>
                      <div className="faq-content">
                        <p>
                          A blockchain wallet contains the public key for others
                          to transfer cryptocurrency to your address and the
                          private key so you can securely access your own
                          digital assets.
                        </p>
                      </div>
                    </div>
                    <div className="faq-item">
                      <div className="faq-title">
                        <h5 className="title">
                          Are blockchain and cryptocurrencies the same?
                        </h5>
                        <span className="right-icon"></span>
                      </div>
                      <div className="faq-content">
                        <p>
                          No. Blockchain is the technology that allows for
                          cryptocurrencies to work. It is important to separate
                          the technology behind cryptocurrencies from the actual
                          cryptocurrencies.
                        </p>
                      </div>
                    </div>
                    <div className="faq-item">
                      <div className="faq-title">
                        <h5 className="title">
                          What is the difference between crypto, coin and token?
                        </h5>
                        <span className="right-icon"></span>
                      </div>
                      <div className="faq-content">
                        <ul>
                          <li>
                            <b>Crypto</b> — It is an umbrella term for all
                            digital currencies
                          </li>
                          <li>
                            <b>Coins</b> — Cryptocurrency that has its own
                            separate blockchain. Example: ETH, BNB, SOL, etc.
                          </li>
                          <li>
                            <b>Tokens</b> — Cryptocurrency that is built on top
                            of existing blockchain, e.g., projects issue their
                            tokens on using token standards such as ERC-20,
                            BEP-20, etc.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="faq-item">
                      <div className="faq-title">
                        <h5 className="title">Can cryptocurrencies fail?</h5>
                        <span className="right-icon"></span>
                      </div>
                      <div className="faq-content">
                        <p>
                          Yes. It is estimated that more than 2,000
                          cryptocurrencies have failed. This is for a variety of
                          reasons: lack of funding at start and after launch,
                          failure to evolve, and a few were outright frauds. One
                          should always do proper research before investing
                        </p>
                      </div>
                    </div>
                    <div className="faq-item">
                      <div className="faq-title">
                        <h5 className="title">
                          Is there security like an exchange-traded fund (ETF),
                          mutual fund or SIP that I could purchase?
                        </h5>
                        <span className="right-icon"></span>
                      </div>
                      <div className="faq-content">
                        <p>
                          These products are just starting to come to the
                          marketplace. The design of these products is to gain
                          exposure to cryptocurrencies like Bitcoin and Ethereum
                          without having to directly purchase. WYscale is a
                          platform that lets you INVEST in cryptocurrency.
                        </p>
                      </div>
                    </div>
                    <div className="faq-item active open">
                      <div className="faq-title">
                        <h5 className="title">Can I exit anytime?</h5>
                        <span className="right-icon"></span>
                      </div>
                      <div className="faq-content">
                        <p>
                          Investing in mutual fund, crypto basket & SIP through
                          WYscale have a lock in period of 3 months.
                        </p>
                      </div>
                    </div>
                    <div className="faq-item">
                      <div className="faq-title">
                        <h5 className="title">
                          What is the minimum amount I can invest?
                        </h5>
                        <span className="right-icon"></span>
                      </div>
                      <div className="faq-content">
                        <p>The minimum amount you can invest via WYscale is:</p>
                        <ul>
                          <li>1. $50 in SIP.</li>
                          <li>2. No other minimum amount criteria</li>
                        </ul>
                      </div>
                    </div>
                    <div className="faq-item">
                      <div className="faq-title">
                        <h5 className="title">
                          How do I add fund in my wallet?
                        </h5>
                        <span className="right-icon"></span>
                      </div>
                      <div className="faq-content">
                        <p>
                          There are a number of option for adding funds in your
                          wallet:
                        </p>
                        <ul>
                          <li>
                            1. Through credit/debit card: Depending on where you
                            live, you may be able to purchase Ethereum directly
                            on Metamask or other wallet using a credit card or
                            debit card using a third-party service like MoonPay
                            or Wyre to facilitate the transaction. These
                            generally include Know Your Customer (KYC)
                            requirements and filling in your credit card
                            information.
                          </li>
                          <li>
                            2. Through an exchange: You can fund your by making
                            a deposit from a reputable exchange such as FTX,
                            Coinbase, or Binance. Crypto can be bought on the
                            exchange and deposited into Metamask.
                          </li>
                          <li>
                            3. Peer-to-peer: Peer-to-peer refers to the direct
                            exchange of some asset, such as a digital currency,
                            between individual parties without the involvement
                            of a central authority. For example: you can buy
                            crypto from an individual directly at a set price.
                            You just need to send him/her your wallet address &
                            then pay the equivalent amount in Fiat.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="faq-item">
                      <div className="faq-title">
                        <h5 className="title">
                          Can I use hardware wallet with Metamask or other
                          browser wallet?
                        </h5>
                        <span className="right-icon"></span>
                      </div>
                      <div className="faq-content">
                        <p>
                          Yes. MetaMask currently supports{" "}
                          <Link to="Trezor, Ledger, Lattice, Keystone, and AirGap Vault">
                            Trezor, Ledger, Lattice, Keystone, and AirGap Vault
                          </Link>{" "}
                          on Extension. You can check out hardware wallet
                          support provided by your preferred wallet as well.
                        </p>
                      </div>
                    </div>
                    <div className="faq-item">
                      <div className="faq-title">
                        <h5 className="title">
                          Is WYscale centralised or decentralised?
                        </h5>
                        <span className="right-icon"></span>
                      </div>
                      <div className="faq-content">
                        <p>
                          Wyscale is a decentralised platform that allows anyone
                          in the world to invest in cryptocurrencies and other
                          digital assets.
                        </p>
                      </div>
                    </div>
                    <div className="faq-item">
                      <div className="faq-title">
                        <h5 className="title">
                          What is the fee charged by WYscale?
                        </h5>
                        <span className="right-icon"></span>
                      </div>
                      <div className="faq-content">
                        <p>
                          The gas fee charged by different chains is different
                          and can vary from time to time. The commission fee
                          charged by WYscale varies from 2-10%.
                        </p>
                      </div>
                    </div>
                    <div className="faq-item">
                      <div className="faq-title">
                        <h5 className="title">
                          On what chains is Wyscale live?
                        </h5>
                        <span className="right-icon"></span>
                      </div>
                      <div className="faq-content">
                        <p>
                          We are live on Wyzth, Tron, Polygon, BSC, and Solana
                        </p>
                      </div>
                    </div>
                    <div className="faq-item">
                      <div className="faq-title">
                        <h5 className="title">
                          Which wallets are supported by WYscale?
                        </h5>
                        <span className="right-icon"></span>
                      </div>
                      <div className="faq-content">
                        <p>Metamask, Tronlink pro, and Wywallet</p>
                      </div>
                    </div>
                    <div className="faq-item">
                      <div className="faq-title">
                        <h5 className="title">
                          Where can I view the transaction history of my
                          connected address?
                        </h5>
                        <span className="right-icon"></span>
                      </div>
                      <div className="faq-content">
                        <ul>
                          <li>
                            1. Wyzth and WRC20 tokens [WyScan]: (wyzthscan.org)
                            & legacy.wyzthscan.org
                          </li>
                          <li>
                            2. Ethereum & ERC20 tokens: Etherscan & Ethplorer
                          </li>
                          <li>3. Polygon: Polygonscan</li>
                          <li> 4. Bitcoin: blockchain.com & blockstream</li>
                          <li>5. Solana: Solscan</li>
                        </ul>
                      </div>
                    </div>
                    <div className="faq-item">
                      <div className="faq-title">
                        <h5 className="title">
                          What types of investment opportunity is provided by
                          WYscale?
                        </h5>
                        <span className="right-icon"></span>
                      </div>
                      <div className="faq-content">
                        <p>
                          SIP, Market maker fund , New token straight investment
                          fund , liquidity providing fund , Upcoming Enhancer
                          fund , Fixed APY fund , Moderate Crypto Fund , Large
                          Cap fund , Mid Cap fund , Small cap fund, etc.
                        </p>
                        <p>
                          Read all about it here. (Add a link to “Understanding
                          Crypto mutual fund”)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Bottomheader id={0}/>
      <Footer></Footer>
    </>
  );
}
