import { Dialog, Slide } from '@mui/material';
import React, { useState } from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
function ConfirmationDialog({ message, onConfirm, onCancel }) {
  const [isOpen, setIsOpen] = useState(true);

  const handleConfirm = () => {
    setIsOpen(false);
    onConfirm();
  };

  const handleCancel = () => {
    setIsOpen(false);
    onCancel();
  };

  return (
    <>
    <Dialog 
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      id="2"
    >
    <div className="miu-dialog">
      <div className="miu-dialog__message">{message}</div>
      <div className="miu-dialog__buttons">
        <button onClick={handleCancel}>No</button>
        <button onClick={handleConfirm}>Yes</button>
      </div>
    </div>
    </Dialog>
    </>
  );
}

export default ConfirmationDialog;
