import { Dialog, DialogContent, DialogTitle, Slide } from '@mui/material'
import React from 'react'
import { ethers } from 'ethers'
import { toast } from 'react-toastify';
import { useState } from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function WalletAddressForm({ open,onCloseWalletAddressForm }) {

    const [getAddress, setAddress] = useState('')

    function onsubmit() {
        if (ethers.utils.isAddress(getAddress)) {
            onCloseWalletAddressForm(getAddress)
        } else {
            toast.error('please enter valid address')
        }
    }
    return (
      
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
            id="3"
        >
           
            <DialogContent>
                <div className='card py-2 px-3 border-0' >
                <div  className='cardheadingflex' >
                <h2>Wallet Address</h2>
                <span onClick={()=>onCloseWalletAddressForm(null)}><i className="fa-regular fa-circle-xmark"></i></span>
                </div>
                <div className="row m-0 w-100 py-4">
                    <div className='container'>
                        <div className='form-group'>
                            <input type='text' placeholder='enter your wallet address' className='form-control' onChange={(e) => setAddress(e.target.value)} />
                        </div>
                        <div className='form-group'>
                            <button className='custom-button' onClick={onsubmit}>submit</button>
                        </div>
                    </div>
                </div>
                </div>
            </DialogContent>
        </Dialog>
      
    )
}

export default WalletAddressForm