import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modals from "./Modals";
function Header() {
  const [openModal, setModal] = useState(false);
  const [isLogin, setLogin] = useState(false);
  const [displayName,setDisplayName] = useState([0,0,0])
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("token")) {
      setLogin(true);
    }
  }, []);

  const handleClick = (id) => {
    let data = [0,0,0]
    data[id] = 1
    setDisplayName(data)
  }

  return (
    <>
      {openModal === true ? (
        <Modals open={true} setModal={setModal} id="6" />
      ) : null}

      <div>
        {/*=======Header-Section Starts Here=======*/}
        <header className="header-section">
          <div className="header-top">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-6">
                  {/* <ul className="support-area">
                    <li>
                      <a href="tel:18001204099 ">
                        <i className="flaticon-support" />
                        Support : 18001204099{" "}
                      </a>
                    </li>
                  </ul> */}
                </div>
                {/* <div className="col-6">
                  <ul className="cart-area">
                    {isLogin == false ? (
                      <>
                        <li>
                          <Link to="/signin">Sign In</Link>
                        </li>
                        <li>
                          <Link to="/signup">Sign Up</Link>
                        </li>
                      </>
                    ) : (
                      <li
                        className="logout2"
                        onClick={() => (
                          localStorage.clear(), setLogin(false), navigate("/")
                        )}
                      >
                        Logout
                      </li>
                    )}
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
          <div className="header-bottom">
            <div className="container">
              <div className="header-area">
                <div className="logo">
                  <a href="/">
                    <img src="./assets/images/logo/logo.png" alt="logo" />
                  </a>
                </div>
                <ul className="menu m-auto">
                  {isLogin == true ? (
                    <>
                      <li>
                        {" "}
                        <Link to="/Portfolio">Portfolio</Link>{" "}
                      </li>
                      <li>
                        {" "}
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                    </>
                  ) : (
                    <>
                      {/* <li>
                        <Link to="/">Home</Link>{" "}
                      </li>

                      <li>
                        <Link to="/about">About WYscale</Link>
                      </li> */}
                    </>
                  )}

                  {/* <li>
                    <Link to="/strategies">Strategies</Link>
                  </li> */}

                  {/* <li>
                      <Link to='/faq'>Faqs</Link>
                    </li> */}
                  {/* <li>
                      <Link to='/contact'>Contact</Link>
                    </li> */}
                </ul>
                {/* <ul className="mobileheader">
                  <li className="pr-0 my-auto mx-2">
                    <a
                      onClick={() => setModal(true)}
                      className="custom-button text-white"
                    >
                      download now
                    </a>
                  </li>

                  <li className="pr-0 profileviewmobile">
                    {isLogin == false ? (
                      <> 
                        <li className="d-none">
                          <Link to="/signin"  ></Link>
                        </li>
                      </>
                    ) : (
                      <li className="pr-0">
                         <Link to="/Profile" >
                          <img
                            src="/assets/images/icon/profilemobile.gif"
                            width={"30px"} 
                            style={{borderRadius:"50px"}}
                          ></img>
                        </Link>
                      </li>
                    )}
                  </li>
                </ul> */}
                {/* <div className="header-bar d-lg-none">
                  <span />
                  <span />
                  <span />
                </div> */}
              </div>
            </div>
          </div>
        </header>
        <div className="Fixed-bottom">
        {isLogin == false ? (
          <>
            <p className="d-flex mb-0" style={{justifyContent:"space-evenly"}} >If you are not Login Please Login First <Link to="/signin" ><button className="custom-button"  >Login</button></Link> </p>
          </>
        ) : (
          <header >
            <div className="container">
              <div className="row">
                <div className="col-md-4 col-4 text-center">
                  <Link to="/Portfolio" className="headerfont">
                  {
                         displayName[0] ? null  : 
                         <img
                         src="/assets/images/icon/Stratery.png"
                         width={"25px"}
                         className="m-auto"
                         onClick={() => handleClick(0)}
                       ></img>
                    }
                   
                    <p className="text-center"> {displayName[0] ? "Portfolio" : ""}</p>
                  </Link>
                </div>
                <div className="col-md-4 col-4 text-center">
                  <Link to="/Strategies" className="headerfont">
                    {
                         displayName[1] ? null  : 
                         <img
                         src="/assets/images/icon/Stratery.png"
                         width={"25px"}
                         className="m-auto"
                         onClick={() => handleClick(1)}
                       ></img>
                    }
                  
                    <p className="text-center"> {displayName[1] ? "Strategies" : ""} </p>
                  </Link>
                </div>
                <div className="col-md-4 col-4 text-center">
                  <Link to="/Dashboard" className="headerfont">
                    {
                      displayName[2] ? null  : <img
                      src="/assets/images/icon/dashboardicon.png"
                      width={"25px"}
                      className="m-auto"
                      onClick={() => handleClick(2)}
                    ></img> 
                    }
                   
                    <p className="text-center">{displayName[2] ? "Dashboard" : ""} </p>
                  </Link>
                </div>
              </div>
            </div>
          </header>
        )}
        </div>
        {/* <header className="Fixed-bottom">
          <div className="container">
          <div className="row">
              <div className="col-md-4 col-4 text-center">
                <Link to="/Portfolio" className="headerfont">
                  <img
                    src="/assets/images/icon/Portpolio.png"
                    width={"25px"}
                    className="m-auto"
                  ></img>
                  <p className="text-center"> Portfolio</p>
                </Link>
              </div>
              <div className="col-md-4 col-4 text-center">
                <Link to="/Strategies" className="headerfont">
                  <img
                    src="/assets/images/icon/Stratery.png"
                    width={"25px"}
                    className="m-auto"
                  ></img>
                  <p className="text-center"> Strategies</p>
                </Link>
              </div>
              <div className="col-md-4 col-4 text-center">
                <Link to="/Dashboard" className="headerfont">
                  <img
                    src="/assets/images/icon/dashboardicon.png"
                    width={"25px"}
                    className="m-auto"
                  ></img>
                  <p className="text-center"> Dashboard</p>
                </Link>
              </div>
            </div>
          </div>
        </header> */}
      </div>
    </>
  );
}

export default Header;
