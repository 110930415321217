import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Sidebar from "./Sidebar";
import DashboardHeader from "./DashboardHeader";
import apiService from "../services/ApiService";
import formatDate from "../utils/Dateformat";
import TableLoader from "./TableLoader";
import Pagination from "@mui/material/Pagination";
import Modals from "./Modals";
import Balance from "./Balance";
import { toast } from "react-toastify";

export default function Support() {
  const [openModal, setModal] = useState(false);
  const [page, setPage] = useState(1);
  const [Data, setData] = useState([]);
  const [Loader, setLoader] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [perPageItem, setperPageItem] = useState(0);
  const [isApiCall,setIsApiCall] = useState(false)

 

  const UserTicketList = async () => {
    try {
      setLoader(true);
      const res = await apiService().get(`userTicketList?page=${page}`);
      setLoader(false);
      setData(res.data.data);
      setTotalPage(res.data.lastPage);
      setperPageItem(res.data.perPage);
    } catch (error) {
      setLoader(false);
      console.log(error);
      toast.error(error);
    }
  };

  const pageChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    UserTicketList();
  }, [page]);

  useEffect(() => {
    if(isApiCall) {
      UserTicketList();
      setIsApiCall(false)
     }  
  }, [isApiCall]);

  return (
    <>
      {openModal === true ? (
        <Modals open={true} isApiCallforSupport={setIsApiCall} setModal={setModal} id="4" />
      ) : null}
      <div>
        <div className="notify-overlay" />
        <section className="dashboard-section">
          <Sidebar></Sidebar>
          <div className="dasboard-body">
            <div className="dashboard-hero">
              <div className="header-top">
                <div className="container">
                  <div className="mobile-header d-flex justify-content-between d-lg-none align-items-center">
                    <div className="author">
                      <img
                        src="./assets/images/dashboard/author.png"
                        alt="dashboard"
                      />
                    </div>
                    <div className="cross-header-bar">
                      <span />
                      <span />
                      <span />
                    </div>
                  </div>
                  <DashboardHeader></DashboardHeader>
                </div>
              </div>
              <div className="dashboard-hero-content text-white pb-1">
                <h3 className="title">Tickets</h3>
                <ul className="breadcrumb">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Tickets</li>
                </ul>
              </div>
            </div>
            <div className="container-fluid">
              {/* <Balance /> */}
              <div className="partners">
                <h3 className="main-title">Tickets</h3>
                <a
                  className="custom-button btn-sm mb-30"
                  onClick={() => setModal(true)}
                >
                  New Tickets <i className="flaticon-right-arrow" />
                </a>
                <form className="operation-filter ticket-filter">
                  <div className="filter-item">
                    <label>Show Entries:</label>
                    <div className="select-item">
                      <select
                        className="select-bar"
                        style={{ display: "none" }}
                      >
                        <option value="o1">10</option>
                        <option value="o2">20</option>
                        <option value="o4">30</option>
                        <option value="o3">40</option>
                      </select>
                      <div className="nice-select select-bar" tabIndex={0}>
                        <span className="current">10</span>
                        <ul className="list">
                          <li data-value="o1" className="option selected">
                            10
                          </li>
                          <li data-value="o2" className="option">
                            20
                          </li>
                          <li data-value="o4" className="option">
                            30
                          </li>
                          <li data-value="o3" className="option">
                            40
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="filter-item">
                    <label htmlFor="date">Search:</label>
                    <input type="text" id="date" />
                  </div>
                </form>
                <div className="table-wrapper">
                  <table className="transaction-table text-center more_space">
                    <thead>
                      <tr>
                        <th className="text-truncate" >Sr.No.</th>
                        <th className="text-truncate">DATE AND TIME</th>
                        <th className="text-truncate">Topic</th>
                        <th className="text-truncate">Status</th>
                        <th className="text-truncate">Ticket No</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!Loader && Data.length > 0 ? (
                        Data.map((item, idx) => (
                          <tr key={idx}>
                            <td>
                        
                                    {page * perPageItem - perPageItem + idx + 1}
                                  </td>
                            <td>
                              <i className="far fa-calendar" />{" "}
                              {formatDate(item.datetime)}
                            </td>
                            <td>{item.topic}</td>
                            <td>
                              {(() => {
                                switch (item.status) {
                                  case 0:
                                    return <span className="pending">Pending</span>;
                                  case 1:
                                    return (
                                      <span className="withdrawsuccess text-truncate">
                                        Withdraw Success
                                      </span>
                                    );
                                  case 2:
                                    <span className="cancel">Cancel</span>;
                                }
                              })()}
                            </td>
                            <td>{item.ticket_no}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="6">
                            {!Loader && Data?.length == 0 ? (
                              "No data available"
                            ) : (
                              <TableLoader />
                            )}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {!Loader && Data?.length > 0 ? (
                    <Pagination
                      page={page}
                      count={totalPage}
                      onChange={pageChange}
                    />
                  ) : null}
                </div>
              </div>
            </div>
            <div className="container-fluid sticky-bottom">
              <div className="dashboard-footer">
                <div className="d-flex flex-wrap justify-content-between m-0-15-none">
                  <div className="left">
                    © 2023 <span className="version-09">version - 0.9</span>{" "}
                    &nbsp;&nbsp;&nbsp;<a href="#0">WYscale</a> | All right
                    reserved.
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
