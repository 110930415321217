import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import OtpInput from "react-otp-input";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import QRCode from "react-qr-code";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import formatDate from "../utils/Dateformat";
import Pagination from "@mui/material/Pagination";
import TableLoader from "./TableLoader";
import apiService from "../services/ApiService";
import { useNavigate } from "react-router-dom";
import VerifyButton from "@passbase/button/react";
import WalletAddressForm from "./WalletAddressForm";
import CryptoTranster from "./cryptoTranster";
import { ContractService } from "../services/ContractService";
import ConfirmationDialog from "./ConfirmationDialog";
import { TronWebContractService } from "../services/TronWebContractService";
import SetAmountTron from "./tronWebComponent/SetAmountTron";
import AddWalletAddressTron from "./tronWebComponent/AddWalletAddressTron";
import { ContractServiceWithdrawal } from "../services/withdrawalContractService";
import WithdrawFromOnMeta from "./WithdrawFromOnMeta";
import UserDepositeConfirmation from "./UserDepositeConfirmation";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };

// const names = [
//   "Oliver Hansen",
//   "Van Henry",
//   "April Tucker",
//   "Ralph Hubbard",
//   "Omar Alexander",
//   "Carlos Abbott",
//   "Miriam Wagner",
//   "Bradley Wilkerson",
//   "Virginia Andrews",
//   "Kelly Snyder",
// ];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const onMetaWidget = window.onMetaWidget

export default function Modals({
  open,
  setModal,
  id,
  data,
  userPlanId,
  Plan,
  isApiCallforSupport,
}) {
  const [key, setKey] = useState(1);
  const [ModalId, setModalId] = useState(id);
  const [Amount, setAmount] = useState(0);
  const [openOnMeta, setOnMeta] = useState(true);
  const [personName, setPersonName] = React.useState([]);
  const [Otp, setOtp] = React.useState("");
  const [page, setPage] = useState(1);
  const [Data, setData] = useState([]);
  const [Loader, setLoader] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const Plan_Name = ["Weekly", "Monthly", "Long Term"];
  const [perPageItem, setperPageItem] = useState(0);
  const [Cypto, setCrypto] = useState(0);
  const [referral, setReferral] = useState("");
  const [ticketId, setTicketId] = useState(false);
  const [errorAmount, setErrorAmount] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState();
  const navigate = useNavigate();

  function onChangeAmount(e) {
    if (parseInt(data.inr_amount) <= parseInt(e.target.value)) {
      setAmount(() => e.target.value);
      setErrorAmount(false);
    } else {
      setErrorAmount(true);
    }
  }

  async function handlePaymentGatway() {
    const orderData = await apiService().post('paymentDeposite', { plan_id: data.plan_id, amount: Amount })
    const metaData = JSON.stringify({
      token: localStorage.getItem("token"),
      amount: Amount,
      plan_id: data.plan_id,
      order_id: orderData.data.order_id,
      isNav:false
    });
    const result = await apiService().get("showWalletAddressInfo");
    const createWidget = new onMetaWidget({
      environment: process.env.REACT_APP_PRODUCTION,
      elementId: "widget",
      apiKey: process.env.REACT_APP_PAYMENT_APP_KEY,
      walletAddress: result.data.wallet_address,
      fiatAmount: Amount, // Optional (If passed then minimum amount is 100 inr)
      userEmail: localStorage.getItem("email"), // Optional (if passed user don't have to register in meta platform)
      metaData: metaData,
      onRamp: 'enabled',
      offRamp: 'disabled'
    });
    createWidget.init();
    createWidget.on(
      "ORDER_COMPLETED_EVENTS",
      (data) => (
        setModalId("10"),
        console.log(data),
        setPaymentStatus(data.paymentStatus)
      )
    );
  }

  async function onSubmitAmount() {
    if (parseInt(data.amount) <= parseInt(Amount)) {
      setOnMeta(false);
    }
  }

  useEffect(() => {
    if (!openOnMeta) {
      handlePaymentGatway();
    }
  }, [openOnMeta]);

  const handleClose = () => {
    setModal(false);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const pageChange = (event, value) => {
    setPage(value);
  };

  const UserDepositeList = async () => {
    try {
      setLoader(true);
      const res = await apiService().get(
        `userDepositeList?page=${page}&user_plan_id=${userPlanId}`
      );
      setLoader(false);
      setTotalPage(res.data.lastPage);
      setData(res.data.data);
      setperPageItem(res.data.perPage);
      setWallet_address(res.data.wallet_address)
      setChainIdAndTokenAddressForWithdraw({ chainId: res.data.chain_id, tokenAddress: res.data.token_address, userId: res.data.user_id, planId: res.data.plan_id })
    } catch (error) {
      console.log(error);
      setLoader(false);
      toast.error(error);
    }
  };

  const userWithdrawList = async () => {
    try {
      setLoader(true);
      const res = await apiService().get(
        `userWithdrawList?page=${page}&user_plan_id=${userPlanId}`
      );
      setLoader(false);
      setTotalPage(res.data.lastPage);
      setData(res.data.data);
    } catch (error) {
      setLoader(false);
      console.log(error);
      toast.error(error);
    }
  };

  const userEarlyMaturityList = async () => {
    try {
      setLoader(true);
      const res = await apiService().get(
        `userEarlyMaturityList?page=${page}&user_plan_id=${userPlanId}`
      );
      setLoader(false);
      setData(res.data.data);
    } catch (error) {
      console.log(error);
      setLoader(false);
      toast.error(error);
    }
  };

  const handleOTPClick = async () => {
    try {
      data["otp"] = Otp;
      console.log("data", data);
      const res = await apiService().post("registerUser", data);
      toast.success("User registered successfully");
      navigate("/signin", {
        state: { email: data.email, password: data.password },
      });
      console.log(res);
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };

  const handleReferralClick = async () => {
    try {
      console.log(referral);
      const res = await apiService().post("setReferral", {
        referral_code: referral,
        token: localStorage.getItem("token"),
      });
      toast.success("User signup successfully");
      navigate("/");
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };

  const referenceUserWithKey = (key) => {
    console.log(key);
    // Make request to your backend/db and save the key to the user's profile
  };

  const UserTicketTypes = async () => {
    try {
      setLoader(true);
      const res = await apiService().get(`userTicketTypes`);
      console.log(res);
      setLoader(false);
      // setTotalPage(res.data.lastPage);
      setData(res.data.data);
    } catch (error) {
      setLoader(false);
      console.log(error);
      toast.error(error);
    }
  };

  const AddTicket = async () => {
    try {
      setLoader(true);
      console.log(ticketId);
      const res = await apiService().post(`addTicket`, {
        ticket_type_id: ticketId,
      });
      setLoader(false);
      toast.success(res.data.message);
      setModal(false);
      isApiCallforSupport(true);
    } catch (error) {
      setLoader(false);
      setModal(false);
      console.log(error);
      toast.error(error);
    }
  };

  useEffect(() => {
    if (id == 4) UserTicketTypes();
    else if (id == 5) UserDepositeList();
    else if (id == 8) userWithdrawList();
    else if (id == 7) userEarlyMaturityList();
  }, [page]);
  // //////////////-------------------calculations ====================//////////////
  const [hasTooltip, setHasTooltip] = useState(false);


  // //////////////-------------------calculations end  ====================//////////////


  // /////-----------------handle crypto -------------------////
  const [textBtn, setTextButton] = useState('Polygon-USDT')
  const [bscButton, setBSCButton] = useState('BEP20-USDT')
  const [bUSDButton, setBUSDButton] = useState('BEP20-BUSD')
  const [walletAddressForm, setWalletAddressForm] = useState(false)
  const [cryptoTransterAmountForm, setCryptoTransterAmountForm] = useState(false)
  const [typeForToken, setTypeForToken] = useState('')
  const [typeForTokenIndex, setTypeForTokenIndex] = useState(null)

  async function onCloseWalletAddressForm(wallet_address) {
    setWalletAddressForm(false)
    if (!wallet_address) return
    checkAndCallDepositeFn(wallet_address)
  }

  const checkAndCallDepositeFn = async (wallet_address) => {
    try {
      const result = await apiService().post('storeWalletAddress', { wallet_address: wallet_address })
      localStorage.setItem('userAddress', wallet_address)
      setCryptoTransterAmountForm(true)
    } catch (error) {
      console.log(error);
      toast.error(error.message)
    }
  }

  const cyptoTransfer = async () => {
    if (localStorage.getItem('userAddress')) {
      setCryptoTransterAmountForm(true)
    } else {
      setWalletAddressForm(true)
    }
  };


  async function handleAmountDialogClose(amount) {
    setCryptoTransterAmountForm(false)
    if (!amount) return
    try {

      typeForToken == 'polygon'
        ? setTextButton('Waiting For Approval')
        : typeForToken == 'busdt'
          ? setBUSDButton('Waiting For Approval')
          : setBSCButton('Waiting For Approval')
      const approval = await ContractService._getApproval(typeForTokenIndex,
        typeForToken == 'polygon'
          ? parseInt(process.env.REACT_APP_CHAINID_POLYGON)
          : parseInt(process.env.REACT_APP_CHAINID_BSC), localStorage.getItem('userAddress'), amount)
      if (approval) {

        await approval.wait(2)
      }
      const ethAmount = ContractService.getEthersAmount(amount);
      typeForToken == 'polygon'
        ? setTextButton('Waiting For Transaction')
        : typeForToken == 'busdt'
          ? setBUSDButton('Waiting For Transaction') :
          setBSCButton('Waiting For Transaction')
      const result = await ContractService.deposit(typeForTokenIndex, typeForToken == 'polygon' ? parseInt(process.env.REACT_APP_CHAINID_POLYGON) : parseInt(process.env.REACT_APP_CHAINID_BSC), ethAmount, localStorage.getItem('userId'), data.plan_id)
      await result.wait()
      const res = await apiService().post('PendingDepositeForPlan', { amount: amount, plan_id: data.plan_id, transaction_hash: result.hash })
      toast.success(res.data.message)
      typeForToken == 'polygon'
        ? setTextButton('Polygon-USDT')
        : typeForToken == 'busdt'
          ? setBUSDButton('BEP20-BUSD')
          :
          setBSCButton('BEP20-USDT')

      navigate('/Deposit')
    } catch (error) {
      typeForToken == 'polygon'
        ? setTextButton('Polygon-USDT')
        : typeForToken == 'busdt'
          ? setBUSDButton('BEP20-BUSD')
          :
          setBSCButton('BEP20-USDT')
      toast.error(error.reason)
    }
  }
  // /////-----------------handle crypto  end-------------------////

  /////////----------------handle withdrwal from model id 5 -------------------///////////
  const [isSubmit, setIsSubmit] = useState([])
  const [isConfirmation, setIsConfirmation] = useState(false)
  const [index, setIndex] = React.useState();

  const [submitData, setSubmitData] = useState(null)

  async function onConfirm() {
    setIsConfirmation(false)
    setIsSubmit(prevState => ({ ...prevState, [index]: true }));
    try {
      // if (parseInt(chainIdAndTokenAddressForWithdraw.chainId) === 2) {
      //   const result = await userWithdrawByTron()
      //   console.log(result);
      //   setTimeout(() => {
      //     setIsSubmit(prevState => ({ ...prevState, [index]: false }));
      //     toast.success('transaction success.')
      //     Data[index].is_withdraw = 1
      //   }, 30000);
      // } else {
      //   const result = await userWithdraw()
      //   await result.wait()
      //   setIsSubmit(prevState => ({ ...prevState, [index]: false }));
      //   toast.success('transaction success.')
      //   Data[index].is_withdraw = 1
      // }
      // const result = await apiService().post('userWithdrawIntrest', { user_plan_detail_id: [submitData.user_plan_detail_id], user_plan_id: submitData.user_plan_id })
      const result = await apiService().post('sendUserWithdrawRequest', { user_plan_detail_id: [submitData.user_plan_detail_id], user_plan_id: submitData.user_plan_id })
      setIsSubmit(prevState => ({ ...prevState, [index]: false }));
      toast.success(result.data.message)
      Data[index].is_withdraw = 2
    } catch (error) {
      toast.success('transaction failed.')
      setIsSubmit(prevState => ({ ...prevState, [index]: false }));
    }
  }

  function onCancel() {
    setIsConfirmation(false)
  }



  /////////----------------handle withdrwal from model id 5 end -------------------///////////


  //////////handle tronweb transaction start /////
  const [openAmountDialog, setAmountDialog] = useState(false)
  const [openAddWalletAddress, setWalletAddressTron] = useState(false)
  const [tbuttontxt, setTbuttontxt] = useState('TRC20-USDT')
  async function handleAmountDialogCloseT(amount) {
    setAmountDialog(false)
    if (!amount) return
    try {
      setTbuttontxt('Waiting For Approval')
      const userAddress = await TronWebContractService.getTronWeb()
      const value = await TronWebContractService.getEthersAmount(amount)
      const approval = await TronWebContractService.getApproval(value, userAddress)
      setTbuttontxt('Waiting For Transaction')
      const result = await TronWebContractService.deposite(value, localStorage.getItem('userId'), data.plan_id)
      console.log(result);
      setTimeout(() => {
        toast.success('transaction success')
        setTbuttontxt('TRC20-USDT')
        navigate('/Deposit')
      }, 30000);
    } catch (error) {
      console.log(error);
      setTbuttontxt('TRC20-USDT')
    }

  }

  async function onCloseWalletAddressTron(tron_wallet_address) {
    setWalletAddressTron(false)
    if (!tron_wallet_address) return
    try {
      const result = await apiService().post('storeTronWalletAddress', { tron_wallet_address: tron_wallet_address })
      localStorage.setItem('tronAddress', tron_wallet_address)
      setAmountDialog(true)
    } catch (error) {

    }
  }

  async function handleTronTransaction() {
    if (localStorage.getItem('tronAddress')) {
      setAmountDialog(true)
    } else {
      setWalletAddressTron(true)
    }
  }
  //////////handle tronweb transaction end /////
  ////////================handleClaim============/////
  const [isCliamConfirmation, setCliamConfirmation] = useState(false)
  const [user_plan_id, setUser_plan_id] = useState(0)

  function onCliamCancel() {
    setCliamConfirmation(false)
  }

  async function onCliamConfirm() {
    setCliamConfirmation(false)
    try {
      const result = await apiService().post('cancelPlanUser', { user_plan_id: user_plan_id })
      toast.success(result.message)
      Data.is_cancel = 1
    } catch (error) {
      toast.error(error.message)
    }
  }

  function handleClaim(user_plan_id) {
    setCliamConfirmation(true)
    setUser_plan_id(user_plan_id)
  }
  ////////================handleClaim end============/////


  ////////////handle user withdrawal flow  start/////////////////
  const [chainIdAndTokenAddressForWithdraw, setChainIdAndTokenAddressForWithdraw] = useState(null)

  async function userWithdraw() {
    const result = await ContractServiceWithdrawal.userWithdraw(chainIdAndTokenAddressForWithdraw.tokenAddress, parseInt(chainIdAndTokenAddressForWithdraw.chainId), { ...submitData, ...chainIdAndTokenAddressForWithdraw })
    return result
  }

  async function userWithdrawByTron() {
    const userAddress = await TronWebContractService.getTronWeb()
    const result = await TronWebContractService._userWithdraw(chainIdAndTokenAddressForWithdraw.tokenAddress, { ...submitData, ...chainIdAndTokenAddressForWithdraw })
    return result
  }


  ////////////handle user withdrawal flow end /////////////////


  /*
   *  handle payment withdraw 
   */

  const [is_withdraw, setIsWithdraw] = useState(false)

  async function handleWithdraw(item) {
    try {
      const result = await apiService().post('paymentWithdraw', { user_plan_id: item.user_plan_id, user_plan_detail_id: item.user_plan_detail_id, amount: item.amount })
      setSubmitData(prevState => ({ ...prevState, order_id: result.data.order_id }));
    } catch (error) {
      console.log(error);
      throw error
    }
  }

  function closeWithDrawDialog() {
    setIsWithdraw(false)
  }

  //handle transaction confirmation 
  const [isTransactionConfirmation, setIsTransactionConfirmation] = useState(false)
  const [wallet_address, setWallet_address] = useState(null)

  async function handleConfirmation(item) {
    try {
      await handleWithdraw(item)
      setIsTransactionConfirmation(true)
    } catch (error) {
      console.log(error);
    }
  }

  function closeUserDepositeConfirmation() {
    setIsTransactionConfirmation(false)
  }

  function onConfirmUserDepositeConfirmation(item) {
    setIsTransactionConfirmation(false)
    if (item == '0') {
      setIsWithdraw(true)
    } else {
      if (!wallet_address) return toast.error('please add wallet address.')
      onConfirm(submitData)
    }
  }
  return (
    <div>
      {isTransactionConfirmation ? <UserDepositeConfirmation onconfirm={onConfirmUserDepositeConfirmation} onclose={closeUserDepositeConfirmation} /> : <></>}
      {is_withdraw ? <WithdrawFromOnMeta onclose={closeWithDrawDialog} is_withdraw={is_withdraw} data={submitData} /> : <></>}
      {isCliamConfirmation ? <ConfirmationDialog message="Are you sure you want to perform this action?" onCancel={onCliamCancel} onConfirm={onCliamConfirm} /> : null}
      {cryptoTransterAmountForm ? <CryptoTranster open={true} handleAmountDialogClose={handleAmountDialogClose} data={data} /> : null}
      {walletAddressForm ? <WalletAddressForm open={true} onCloseWalletAddressForm={onCloseWalletAddressForm} /> : null}
      {openAmountDialog ? <SetAmountTron open={true} handleAmountDialogCloseT={handleAmountDialogCloseT} data={data} /> : null}
      {openAddWalletAddress ? <AddWalletAddressTron open={true} onCloseWalletAddressTron={onCloseWalletAddressTron} /> : null}
      {(() => {
        switch (ModalId) {
          case "3":
            return (
              <>
                {openOnMeta ? (
                  <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                    id="3"
                  >
                    <DialogTitle className="invest-title">
                      {"Add Funds"}
                    </DialogTitle>
                    <DialogContent>
                      <div className="row m-0 w-100">
                        <DialogContentText id="alert-dialog-slide-description">
                          <h4></h4>
                        </DialogContentText>
                        <div className="row m-0 w-100">
                          <div className="col-md-6 investcolumn ">
                            <p className="investingin">Investing In:</p>
                            <img
                              src="https://d2nypvkdltc2i5.cloudfront.net/basket_images/defi_innovation.png"
                              className="strategyimage1"
                            />
                            <span className="span">Market maker fund</span>
                            <p className="notepara">Note:</p>
                            <ul>
                              <li className="downpara exeptions1">
                                Funds may take upto 4 business hours to reflect
                                in your account. For queries, please reach out
                                to us on chat or Contact us at{" "}
                                {/* <a
                                  href="tel:18001204099 "
                                  className="text-info"
                                >
                                  {" "}
                                  18001204099{" "}
                                </a> */}
                              </li>
                              <li className="downpara exeptions1">
                                We will convert your funds to our accepted
                                cryptocurrency and pay TDS as per Indian Govt.
                                norms.{" "}
                              </li>
                              <li className="downpara exeptions1">
                                If you have trouble finding the Transaction
                                Reference No., see our guide here .
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6 p-0">
                            <Tabs
                              id="controlled-tab-example"
                              activeKey={key}
                              onSelect={(k) => (setKey(k), setCrypto(0))}
                              className=""
                            >
                              <Tab
                                eventKey="1"
                                title="Bank Transfer"
                                width="50"
                              >
                                <div className="row m-0">
                                  <div className="col-md-12 mx-auto my-2">
                                    <div className="row d-flex paymentflex">
                                      <div className="col-12 col-md-12 m-auto">
                                        <div className="form-group text-left">
                                          <label
                                            htmlFor="email"
                                            className="mb-0 endermodalamount"
                                          >
                                            Enter Amount:
                                          </label>
                                          <input
                                            className=" inputfeild2  m-auto"
                                            type=""
                                            width="50"
                                            onChange={(e) => onChangeAmount(e)}
                                            placeholder="INR Amount"
                                          />
                                          {errorAmount ? (
                                            <small>
                                              Minimum invest amount is{" "}
                                              ₹ {data.inr_amount}  required
                                            </small>
                                          ) : null}
                                        </div>
                                      </div>
                                      {/* <div className="col-3 col-md-3 m-auto">
                      <i
                        className="fa fa-arrows-h my-auto"
                        aria-hidden="true"
                      ></i>
                    </div>
                    <div className="col-4 col-md-4 m-auto">
                      <div className="d-block my-auto">
                        <p className="usdtamount">USDT</p>
                        <span className=" usdamount ">34.56</span>
                      </div>
                    </div>  */}
                                    </div>
                                    <div className="text-center">
                                      <a
                                        onClick={() => onSubmitAmount()}
                                        className="custom-button text-white"
                                      >
                                        Submit
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </Tab>
                              <Tab eventKey="profile" title="Crypto Transfer">
                                <div className="row m-0">
                                  <div className="col-md-12 m-auto ">
                                    {
                                      textBtn === 'Polygon-USDT'
                                        && bscButton == 'BEP20-USDT'
                                        && bUSDButton == 'BEP20-BUSD'
                                        && tbuttontxt === 'TRC20-USDT' ?
                                        <>
                                          <div className="card paymentcard"
                                            onClick={() => (textBtn == 'Polygon-USDT' ? (setTypeForToken('polygon'), setTypeForTokenIndex(0), cyptoTransfer()) : null)}
                                          >
                                            <div className="d-flex">
                                              <img
                                                src="./assets/images/icon/matic-token-icon-removebg-preview.png"
                                                width={"30px"}
                                              ></img>
                                              <span className="usdtname">
                                                {textBtn}
                                              </span>
                                              <i className="fa-solid fa-arrow-right icons"></i>
                                            </div>
                                          </div>
                                          <div className="card paymentcard"
                                            onClick={() => (bscButton == 'BEP20-USDT' ? (setTypeForToken('bnb'), setTypeForTokenIndex(0), cyptoTransfer()) : null)}
                                          >
                                            <div className="d-flex">
                                              <img
                                                src="./assets/images/icon/UNI.png"
                                                width={"30px"}
                                              ></img>
                                              <span className="usdtname">
                                                {bscButton}
                                              </span>
                                              <i className="fa-solid fa-arrow-right icons"></i>
                                            </div>
                                          </div>
                                          <div className="card paymentcard"
                                            onClick={() => (bUSDButton == 'BEP20-BUSD' ? (setTypeForToken('busdt'), setTypeForTokenIndex(1), cyptoTransfer()) : null)}
                                          >
                                            <div className="d-flex">
                                              <img
                                                src="./assets/images/icon/UNI.png"
                                                width={"30px"}
                                              ></img>
                                              <span className="usdtname">
                                                {bUSDButton}
                                              </span>
                                              <i className="fa-solid fa-arrow-right icons"></i>
                                            </div>
                                          </div>
                                          <div className="card paymentcard"
                                            onClick={() => tbuttontxt === 'TRC20-USDT' ? handleTronTransaction() : null}
                                          >
                                            <div className="d-flex">
                                              <img
                                                src="./assets/images/icon/trc-usdt.png"
                                                width={"30px"}
                                              ></img>
                                              <span className="usdtname">
                                                {tbuttontxt}
                                              </span>
                                              <i className="fa-solid fa-arrow-right icons"></i>
                                            </div>
                                          </div>
                                        </> : textBtn !== 'Polygon-USDT' ? textBtn : bscButton !== 'BEP20-USDT' ? bscButton : bUSDButton !== 'BEP20-BUSD' ? bUSDButton : tbuttontxt !== 'TRC20-USDT' ? tbuttontxt : null
                                    }
                                  </div>
                                </div>
                              </Tab>
                            </Tabs>
                          </div>
                        </div>
                      </div>
                    </DialogContent>
                  </Dialog>
                ) : (
                  <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-describedby="alert-dialog-slide-description"
                    id="3"
                  >
                    <span onClick={handleClose} className="text-right m-3"  ><i className="fa-regular fa-circle-xmark"></i></span>
                    <div className="col-md-6 px-3">
                      <div id="widget"></div>
                    </div>
                  </Dialog>
                )}
              </>
            );
          case "4":
            return (
              <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                id="4"
              >
                <DialogTitle className="invest-title">
                  {"One Step Forward"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    <h4></h4>
                  </DialogContentText>
                  <div className="p-4">
                    <div className="filter-item mt-5">
                      <div className="select-item">
                        <div
                          className="nice-select select-bar"
                          tabindex="1"
                          style={{ width: "185px; ", display: "block" }}
                        >
                          <p className="current">Select Options</p>

                          <ul className="list">
                            {!Loader && Data?.length > 0
                              ? Data.map((item, idx) => (
                                <li
                                  key={idx}
                                  data-value="o2"
                                  onClick={() =>
                                    setTicketId(item.ticket_type_id)
                                  }
                                  className="option"
                                >
                                  {item.name}
                                </li>
                              ))
                              : "--"}
                          </ul>
                        </div>
                      </div>
                      <button
                        className="my-3 custom-button w-auto"
                        onClick={() => AddTicket()}
                      >
                        Submit
                      </button>
                      <p className="text-truncate">
                        {" "}
                        General Queries :{" "}
                        <a href="#" target={"_blank"}>
                          https://web.telegram.org/k/
                        </a>
                      </p>
                    </div>
                  </div>
                </DialogContent>
              </Dialog>
            );
          case "5":
            return (
              <>
                <Dialog
                  fullScreen
                  open={open}
                  onClose={handleClose}
                  TransitionComponent={Transition}
                >
                  <AppBar sx={{ position: "relative" }}>
                    <Toolbar>
                      <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                      >
                        <CloseIcon />
                      </IconButton>

                      <h3 className="main-title text-white m-auto ">
                        {Plan_Name[Plan - 1]} Deposit
                      </h3>
                    </Toolbar>
                  </AppBar>
                  <section>
                    <div className="container-fluid">
                      <div className="operations mt-5">
                        <form className="operation-filter">
                          <div className="filter-item">
                            <label for="date">Date from:</label>
                            <input type="date" placeholder="Date from" />
                          </div>
                          <div className="filter-item">
                            <label for="date">Date To:</label>
                            <input type="date" placeholder="Date from" />
                          </div>
                          <div className="filter-item">
                            <label>Operation:</label>
                            <div className="select-item">
                              <select
                                className="select-bar"
                                style={{ display: "none" }}
                              >
                                <option value="o1">Add funds</option>
                                <option value="o2">Withdraw funds</option>
                                <option value="o4">Deposit funds</option>
                                <option value="o3">Transfer funds</option>
                              </select>
                              <div
                                className="nice-select select-bar"
                                tabindex="0"
                              >
                                <p className="current">Add funds</p>
                                <ul className="list">
                                  <li data-value="o1" className="option selected">
                                    Add funds
                                  </li>
                                  <li data-value="o2" className="option">
                                    Withdraw funds
                                  </li>
                                  <li data-value="o4" className="option">
                                    Deposit funds
                                  </li>
                                  <li data-value="o3" className="option">
                                    Transfer funds
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="filter-item">
                            <label>Status:</label>
                            <div className="select-item">
                              <select
                                className="select-bar"
                                style={{ display: "none" }}
                              >
                                <option value="p1">Prepared</option>
                                <option value="p2">Prepared</option>
                                <option value="p3">Prepared</option>
                                <option value="p4">Prepared</option>
                                <option value="p5">Prepared</option>
                                <option value="p6">Prepared</option>
                              </select>
                              <div
                                className="nice-select select-bar"
                                tabindex="0"
                              >
                                <p className="current">Prepared</p>
                                <ul className="list">
                                  <li data-value="p1" className="option selected">
                                    Prepared
                                  </li>
                                  <li data-value="p2" className="option">
                                    Prepared
                                  </li>
                                  <li data-value="p3" className="option">
                                    Prepared
                                  </li>
                                  <li data-value="p4" className="option">
                                    Prepared
                                  </li>
                                  <li data-value="p5" className="option">
                                    Prepared
                                  </li>
                                  <li data-value="p6" className="option">
                                    Prepared
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="filter-item">
                            <button type="submit" className="custom-button">
                              Filter
                            </button>
                          </div>
                        </form>
                        <div className="table-wrapper">
                          <table className="transaction-table">
                            <thead>
                              <tr>
                                <th>Sr.No.</th>
                                <th className="text-truncate">
                                  Investment Month
                                </th>
                                <th className="text-truncate">Date</th>
                                <th className="text-truncate">Total Amount</th>
                                <th className="text-truncate">
                                  Receivable Amount
                                </th>
                                <th className="text-truncate">Admin Fees</th>

                                <th className="text-truncate">Withdraw</th>
                              </tr>
                            </thead>
                            <tbody>
                              {!Loader && Data?.length > 0 ? (
                                Data.map((item, idx) => (
                                  <tr key={idx}>
                                    <td>
                                      {" "}
                                      {page * perPageItem - perPageItem + idx + 1}
                                    </td>
                                    <td className="text-truncate">
                                      {new Date(item.date).toLocaleString(
                                        "default",
                                        { month: "long" }
                                      )}
                                    </td>
                                    <td className="text-truncate">
                                      {formatDate(item.date)}
                                    </td>
                                    <td className="text-truncate">
                                      ${item.total_amount}
                                    </td>
                                    <td className="text-truncate">
                                      ${item.amount}
                                    </td>
                                    <td className="text-truncate">
                                      ${item.admin_fees}
                                    </td>
                                    <td className="text-truncate">
                                      {item.is_withdraw === 0 ? (
                                        <button
                                          type="submit"
                                          className={`action-btn ${isSubmit[idx] ? 'loading' : ""}`}
                                          onClick={() => (setIndex(idx), setSubmitData(item), handleConfirmation(item))}
                                          disabled={isSubmit[idx]}
                                        >
                                          Withdraw
                                        </button>
                                      ) : item.is_withdraw === 2 ? (
                                        "Request sent"
                                      ) : "withdraw"}
                                    </td>
                                    {/* {chainIdAndTokenAddressForWithdraw.chainId == 1 ?
                                      <td className="text-truncate">
                                        {item.is_withdraw === 0 ? (
                                          <button
                                            type="submit"
                                            className={`action-btn ${isSubmit[idx] ? 'loading' : ""}`}
                                            onClick={() => (setIndex(idx), setSubmitData(item),handleWithdraw(item))}
                                            disabled={isSubmit[idx]}
                                          >
                                            Withdraw
                                          </button>
                                        ) : item.is_withdraw === 2 ? (
                                          "Request sent"
                                        ) : "withdraw"}
                                      </td> :
                                      <td className="text-truncate">
                                        {item.is_withdraw === 0 ? (
                                          <button
                                            type="submit"
                                            className={`action-btn ${isSubmit[idx] ? 'loading' : ""}`}
                                            onClick={() => (setIsConfirmation(true), setIndex(idx), setSubmitData(item))}
                                            disabled={isSubmit[idx]}
                                          >
                                            Request for Withdraw
                                          </button>
                                        ) : item.is_withdraw === 2 ? (
                                          "Request sent"
                                        ) : "withdraw"}
                                      </td>} */}
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="7">
                                    {!Loader && Data?.length == 0 ? (
                                      "No data available"
                                    ) : (
                                      <TableLoader />
                                    )}
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                          {!Loader && Data?.length > 0 ? (
                            <Pagination
                              page={page}
                              count={totalPage}
                              onChange={pageChange}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </section>
                </Dialog>
              </>
            );
          case "6":
            return (
              <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                id="4"
              >
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    <div className="mx-3 mt-3">
                      <div className="d-flex">
                        <div className="d-block">
                          <p className="walletaddress m-0">QR Code:</p>
                          <QRCode
                            value="as;dfj;adsf"
                            size="170"
                            className="my-2"
                          />
                          <p className="mb-0 scanqrcode">
                            Scan to Download App IOS & Android
                          </p>
                        </div>
                        <div className="d-block my-auto mx-3">
                          <ul className="justify-content-center mb-3">
                          <li>
                             <a href="https://testflight.apple.com/join/D88p82G2" target="_blank">
                             <img
                                src="/assets/images/icon/ios-store.png"
                                width={"100px"}
                              ></img>
                              <p className="scanqrcode m-0" >Download Testflight to download WYScale</p>
                             </a>
                            </li>
                            <li>
                             <a href="https://play.google.com/store/apps/details?id=com.wyscale.twa" target="_blank" >
                             <img
                                src="/assets/images/icon/play-store.png"
                                width={"100px"}
                              ></img>
                             </a>
                            </li>
                          </ul>

                          <button type="submit" className="custom-button text-truncate ">
                            More download Options
                          </button>
                        </div>
                      </div>
                    </div>
                  </DialogContentText>
                </DialogContent>
              </Dialog>
            );
          case "7":
            return (
              <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
              >
                <AppBar sx={{ position: "relative" }}>
                  <Toolbar>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={handleClose}
                      aria-label="close"
                    >
                      <CloseIcon />
                    </IconButton>

                    <h3 className="main-title text-white m-auto ">
                      Total Maturity Calculations
                    </h3>
                  </Toolbar>
                </AppBar>
                <section>
                  <div className="container-fluid">
                    <div className="operations mt-5">
                      <div className="table-wrapper">
                        <table className="transaction-table">
                          <thead>
                            <tr>
                              <th className="text-truncate"> Month</th>
                              <th className="text-truncate">Principle AMT</th>
                              <th className="text-truncate">
                                Received Interest
                              </th>
                              <th className="text-truncate"> Deduction %</th>

                              <th className="text-truncate">Receivable AMT</th>
                              <th className="text-truncate">
                                Calculations Info
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {!Loader && Data && Object.keys(Data).length > 0 ? (
                              <tr>
                                <td className="text-truncate">
                                  {new Date().toLocaleString("default", {
                                    month: "long",
                                  })}
                                </td>
                                <td className="text-truncate">
                                  ${Data.principle_amount}
                                </td>
                                <td className="text-truncate">
                                  ${Data.amount_received}
                                </td>
                                <td className="text-truncate">
                                  {Data.deduction_per}%
                                </td>
                                <td className="text-truncate">
                                  ${Data.cancelation_amount}
                                </td>
                                <td className="">

                                  <div className="tooltipicon">
                                    <IconButton aria-label="info">
                                      <InfoIcon
                                        onClick={() =>
                                          setHasTooltip(!hasTooltip)
                                        }
                                      />
                                    </IconButton>
                                  </div>
                                  {hasTooltip ? (
                                    <div className="card tooltipcard">
                                      <span
                                        className="tooltipclose"
                                        onClick={() => setHasTooltip(false)}
                                      >
                                        X
                                      </span>
                                      <div className="pt-4 px-2 "  >
                                        <div className="d-flex tooltipdflex">
                                          <h6> total Received Amount :</h6>{" "}
                                          <p>{Data.amount_received}</p>
                                        </div>
                                        {Data.plan_id == 3 ? (
                                          <div>
                                            <h6>total Received Interest : </h6>{" "}
                                            <p>
                                              {Data.principle_amount} - ({Data.amount_received}/2) ={" "}
                                              {Data.principle_amount - (Data.amount_received / 2)}{" "}
                                            </p>
                                          </div>
                                        ) : null}

                                        <div className="d-flex tooltipdflex">
                                          <h6>
                                            {" "}
                                            Deduction by {Data.deduction_per}% :
                                          </h6>{" "}
                                          <p>
                                            {Data.principle_amount - (Data.amount_received / 2)}
                                            *({Data.deduction_per}/100) ={" "}
                                            {((Data.principle_amount - (Data.amount_received / 2)) *
                                              Data.deduction_per) /
                                              100}

                                          </p>
                                        </div>

                                        <div className="d-flex tooltipdflex">
                                          <h6> Deduction Amount :</h6>{" "}
                                          <p>
                                            {(Data.principle_amount - (Data.amount_received / 2))}
                                            -{((Data.principle_amount - (Data.amount_received / 2)) *
                                              Data.deduction_per) /
                                              100} ={" "} {
                                              (Data.principle_amount - (Data.amount_received / 2)) - ((Data.principle_amount - (Data.amount_received / 2)) *
                                                Data.deduction_per) /
                                              100
                                            }
                                          </p>
                                        </div>
                                        <div className="d-flex tooltipdflex">
                                          <h6>
                                            {" "}
                                            Receivable Amount (Deduction by
                                            admin {Data.admin_per}%):
                                          </h6>{" "}
                                          <p>
                                            {(Data.principle_amount - (Data.amount_received / 2)) - ((Data.principle_amount - (Data.amount_received / 2)) *
                                              Data.deduction_per) /
                                              100}-
                                            {Data.admin_amount} ={" "}
                                            {(Data.principle_amount - (Data.amount_received / 2)) - ((Data.principle_amount - (Data.amount_received / 2)) *
                                              Data.deduction_per) /
                                              100 -
                                              Data.admin_amount}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                </td>
                              </tr>
                            ) : (
                              <tr>
                                <td colSpan="7">
                                  {!Loader && Object.keys(Data).length == 0 ? (
                                    "No data available"
                                  ) : (
                                    <TableLoader />
                                  )}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>

                        <div className="text-center">
                          {!Loader && Data ? (
                            Data?.is_cancel !== 1 ? (
                              <button
                                type="submit"
                                className="custom-button mt-3"
                                style={{ width: "10%" }}
                                onClick={() => handleClaim(Data?.user_plan_id)}
                              >
                                Claim
                              </button>
                            ) : (
                              "Already claimed"
                            )
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </Dialog>
            );
          case "8":
            return (
              <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
              >
                <AppBar sx={{ position: "relative" }}>
                  <Toolbar>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={handleClose}
                      aria-label="close"
                    >
                      <CloseIcon />
                    </IconButton>

                    <h3 className="main-title text-white m-auto ">
                      {Plan_Name[Plan - 1]} Deposit
                    </h3>
                  </Toolbar>
                </AppBar>
                <section>
                  <div className="container-fluid">
                    <div className="operations mt-5">
                      <form className="operation-filter">
                        <div className="filter-item">
                          <label for="date">Date from:</label>
                          <input type="date" placeholder="Date from" />
                        </div>
                        <div className="filter-item">
                          <label for="date">Date To:</label>
                          <input type="date" placeholder="Date from" />
                        </div>
                        <div className="filter-item">
                          <label>Operation:</label>
                          <div className="select-item">
                            <select
                              className="select-bar"
                              style={{ display: "none" }}
                            >
                              <option value="o1">Add funds</option>
                              <option value="o2">Withdraw funds</option>
                              <option value="o4">Deposit funds</option>
                              <option value="o3">Transfer funds</option>
                            </select>
                            <div
                              className="nice-select select-bar"
                              tabindex="0"
                            >
                              <p className="current">Add funds</p>
                              <ul className="list">
                                <li data-value="o1" className="option selected">
                                  Add funds
                                </li>
                                <li data-value="o2" className="option">
                                  Withdraw funds
                                </li>
                                <li data-value="o4" className="option">
                                  Deposit funds
                                </li>
                                <li data-value="o3" className="option">
                                  Transfer funds
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="filter-item">
                          <label>Status:</label>
                          <div className="select-item">
                            <select
                              className="select-bar"
                              style={{ display: "none" }}
                            >
                              <option value="p1">Prepared</option>
                              <option value="p2">Prepared</option>
                              <option value="p3">Prepared</option>
                              <option value="p4">Prepared</option>
                              <option value="p5">Prepared</option>
                              <option value="p6">Prepared</option>
                            </select>
                            <div
                              className="nice-select select-bar"
                              tabindex="0"
                            >
                              <p className="current">Prepared</p>
                              <ul className="list">
                                <li data-value="p1" className="option selected">
                                  Prepared
                                </li>
                                <li data-value="p2" className="option">
                                  Prepared
                                </li>
                                <li data-value="p3" className="option">
                                  Prepared
                                </li>
                                <li data-value="p4" className="option">
                                  Prepared
                                </li>
                                <li data-value="p5" className="option">
                                  Prepared
                                </li>
                                <li data-value="p6" className="option">
                                  Prepared
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="filter-item">
                          <button type="submit" className="custom-button">
                            Filter
                          </button>
                        </div>
                      </form>
                      <div className="table-wrapper">
                        <table className="transaction-table">
                          <thead>
                            <tr>
                              <th className="text-truncate">
                                Investment Month
                              </th>
                              <th className="text-truncate">Date</th>
                              <th className="text-truncate">Amount</th>

                              <th className="text-truncate">Withdraw</th>
                            </tr>
                          </thead>
                          <tbody>
                            {!Loader && Data?.length > 0 ? (
                              Data.map((item, idx) => (
                                <tr key={idx}>
                                  <td className="text-truncate">
                                    {new Date(item.date).toLocaleString(
                                      "default",
                                      { month: "long" }
                                    )}
                                  </td>
                                  <td className="text-truncate">
                                    {formatDate(item.date)}
                                  </td>
                                  <td className="text-truncate">
                                    ${item.total_amount}
                                  </td>
                                  <td className="text-truncate">
                                    {item.is_withdraw === 0 ? (
                                      <button
                                        type="submit"
                                        className="custom-button"
                                      >
                                        Withdraw
                                      </button>
                                    ) : (
                                      "Already Withdraw"
                                    )}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="7">
                                  {!Loader && Data?.length == 0 ? (
                                    "No data available"
                                  ) : (
                                    <TableLoader />
                                  )}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {!Loader && Data?.length > 0 ? (
                          <Pagination
                            page={page}
                            count={totalPage}
                            onChange={pageChange}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </section>
              </Dialog>
            );
          case "9":
            return (
              <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                id="4"
              >
                <DialogTitle className="invest-title">
                  {"OTP Verification"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    <h4></h4>
                  </DialogContentText>

                  <div className="p-4">
                    <form className="mt-1">
                      <div>
                        <p className="text-center">
                          We have shared an OTP with your registered Email Address
                        </p>
                        <div className=" d-flex  justify-content-center mb-4">
                          <OtpInput
                            value={Otp}
                            className="optinputs"
                            onChange={setOtp}
                            numInputs={4}
                            separator={<span>-</span>}
                          />
                        </div>
                        <p className="text-center">Didn’t Received any OTP?</p>
                        <div className="text-center">
                          <a
                            className="custom-button text-white"
                            onClick={() => handleOTPClick()}
                          >
                            Verify
                          </a>
                        </div>
                      </div>
                    </form>
                  </div>
                </DialogContent>
              </Dialog>
            );
          case "10":
            return (
              <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                id="4"
              >
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    <div className="mx-3 mt-3">
                      {paymentStatus === "success" ? (
                        <div className="card border-0 px-4">
                          <img
                            src="./assets/images/banner/approved.gif"
                            className="mx-auto my-3"
                            width={"70px"}
                          ></img>
                          <h3 className="successhead">Payment Successful !</h3>
                          <p className="successtext">
                            Payment was Successful! You now <br></br> continue
                            using WYScale
                          </p>
                          <button
                            type="submit"
                            className="custom-button mb-5 mx-auto w-50 "
                            onClick={() => navigate("/deposit")}
                          >
                            Go to Dashboard
                          </button>
                        </div>
                      ) : (
                        <div className="card border-0 px-4">
                          <img
                            src="./assets/images/banner/failed.gif"
                            className="mx-auto my-3"
                            width={"70px"}
                          ></img>
                          <h3 className="successhead">
                            {" "}
                            Sorry <br></br>{" "}
                            <span className="text-danger">
                              Payment Failed !
                            </span>
                          </h3>
                          <p className="successtext">
                            Your Transaction was Failed!
                          </p>
                          <button
                            type="submit"
                            className="custom-button mb-5 mx-auto w-50"
                            onClick={() => navigate("/deposit")}
                          >
                            Go to Dashboard
                          </button>
                        </div>
                      )}
                    </div>
                  </DialogContentText>
                </DialogContent>
              </Dialog>
            );
          case "11":
            return (
              <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                id="4"
              >
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    <div className="mx-3 mt-3">
                      <div className="card border-0 p-4">
                        <h6>Enter recovery Email Address</h6>
                        <form className="account-form mt-4">
                          <div className="form-group">
                            <input
                              type="text"
                              placeholder="Enter Your Email "
                              id="email"
                              name="email"
                            />
                          </div>
                        </form>
                        <button
                          type="submit"
                          className="custom-button  mt-4 mx-auto w-50"
                        >
                          Verify Email
                        </button>
                      </div>
                      <div className="card border-0 p-4">
                        <h6>Change Password</h6>
                        <form className="account-form mt-4">
                          <div className="form-group">
                            <input
                              type="password"
                              placeholder="Enter Password "
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="password"
                              placeholder="Confrim Password"
                            />
                          </div>
                        </form>
                        <button
                          type="submit"
                          className="custom-button  mt-4 mx-auto w-50"
                        >
                          Confirm Changes
                        </button>
                      </div>
                    </div>
                  </DialogContentText>
                </DialogContent>
              </Dialog>
            );
          case "12":
            return (
              <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                // onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                id="4"
              >

                <DialogTitle className="invest-title">
                  {"Referral code"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    <h4></h4>
                  </DialogContentText>

                  <div className="p-4">
                    <form className="mt-1">
                      <div>
                        <p className="text-center">Enter your referral code</p>
                        <div className=" d-flex  justify-content-center mb-4">
                          <input
                            type="text"
                            value={referral}
                            onChange={(e) => setReferral(e.target.value)}
                          ></input>
                        </div>
                        <div className="text-center">
                          <a
                            className="custom-button text-white"
                            onClick={() => handleReferralClick()}
                          >
                            Submit
                          </a>
                          <button className="skipcode"
                            onClick={() => (
                              toast.success("User login successfully"), navigate("/")
                            )}
                          >
                            {" "}
                            Skip{" "}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </DialogContent>
              </Dialog>
            );
          case "13":
            return (
              <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                id="4"
              >
                <DialogTitle className="invest-title">
                  {"KYC Verification"}
                </DialogTitle>
                <DialogContent>
                  <div className="p-4">
                    <div>
                      <p className="text-center">Complete your kyc</p>

                      <VerifyButton
                        apiKey={
                          "49ab129a42f3bd68094d83d742e92750a0416a57224dcd013288a2e08be5c63c"
                        }
                        onFinish={(identityAccessKey) => {
                          referenceUserWithKey(identityAccessKey);
                        }}
                        onError={(errorCode) => {
                          console.log(errorCode);
                        }}
                        onStart={() => { }}
                      />
                    </div>
                  </div>
                </DialogContent>
              </Dialog>
            );
          case "14":
            return (
              <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                id="4"
              >
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    <img src="/assets/images/banner/2 6.png" className="introbanner" ></img>
                  </DialogContentText>
                </DialogContent>
              </Dialog>
            );
          case "15":
            return (
              <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                id="4"
              >
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    <img src="/assets/images/banner/3 4.png" className="introbanner" ></img>
                  </DialogContentText>
                </DialogContent>
              </Dialog>
            );
        }

      })()}
    </div>
  );
}
