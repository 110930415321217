import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import loadjs from "loadjs";
import Modals from "./Modals";
import { toast } from "react-toastify";
import $ from 'jquery'
export default function DashboardHeader() {
  const [openModal, setModal] = useState(false);

  useEffect(() => {
    loadjs("./assets/js/main.js", () => {});
  }, []);

  const kyc = async () => {
    setModal(true);
  };

  const copyText = () => {
    try{
    navigator.clipboard.writeText( localStorage.getItem("referral_code"))
    toast.success("Text copied successfully!")
    }catch(error){
      toast.error("Unable to copy text")
    }
  }
function closeFN(){
  $('.mobile-header-content').removeClass('active');
}
  return (
    <div className="mobile-header-content d-flex flex-wrap justify-content-lg-between align-items-center">
      {openModal === true ? (
        <Modals open={true} setModal={setModal} id="13" />
      ) : null}

      {/* <ul className="support-area">
        <li>
          <a href="tel:18001204099 ">
            <i className="flaticon-support" />
            Support : 18001204099
          </a>
        </li>
        <li>
          <a onClick={closeFN} className="closehand"  >
           
            X
          </a>
        </li>
      </ul> */}
      <div className="dashboard-header-right d-flex flex-wrap justify-content-center justify-content-sm-between justify-content-lg-end align-items-center">
       
        <ul className="dashboard-right-menus">
          
            
          <li>
            <a className="author">
              <div className="thumb">
                <img
                  src="./assets/images/dashboard/author.png"
                  alt="dashboard"
                />
                <span className="checked">
                  <i className="flaticon-checked"></i>
                </span>
              </div>
              <div className="content">
                <h6 className="title">{localStorage.getItem("name") || ""}</h6>
               
              </div>
            </a>
            <div className="notification-area">
              <div className="author-header">
                <div className="thumb">
                  <img
                    src="./assets/images/dashboard/author.png"
                    alt="dashboard"
                  />
                </div>
                <h6 className="title">{localStorage.getItem("name") || ""}</h6>
                <p>{localStorage.getItem("role_name")}</p>
                <a href="#mailto:johndoe@gmail.com">
                  <span
                    className="__cf_email__"
                    data-cfemail="cb81a4a3a5afa4ae8baca6aaa2a7e5a8a4a6"
                  >
                    {/* [email&nbsp;protected] */}
                  </span>
                </a>
              </div>
              <div className="author-body">
                <ul>
                  <li>
                    <Link to="/Profile">
                      <i className="far fa-user"></i>Profile
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/Profile">
                      <i className="fas fa-user-edit"></i>Edit Profile
                    </Link>
                  </li> */}
                  <li>
                    <a onClick={() => kyc()}>
                      <i className="fas fa-user-edit"></i>KYC
                    </a>
                  </li>
                  <li onClick={() => copyText()} >
                    <Link >
                      <i className="fa fa-user-plus" aria-hidden="true">
                      </i>
                      Referral: {localStorage.getItem("referral_code") || ""}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
