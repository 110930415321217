import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useEffect, useState } from "react";
import Slide from "@mui/material/Slide";
import IconButton from "@mui/material/IconButton";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { toast } from "react-toastify";
import apiService from "../services/ApiService";
import OtpInput from "react-otp-input";
import axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ProfileModals({ isOpenBy, openModal, setModal, userData }) {

  const [IsOpenBy, SetIsOpenBy] = useState(isOpenBy);
  const [Country, setCountry] = useState({ name: "", id: "" });
  const [showPassword, setShowPassword] = useState(true);
  const [Loader, setLoader] = useState(false);
  const [Data, setData] = useState([]);
  const [CountryData, setCountryData] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [Otp, setOtp] = React.useState("");




  const handleClickShowPassword = () => setShowPassword((show) => !show);


  const handleModalClose = () => {
    setModal(false);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const GetAllCountry = async () => {
    try {
      setLoader(true);
      const res = await apiService().get(`allCountry`);
      setLoader(false);
      setCountryData(res.data.data);
    } catch (error) {
      setLoader(false);
      console.log(error);
      toast.error(error);
    }
  };

  const changePassword = () => {
    console.log("Password Data : ", Data)
    setData([])
    SetIsOpenBy(5);
  };

  const SetCountryID = (CountryId) => {
    setCountry((prevData) => ({ ...prevData, id: CountryId }))
  };

  const AddBankDetails = () => {
    Data['country_id'] = Country.id
    console.log("Bank Details : ", Data)
    handleModalClose()
    setData([])
  };



  const handleOTPClick = async () => {
    try {
      //   console.log("data", data);
      //   const res = await apiService().post("registerUser", data);
      //   toast.success("User registered successfully");
      //   navigate("/signin", {
      //     state: { email: data.email, password: data.password },
      //   });
      //   console.log(res);
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };


  const submitUserProfile = async (e, type) => {
    e.preventDefault()
    try {
      let body
      setIsSubmit(true)
      if (type == 1 && validateFormOne()) {
        body = form_one
      } else if (type == 2 && validateFormTwo()) {
        body = form_two

      } else if (type == 3 && validateFormThree()) {
        body = form_three

      } else if (type == 4 && validateFormFour()) {
        body = form_four
      }

      if (!body) return setIsSubmit(false)
      const result = await apiService().post('userProfileUpdate', { ...body, type: type })
      toast.success(result.data.message)
      handleModalClose()
      setIsSubmit(false)
    } catch (error) {
      setIsSubmit(false)
      console.log(error);
    }
  };

  useEffect(() => {
    if (IsOpenBy == 3) GetAllCountry();
  }, []);

  //_______// handle formOne   //____________________//
  const [form_one, setFormOne] = useState({
    user_name: userData.name,
    image_path: userData.user_image
  })

  const [formOneErrors, setFormOneErrors] = useState({});

  const validateFormOne = () => {
    let newErrors = {};
    if (!form_one.user_name.trim()) {
      newErrors.user_name = 'user name is required';
    }
    // Validate image upload
    if (!form_one.image_path) {
      newErrors.image_path = 'Image upload is required';
    } else if (!['image/jpeg', 'image/png', 'image/jpg'].includes(form_one.type)) {
      newErrors.image_path = 'Please upload a JPG, JPEG or PNG image';
    } else if (form_one.size > 2024 * 2024) {
      newErrors.image_path = 'Please upload an image smaller than 2 MB';
    }
    setFormOneErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const handleUserImage = async (event) => {
    const file = event.target.files[0]
    if (file) {
      if (!file.type.includes('image/')) {
        toast.error('Please upload an image!');
        return;
      }
      if (
        file.type !== 'image/jpeg' &&
        file.type !== 'image/png'
      ) {
        toast.error('Please upload a JPEG or PNG image!');
        return;
      }
      if (file.size > 2 * 1024 * 1024) {
        toast.error('File size should not exceed 2 MB!');
        return;
      }
      const formData = new FormData()
      formData.append('file', file)
      try {
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data', // set the Content-Type header to multipart/form-data
            'token': `${localStorage.getItem('token')}` // set your custom authorization header
          }
        };
        const result = await axios.post(`${process.env.REACT_APP_API_URL}sendUserImagePath`, formData, config)
        setFormOne((ps) => ({ ...ps, image_path: result.data.image_path, type: file.type, size: file.size }))
        setTimeout(() => {
          validateFormOne()
        }, 1000);
      } catch (error) {
        console.log(error);
      }
    }
  };


  //_______// handle formOne end //____________________//

  //_______// handle formTwo start //____________________//


  const [form_two, setFormTwo] = useState({
    address: userData.address
  })
  const [formTwoErrors, setFormTwoErrors] = useState({});

  const validateFormTwo = () => {
    let newErrors = {};
    if (!form_two.address.trim()) {
      newErrors.address = 'address is required';
    }

    setFormTwoErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  //_______// handle formTwo end //____________________//

  //_______// handle formThree start //____________________//
  const [form_three, setFormThree] = useState({
    account_no: null,
    ifsc_code: null,
    bank_name: null,
    account_holder_name: null,
    country_id: null
  })

  const [formThreeErrors, setFormThreeErrors] = useState({});

  const validateFormThree = () => {
    let newErrors = {};
    if (!form_three.account_no) {
      newErrors.account_no = 'account number is required';
    }
    if (!form_three.ifsc_code) {
      newErrors.ifsc_code = 'ifsc code is required';
    }
    if (!form_three.bank_name) {
      newErrors.bank_name = 'bank name is required';
    }
    if (!form_three.account_holder_name) {
      newErrors.account_holder_name = 'accout holder name is required';
    }
    if (!form_three.country_id) {
      newErrors.country_id = 'country is required';
    }

    setFormThreeErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  //_______// handle formThree end //____________________//

  //_______// handle formFour start //____________________//
  const [form_four, setFormfour] = useState({
    current_password: null,
    new_password: null,
    confirm_password: null
  })

  const [formFourErrors, setFormFourErrors] = useState({});

  const validateFormFour = () => {
    let errors = {};

    if (!form_four.current_password) {
      errors.current_password = 'current Password is required';
    }

    if (!form_four.new_password) {
      errors.new_password = 'new Password is required'
    }
    if (!form_four.confirm_password) {
      errors.confirm_password = 'Confirm password is required';
    } else if (form_four.new_password !== form_four.confirm_password) {
      errors.confirm_password = 'Passwords must match';
    }
    setFormFourErrors(errors);
    return Object.keys(errors).length === 0;
  };
  //_______// handle formFour end //____________________//


  return (
    <Dialog
      open={openModal}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleModalClose}
      aria-describedby="alert-dialog-slide-description"
      id="2"
    >
      <DialogTitle>{"Add Profile Details"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
        
          <i
            className="fa-regular fa-circle-xmark closebutton"
            onClick={() => handleModalClose()}
          ></i>
          <div className="modalpad">
            <form className="">
              {(() => {
                switch (IsOpenBy) {
                  //Profile details 
                  case 1:
                    return (
                      <div>
                        <TextField
                          fullWidth
                          type="file"
                          id="image_path"
                          name="image_path"
                          className="inputfeilds"
                          onChange={(handleUserImage)}
                        />
                        {formOneErrors.image_path && <span>{formOneErrors.image_path}</span>}
                        <TextField
                          fullWidth
                          label="Your Full Name"
                          id="user_name"
                          className="inputfeilds"
                          name="user_name"
                          defaultValue={form_one.user_name}
                          onChange={(event) => (setFormOne(prevState => ({ ...prevState, user_name: event.target.value })), validateFormOne())}
                        />
                        {formOneErrors.user_name && <span>{formOneErrors.user_name}</span>}
                        <button type="submit" onClick={(e) => submitUserProfile(e, 1)} className={`action-btn ${isSubmit ? 'loading' : ''}`} disabled={isSubmit}>
                          Submit
                        </button>
                      </div>
                    );

                  //Add Address
                  case 2:
                    return (
                      <>
                        <h6 className="text-left" >Edit Address</h6>
                        <TextField
                          fullWidth
                          label="Enter your Address "
                          id="address"
                          className="inputfeilds"
                          multiline
                          rows={3}
                          defaultValue={form_two.address}
                          onChange={(e) => setFormTwo({ address: e.target.value })}
                        />
                        {formTwoErrors.address && <span>{formTwoErrors.address}</span>}
                        <button type="submit" onClick={(e) => submitUserProfile(e, 2)} className={`action-btn ${isSubmit ? 'loading' : ''}`} disabled={isSubmit}>
                          Submit
                        </button>
                      </>
                    );

                  //Add Bank Details
                  case 3:
                    return (
                      <>
                        <div>
                          <FormControl className="w-100">
                            <InputLabel id="demo-multiple-checkbox-label">
                              Country
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={form_three.country_id}
                              label="Country"
                              onChange={(event) =>
                                setFormThree((prevState) => ({
                                  ...prevState,
                                  country_id: event.target.value,
                                }))
                              }
                            >
                              {CountryData.map((country) => (
                                <MenuItem key={country.id} value={country.id}>
                                  {country.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {formThreeErrors.country_id && <span>{formThreeErrors.country_id}</span>}
                        </div>
                        <div>
                          <TextField
                            fullWidth
                            label="Account Number"
                            id="account_no"
                            className="inputfeilds"
                            onChange={(event) =>
                              setFormThree((prevState) => ({
                                ...prevState,
                                account_no: event.target.value,
                              }))
                            }
                          />
                          {formThreeErrors.account_no && <span>{formThreeErrors.account_no}</span>}
                        </div>
                        <div>
                          <TextField
                            fullWidth
                            label="Bank Name"
                            id="bank_name"
                            className="inputfeilds"
                            onChange={(event) =>
                              setFormThree((prevState) => ({
                                ...prevState,
                                bank_name: event.target.value,
                              }))
                            }
                          />
                          {formThreeErrors.bank_name && <span>{formThreeErrors.bank_name}</span>}

                        </div>
                        <div>
                          <TextField
                            fullWidth
                            label="IFSC Code"
                            id="ifsc_code"
                            className="inputfields"
                            onChange={(event) =>
                              setFormThree((prevState) => ({
                                ...prevState,
                                ifsc_code: event.target.value,
                              }))
                            }
                          />
                          {formThreeErrors.ifsc_code && <span>{formThreeErrors.ifsc_code}</span>}
                        </div>
                        <div>
                          <TextField
                            fullWidth
                            label="Account Holder Name"
                            id="nickname"
                            className="inputfeilds"
                            onChange={(event) =>
                              setFormThree((prevState) => ({
                                ...prevState,
                                account_holder_name: event.target.value,
                              }))
                            }
                          />
                          {formThreeErrors.account_holder_name && <span>{formThreeErrors.account_holder_name}</span>}

                        </div>
                        <div className="text-center"
                          onClick={(e) => submitUserProfile(e, 3)}
                        >
                          <a className="custom-button text-white">Submit</a>
                        </div>
                      </>
                    );

                  //Change password
                  case 4:
                    return (
                      <div>
                        <FormControl
                          className="w-100 inputfeilds"
                          variant="outlined"
                        >

                          <InputLabel htmlFor="outlined-adornment-password">
                            Current Password
                          </InputLabel>
                          <OutlinedInput
                            id="curr_pass"
                            type={showPassword ? "password" : "text"}
                            onChange={(e) => setFormfour((pre => ({ ...pre, current_password: e.target.value })))}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Password"
                          />
                        </FormControl>
                        {formFourErrors.current_password && <span>{formFourErrors.current_password}</span>}
                        <FormControl
                          className="w-100 inputfeilds"
                          variant="outlined"
                        >
                          <InputLabel htmlFor="outlined-adornment-password">
                            New Password
                          </InputLabel>
                          <OutlinedInput
                            id="new_pass"
                            type={showPassword ? "password" : "text"}
                            onChange={(e) => setFormfour((pre => ({ ...pre, new_password: e.target.value })))}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Password"
                          />
                        </FormControl>
                        {formFourErrors.new_password && <span>{formFourErrors.new_password}</span>}

                        <FormControl
                          className="w-100 inputfeilds"
                          variant="outlined"
                        >
                          <InputLabel htmlFor="outlined-adornment-password">
                            Confirm Password
                          </InputLabel>
                          <OutlinedInput
                            id="conf_pass"
                            type={showPassword ? "password" : "text"}
                            onChange={(e) => setFormfour((pre => ({ ...pre, confirm_password: e.target.value })))}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Password"
                          />
                        </FormControl>
                        {formFourErrors.confirm_password && <span>{formFourErrors.confirm_password}</span>}
                        <div
                          className="text-center"
                          onClick={(e) =>
                            submitUserProfile(e, 4)
                          }
                        >
                          <a className="custom-button text-white">
                            Change Password
                          </a>
                        </div>
                      </div>
                    );

                  // OTP Modal
                  case 5:
                    return (
                      <div>
                        <form>
                          <div>
                            <p className="text-center">
                              We have shared an OTP with your registered Email Address
                            </p>
                            <div className=" d-flex  justify-content-center">
                              <OtpInput
                                value={Otp}
                                className="optinputs"
                                onChange={setOtp}
                                numInputs={4}
                                separator={<span>-</span>}
                              />
                            </div>
                            <p className="text-center">
                              Didn’t reveive any OTP?
                            </p>
                            <div className="text-center">
                              <a
                                className="custom-button text-white"
                                onClick={() => handleOTPClick()}
                              >
                                Verify
                              </a>
                            </div>
                          </div>
                        </form>
                      </div>
                    );
                }
              })()}
            </form>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
      </DialogActions>
    </Dialog>
  );
}

export default ProfileModals;
