import React from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SignIn from "../Components/SignIn";
import SignUp from "../Components/SignUp";
import Home from "../Components/Home";
import Contact from "../Components/Contact";
import Dashboard from "../Components/Dashboard";
import About from "../Components/About";
import Affiliate from "../Components/Affiliate";
import Portfolio from "../Components/Portfolio";
import Strategies from "../Components/Strategies";
import Operations from "../Components/Operations";
import Deposit from "../Components/Deposit";
import Withdraw from "../Components/Withdraw";
import Referral from "../Components/Referral";
import Setting from "../Components/Setting";
import Notifications from "../Components/Notifications";
import Support from "../Components/Support";
import Innerstrategy from "../Components/Innerstrategy";
import Profile from "../Components/Profile";
import Investmentplan from "../Components/Investmentplan";
import FAQ from "../Components/FAQ";
import "react-toastify/dist/ReactToastify.css";
import Earlymaturity from "../Components/Earlymaturity";
import "react-loading-skeleton/dist/skeleton.css";
import TermsCondition from "../Components/TermsCondition";
import PrivacyPolicy from "../Components/PrivacyPolicy";
import Bottomheader from "../Components/Bottomheader"
import PrivateRoute from "./PrivateRoutes";


function Routers() {

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<SignIn />}></Route>
        {/* <Route exact path="/signin" element={<SignIn />}></Route> */}
        {/* <Route exact path="/signup" element={<SignUp />}></Route>
        <Route exact path="/about" element={<About />}></Route>
        <Route exact path="/Strategies" element={<Strategies />}></Route>
        <Route exact path="/TermsCondition" element={<TermsCondition />}></Route>
        <Route exact path="/PrivacyPolicy" element={<PrivacyPolicy />}></Route>
        <Route exact path="/contact" element={<Contact />}></Route>
        <Route exact path="/FAQ" element={<FAQ />}></Route> */}

        <Route exact path="/Dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>}></Route>
        <Route exact path="/affiliate" element={<PrivateRoute><Affiliate /></PrivateRoute>}></Route>
        <Route exact path="/Portfolio" element={<PrivateRoute><Portfolio /></PrivateRoute>}></Route>
        <Route exact path="/Bottomheader" element={<PrivateRoute><Bottomheader /></PrivateRoute>}></Route> 
        <Route exact path="/Operations" element={<PrivateRoute><Operations /></PrivateRoute>}></Route>
        <Route exact path="/Deposit" element={<PrivateRoute><Deposit /></PrivateRoute>}></Route>
        <Route exact path="/Withdraw" element={<PrivateRoute><Withdraw /></PrivateRoute>}></Route>
        <Route exact path="/Referral" element={<PrivateRoute><Referral /></PrivateRoute>}></Route>
        <Route exact path="/Setting" element={<PrivateRoute><Setting /></PrivateRoute>}></Route>
        <Route exact path="/Notifications" element={<PrivateRoute><Notifications /></PrivateRoute>} ></Route>
        <Route exact path="/Support" element={<PrivateRoute><Support /> </PrivateRoute>}  ></Route>
        <Route exact path="/Innerstrategy" element={<PrivateRoute><Innerstrategy /></PrivateRoute>}></Route>
        {/* <Route exact path="/Sidebar" element={<PrivateRoute><Sidebar /></PrivateRoute>}  ></Route> */}
        <Route exact path="/Profile" element={<PrivateRoute><Profile /></PrivateRoute>}  ></Route>
        {/* <Route exact path="/DashboardHeader" element={<PrivateRoute><DashboardHeader /></PrivateRoute>}></Route> */}
        <Route exact path="/Investmentplan" element={<PrivateRoute><Investmentplan /></PrivateRoute>}></Route>
        {/* <Route exact path="/Paymentmodal" element={<PrivateRoute><Paymentmodal /></PrivateRoute>} ></Route> */}
        <Route exact path="/Earlymaturity" element={<PrivateRoute><Earlymaturity /></PrivateRoute>}></Route>
       
      </Routes>
    </Router>
  );
}

export default Routers;
