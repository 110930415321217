import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import DashboardHeader from "./DashboardHeader";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import apiService from "../services/ApiService";
import formatDate from "../utils/Dateformat";
import TableLoader from "./TableLoader";
import Pagination from "@mui/material/Pagination";
import Modals from "./Modals";
import { toast } from "react-toastify";
import { Balance } from "@mui/icons-material";

export default function Withdraw(props) {
  console.log(props);
  const [key, setKey] = useState("1");
  const [openModal, setModal] = useState(false);
  const [page, setPage] = useState(1);
  const [Data, setData] = useState([]);
  const [LoaderPlanList, setLoaderPlanList] = useState(false);
  const [LoaderUser, setLoaderUser] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [userPlanId, setUserPlanId] = useState(0);
  const [PlanList, setPlanList] = useState([]);
  const [perPageItem, setperPageItem] = useState(0);

  useEffect(() => {
    GetplanList();
  }, []);

  const fetchUserPlanList = async () => {
    try {
      setLoaderUser(true);
      const res = await apiService().get(
        `userPlanList?page=${page}&plan_id=${key}`
      );
      setLoaderUser(false);
      setTotalPage(res.data.lastPage);
      setData(res.data.data);
      setperPageItem(res.data.perPage);
    } catch (error) {
      console.log(error);
      setLoaderUser(false);
      toast.error(error);
    }
  };

  const GetplanList = async () => {
    try {
      setLoaderPlanList(true);
      setLoaderUser(true);
      const res = await apiService().get(`planList`);
      setLoaderPlanList(false);
      setPlanList(res.data.data);
      setKey(res?.data?.data[0]?.plan_id || 1);
    } catch (error) {
      setLoaderPlanList(false);
      setLoaderUser(false);
      console.log(error);
      toast.error(error);
    }
  };

  const pageChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    if (key === 0) return;
    fetchUserPlanList();
  }, [page, key]);

  return (
    <>
      {openModal === true
        ? (console.log(userPlanId),
          (
            <Modals
              open={true}
              setModal={setModal}
              id="8"
              userPlanId={userPlanId}
              Plan={key}
            />
          ))
        : null}

      <div>
        <div className="notify-overlay" />
        <section className="dashboard-section">
          <Sidebar></Sidebar>
          <div className="dasboard-body">
            <div className="dashboard-hero">
              <div className="header-top">
                <div className="container">
                  <div className="mobile-header d-flex justify-content-between d-lg-none align-items-center">
                    <div className="author">
                      <img
                        src="./assets/images/dashboard/author.png"
                        alt="dashboard"
                      />
                    </div>
                    <div className="cross-header-bar">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                  <DashboardHeader></DashboardHeader>
                </div>
              </div>
              <div className="dashboard-hero-content text-white ">
                <h3 className="title">Withdraw Funds</h3>
                <ul className="breadcrumb">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Withdraw Funds</li>
                </ul>
              </div>
            </div>
            <div className="container-fluid">
            
              <div className="row justify-content-center mt--85">
                <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                  <div className="dashboard-item">
                    <div className="dashboard-inner">
                      <div className="cont">
                        <span className="title">Balance</span>
                        <h5 className="amount">{props.data?.invested_amount} USDT</h5>
                      </div>
                      <div className="thumb">
                        <img
                          src="./assets/images/icon/Tether-USDT-icon.png"
                          alt="dashboard"
                          width="51px"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                  <div className="dashboard-item">
                    <div className="dashboard-inner">
                      <div className="cont">
                        <span className="title">Balance</span>
                        <h5 className="amount">{props.data?.invested_amount * 87.50} INR</h5>
                      </div>
                      <div className="thumb">
                        <img
                          src="./assets/images/icon/INR.png"
                          alt="dashboard"
                          width="51px"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="operations">
                <h3 className="main-title mt-5">Withdrawal History</h3>

                <div className="mt-2">
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                  >
                    {!LoaderPlanList && PlanList?.length > 0 ? (
                      PlanList.map((item, idx) => (
                        <Tab
                          key={idx}
                          eventKey={item.plan_id}
                          title={item.name}
                          width="50"
                        ></Tab>
                      ))
                    ) : (
                      <>
                        {" "}
                        {!LoaderPlanList && Data?.length === 0 ? (
                          "No data available"
                        ) : (
                          <TableLoader />
                        )}
                      </>
                    )}
                  </Tabs>

                  <form className="operation-filter">
                    <div className="filter-item">
                      <label for="date">Date from:</label>
                      <input type="date" placeholder="Date from" />
                    </div>
                    <div className="filter-item">
                      <label for="date">Date To:</label>
                      <input type="date" placeholder="Date from" />
                    </div>
                    {/* <div className="filter-item">
                    <label>Operation:</label>
                    <div className="select-item">
                      <select
                        className="select-bar"
                       
                      >
                        <option value="o1">Add funds</option>
                        <option value="o2">Withdraw funds</option>
                        <option value="o4">Deposit funds</option>
                        <option value="o3">Transfer funds</option>
                      </select>
                      <div className="nice-select select-bar" tabindex="0">
                        <span className="current">Add funds</span>
                        <ul className="list">
                          <li data-value="o1" className="option selected">
                            Add funds
                          </li>
                          <li data-value="o2" className="option">
                            Withdraw funds
                          </li>
                          <li data-value="o4" className="option">
                            Deposit funds
                          </li>
                          <li data-value="o3" className="option">
                            Transfer funds
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="filter-item">
                    <label>Status:</label>
                    <div className="select-item">
                      <select
                        className="select-bar"
                       
                      >
                        <option value="p1">Prepared</option>
                        <option value="p2">Prepared</option>
                        <option value="p3">Prepared</option>
                        <option value="p4">Prepared</option>
                        <option value="p5">Prepared</option>
                        <option value="p6">Prepared</option>
                      </select>
                      <div className="nice-select select-bar" tabindex="0">
                        <span className="current">Prepared</span>
                        <ul className="list">
                          <li data-value="p1" className="option selected">
                            Prepared
                          </li>
                          <li data-value="p2" className="option">
                            Prepared
                          </li>
                          <li data-value="p3" className="option">
                            Prepared
                          </li>
                          <li data-value="p4" className="option">
                            Prepared
                          </li>
                          <li data-value="p5" className="option">
                            Prepared
                          </li>
                          <li data-value="p6" className="option">
                            Prepared
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div> */}
                    <div className="filter-item">
                      <button type="submit" className="custom-button">
                        Filter
                      </button>
                    </div>
                  </form>
                  <div className="table-wrapper">
                    <table className="transaction-table">
                      <thead>
                        <tr>
                          <th className="text-truncate">Sr.No.</th>
                          <th className="text-truncate">Plan Name</th>
                          <th className="text-truncate">Start Date</th>
                          <th className="text-truncate">End Date</th>

                          <th className="text-truncate">Amount</th>
                          <th className="text-truncate">Received Amount</th>

                          <th className="text-truncate">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!LoaderUser && Data?.length > 0 ? (
                          Data.map((item, idx) => (
                            <tr key={idx}>
                              <td>
                                {" "}
                                {page * perPageItem - perPageItem + idx + 1}
                              </td>
                              <td
                                onClick={() => (
                                  setUserPlanId(item.user_plan_id),
                                  setModal(true)
                                )}
                                className="planduration text-truncate"
                              >
                                <i className="fa-solid fa-language"></i>
                                {item.plan_name}
                              </td>
                              <td className="text-truncate">
                                {formatDate(item.start_date)}
                              </td>
                              <td className="text-truncate">
                                {" "}
                                {formatDate(item.end_date)}
                              </td>
                              <td className="text-truncate">${item.amount}</td>
                              <td className="text-truncate">
                                ${item.amount_received}
                              </td>
                              <td
                                onClick={() => (
                                  setUserPlanId(item.user_plan_id),
                                  setModal(true)
                                )}
                                className="planduration text-truncate text-info"
                              >
                                View Payment
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="7">
                              {!LoaderUser && Data?.length === 0 ? (
                                "No data available"
                              ) : (
                                <TableLoader />
                              )}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                   
                  </div>
                  {!LoaderUser && Data?.length > 0 ? (
                      <Pagination
                        page={page}
                        count={totalPage}
                        onChange={pageChange}
                      />
                    ) : null}
                </div>
              </div>
            </div>
            <div className="container-fluid sticky-bottom">
              <div className="dashboard-footer">
                <div className="d-flex flex-wrap justify-content-between m-0-15-none">
                  <div className="left">
                    © 2023 <span className="version-09">version - 0.9</span>{" "}
                    &nbsp;&nbsp;&nbsp; <a href="#0">WYscale</a> | All right
                    reserved.
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
