import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import Sidebar from "./Sidebar";
import DashboardHeader from "./DashboardHeader";
import Balance from "./Balance";
export default function Deposite() {

 

  return (
    <div>
      <div className="notify-overlay" />
      <section className="dashboard-section">
        <Sidebar></Sidebar>
        <div className="dasboard-body">
                <div className="dashboard-hero">
                <div className="header-top">
              <div className="container">
                <div className="mobile-header d-flex justify-content-between d-lg-none align-items-center">
                  <div className="author">
                    <img
                      src="./assets/images/dashboard/author.png"
                      alt="dashboard"
                    />
                  </div>
                  <div className="cross-header-bar">
                    <span />
                    <span />
                    <span />
                  </div>
                </div>
                <DashboardHeader></DashboardHeader>
              </div>
            </div>
                    <div className="dashboard-hero-content text-white pb-1">
                        <h3 className="title">Notification</h3>
                        <ul className="breadcrumb">
                        <li>
                <Link to='/'>Home</Link>
                </li>
                            <li>
                                Notification
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="container-fluid">
                   {/* <Balance/> */}
                    <div className="partners">
                        <h3 className="main-title">Notification</h3>
                        <div className="notification-check-area">
                            <h4 className="subtitle">Notification Setting</h4>
                            <div className="check_boxes">
                                <div className="check_box_group">
                                    <input type="checkbox" name="notify" id="notify_1" />
                                    <label for="notify_1">Deposit Confirmation</label>
                                </div>
                                <div className="check_box_group">
                                    <input type="checkbox" name="notify" id="notify_2" checked="" />
                                    <label for="notify_2">Deposit Request</label>
                                </div>
                                <div className="check_box_group">
                                    <input type="checkbox" name="notify" id="notify_3" />
                                    <label for="notify_3">Support Tickets</label>
                                </div>
                                <div className="check_box_group">
                                    <input type="checkbox" name="notify" id="notify_4" checked="" />
                                    <label for="notify_4">Change Password</label>
                                </div>
                                <div className="check_box_group">
                                    <input type="checkbox" name="notify" id="notify_5" />
                                    <label for="notify_5">Fund Transfer Credit</label>
                                </div>
                                <div className="check_box_group">
                                    <input type="checkbox" name="notify" id="notify_6" checked="" />
                                    <label for="notify_6">Withdrawal</label>
                                </div>
                                <div className="check_box_group">
                                    <input type="checkbox" name="notify" id="notify_7" /> 
                                    <label for="notify_7">Exchange Confirmation</label>
                                </div>
                                <div className="check_box_group">
                                    <input type="checkbox" name="notify" id="notify_8" />
                                    <label for="notify_8">New Referral</label>
                                </div>
                                <div className="check_box_group">
                                    <input type="checkbox" name="notify" id="notify_9" />
                                    <label for="notify_9">Referral Commission Earned</label>
                                </div>
                                <div className="check_box_group">
                                    <input type="checkbox" name="notify" id="notify_10" />
                                    <label for="notify_10">Profile Update</label>
                                </div>
                                <div className="check_box_group">
                                    <input type="checkbox" name="notify" id="notify_11" />
                                    <label for="notify_11">Fund Transfer Debit</label>
                                </div>
                                <div className="check_box_group">
                                    <input type="checkbox" name="notify" id="notify_12" />
                                    <label for="notify_12">Email Login Verification</label>
                                </div>
                                <div className="check_box_group">
                                    <input type="checkbox" name="notify" id="notify_13" />
                                    <label for="notify_13">Email IP Verification</label>
                                </div>
                            </div>
                            <div className="text-center">
                                <a href="#0" className="custom-button btn-sm">change account data</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid sticky-bottom">
                    <div className="dashboard-footer">
                        <div className="d-flex flex-wrap justify-content-between m-0-15-none">
                            <div className="left">
                                © 2023 <span className="version-09" >version - 0.9</span> &nbsp;&nbsp;&nbsp; <a href="#0">WYscale</a>  |  All right reserved.
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
      </section>
    </div>
  );
}
