import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Sidebar from "./Sidebar";
import DashboardHeader from "./DashboardHeader";
import Balance from "./Balance";
import { toast } from "react-toastify";
import apiService from "../services/ApiService";

export default function Dashboard() {
  const [Data, setData] = useState();
  const [Loader, setLoader] = useState(false);

  const User_dashboard = async () => {
    try {
      setLoader(true);
      const res = await apiService().get(`user_dashboard`);
      setLoader(false);
      setData(res.data);
    } catch (error) {
      setLoader(false);
      console.log(error)
      toast.error(error)
    }
  };

  useEffect(() => {
    User_dashboard()
  },[])
 
  return (
    <div>
      <div className="notify-overlay" />
      <section className="dashboard-section">
        <Sidebar></Sidebar>
        <div className="dasboard-body">
          <div className="dashboard-hero">
            <div className="header-top">
              <div className="container">
                <div className="mobile-header d-flex justify-content-between d-lg-none align-items-center">
                  <div className="author">
                    <img
                      src="./assets/images/dashboard/author.png"
                      alt="dashboard"
                    />
                  </div>
                  <div className="cross-header-bar">
                    <span />
                    <span />
                    <span />
                  </div>
                </div>
                <DashboardHeader></DashboardHeader>
              </div>
            </div>
            <div className="dashboard-hero-content text-white">
              <h3 className="title">Dashboard</h3>
              <ul className="breadcrumb">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Dashboard</li>
              </ul>
            </div>
          </div>
          <div className="container-fluid">
           <Balance data={Data}/>
            <div className="row pb-30">
              <div className="col-lg-6">
                <div className="total-earning-item">
                  <div className="total-earning-heading">
                    <h5 className="title">Total  Yield </h5>
                    {/* <h4 className="amount cl-1">${Data?.invested_amount}</h4> */}
                  </div>
                  <div className="d-flex flex-wrap justify-content-between">
                    <div className="item">
                      <div className="cont">
                        <h4 className="cl-theme"> {Data?.total_Yield}%</h4>
                        <span className="month">Month Profit</span>
                      </div>
                      <div className="thumb">
                        <img
                          src="/assets/images/dashboard/graph1.png"
                          alt="dashboard"
                        />
                      </div>
                    </div>
                    {/* <div className="item">
                      <div className="cont">
                        <h4 className="cl-1">+.12%</h4>
                        <span className="month">APY Profit</span>
                      </div>
                      <div className="thumb">
                        <img
                          src="./assets/images/dashboard/graph2.png"
                          alt="dashboard"
                        />
                      </div>
                    </div> */}
                  </div>
                  {/* <div className="text-center">
                    <a href="#0" className="normal-button">
                      Explore <i className="fas fa-arrow-right" />
                    </a>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="progress-wrapper mb-30">
                  <h5 className="title">Progress</h5>
                  <div className="row mt-4" >
                    
                    <div className="col-6 col-sm-6  col-md-3 " >
                    <div className="flex-wrap m-0-15-20-none">
                    <div className="circle-item left-and-right">
                      <span className="month text-dark">Base APY </span>
                      <div className="progress1 circle">
                      <p className="cl-theme">+25% /Yr</p>
                     
                     
                      </div>
                    </div>
                    <div>
                     
                    </div>
                  </div>
                    </div>
                    <div className="col-6 col-sm-6  col-md-3" >
                    <div className=" flex-wrap m-0-15-20-none">
                    <div className="circle-item left-and-right">
                      <span className="month text-dark">Max. APY</span>
                      <div className="progress1 circle">
                      <p className="cl-theme">+50% / Yr</p>
                     
                      </div>
                    </div>
                    <div>
                    
                    </div>
                  </div>
                    </div>
                    <div className="col-6 col-sm-6  col-md-3" >
                    <div className=" flex-wrap m-0-15-20-none">
                    <div className="circle-item left-and-right">
                      <span className="month text-dark">ROI Earned  </span>
                      <div className="progress1 circle">
                      <p className="cl-theme">$ 345.2433</p>
                     
                      </div>
                    </div>
                    <div>
                     
                    </div>
                  </div>
                    </div>
                    <div className="col-6 col-sm-6  col-md-3" >
                    <div className=" flex-wrap m-0-15-20-none">
                    <div className="circle-item ">
                      <span className="month text-dark">ROI Redeemed  </span>
                      <div className="progress1 circle">
                      <p className="cl-theme">20%</p>
                     
                      </div>
                    </div>
                    <div>
                     
                    </div>
                  </div>
                    </div>
                  </div>
                 
                 
                </div>
              </div>
              <div className="col-xl-6">
                <div className="earn-item mb-30">
                  <div className="earn-thumb">
                    <img
                      src="./assets/images/dashboard/earn/01.png"
                      alt="dashboard-earn"
                    />
                  </div>
                  <div className="earn-content">
                    <h6 className="title">Active deposits in the amount of</h6>
                    <ul className="mb--5">
                      <li>
                        <div className="icon">
                          <img
                            src="./assets/images/icon/Tether-USDT-icon.png"
                            alt="dashboard-earn"
                            width={"33px"}
                          />
                        </div>
                        <div className="cont">
                          <span className="cl-1">{Data?.invested_amount} </span>
                          <span className="cl-4">USDT</span>
                        </div>
                      </li>
                      {/* <li>
                        <div className="icon">
                          <img
                            src="./assets/images/icon/USDCName.png"
                            width={"30px"}
                            alt="dashboard-earn"
                          />
                        </div>
                        <div className="cont">
                          <span className="cl-1">0.000000</span>
                          <span className="cl-4">USDC</span>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <img
                            src="./assets/images/icon/INR.png"
                            width={"30px"}
                            alt="dashboard-earn"
                          />
                        </div>
                        <div className="cont">
                          <span className="cl-1">{!Loader?Data.invested_amount:'--'}</span>
                          <span className="cl-4"> INR</span>
                        </div>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-6">
                <div className="earn-item mb-30">
                  <div className="earn-thumb">
                    <img
                      src="./assets/images/dashboard/earn/02.png"
                      alt="dashboard-earn"
                    />
                  </div>
                  <div className="earn-content partner-content d-flex flex-wrap align-items-start justify-content-between">
                    <h6 className="title w-100">All partners</h6>
                    <ul className="mb--5">
                      <li>
                        <div className="icon">
                          <img
                            src="./assets/images/dashboard/earn/active.png"
                            alt="dashboard-earn"
                          />
                        </div>
                        <div className="cont">
                          <span className="cl-4">Active :</span>
                          <span className="cl-1">40</span>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <img
                            src="./assets/images/dashboard/earn/inactive.png"
                            alt="dashboard-earn"
                          />
                        </div>
                        <div className="cont">
                          <span className="cl-4">Inactive :</span>
                          <span className="cl-1">05</span>
                        </div>
                      </li>
                    </ul>
                    <div className="total-partner">
                      <span className="total-title">45</span>
                      <span>Total</span>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col-lg-6 col-xl-6">
                <div className="earn-item mb-30">
                  <div className="earn-thumb">
                    <img
                      src="./assets/images/dashboard/earn/03.png"
                      alt="dashboard-earn"
                    />
                  </div>
                  <div className="earn-content">
                    <h6 className="title">Earned Referral</h6>
                    <ul className="mb--5">
                      <li>
                        <div className="icon">
                          <img
                            src="./assets/images/icon/Tether-USDT-icon.png"
                            alt="dashboard-earn"
                            width={"33px"}
                          />
                        </div>
                        <div className="cont">
                          <span className="cl-1">{Data?.earn_referral}</span>
                          <span className="cl-4"> USDT</span>
                        </div>
                      </li>
                      {/* <li>
                        <div className="icon">
                          <img
                            src="./assets/images/icon/USDCName.png"
                            width={"30px"}
                            alt="dashboard-earn"
                          />
                        </div>
                        <div className="cont">
                          <span className="cl-1">0.000000</span>
                          <span className="cl-4">USDC</span>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <img
                            src="./assets/images/icon/INR.png"
                            width={"30px"}
                            alt="dashboard-earn"
                          />
                        </div>
                        <div className="cont">
                          <span className="cl-1">{!Loader?Data.earn_referral:'--'}</span>
                          <span className="cl-4"> INR</span>
                        </div>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-xl-6">
                <div className="earn-item mb-30">
                  <div className="earn-thumb">
                    <img
                      src="./assets/images/dashboard/earn/04.png"
                      alt="dashboard-earn"
                    />
                  </div>
                  <div className="earn-content">
                    <h6 className="title">Total Yield</h6>
                    <ul className="mb--5">
                      <li>
                        <div className="icon">
                          <img
                            src="./assets/images/icon/Tether-USDT-icon.png"
                            alt="dashboard-earn"
                            width={"33px"}
                          />
                        </div>
                        <div className="cont">
                          <span className="cl-1">{Data?.total_Yield}</span>
                          <span className="cl-4"> %</span>
                        </div>
                      </li>
                      {/* <li>
                        <div className="icon">
                          <img
                            src="./assets/images/icon/USDCName.png"
                            width={"30px"}
                            alt="dashboard-earn"
                          />
                        </div>
                        <div className="cont">
                          <span className="cl-1">0.000000</span>
                          <span className="cl-4">USDC</span>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <img
                            src="./assets/images/icon/INR.png"
                            width={"30px"}
                            alt="dashboard-earn"
                          />
                        </div>
                        <div className="cont">
                          <span className="cl-1">0.00000</span>
                          <span className="cl-4">INR</span>
                        </div>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-xl-6">
                <div className="earn-item mb-30">
                  <div className="earn-thumb">
                    <img
                      src="./assets/images/dashboard/earn/05.png"
                      alt="dashboard-earn"
                    />
                  </div>
                  <div className="earn-content">
                    <h6 className="title">Amount Withdrawal Available</h6>
                    <ul className="mb--5">
                      <li>
                        <div className="icon">
                          <img
                            src="./assets/images/icon/Tether-USDT-icon.png"
                            alt="dashboard-earn"
                            width={"33px"}
                          />
                        </div>
                        <div className="cont">
                          <span className="cl-1">{Data?.withdrawable_amount}</span>
                          <span className="cl-4"> USDT</span>
                        </div>
                      </li>
                      {/* <li>
                        <div className="icon">
                          <img
                            src="./assets/images/icon/USDCName.png"
                            width={"30px"}
                            alt="dashboard-earn"
                          />
                        </div>
                        <div className="cont">
                          <span className="cl-1">0.000000</span>
                          <span className="cl-4">USDC</span>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <img
                            src="./assets/images/icon/INR.png"
                            width={"30px"}
                            alt="dashboard-earn"
                          />
                        </div>
                        <div className="cont">
                          <span className="cl-1">{!Loader?Data.withdraw_amount:'--'}</span>
                          <span className="cl-4"> INR</span>
                        </div>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-6">
                <div className="earn-item small-thumbs mb-30">
                  <div className="earn-thumb">
                    <img
                      src="./assets/images/dashboard/earn/06.png"
                      alt="dashboard-earn"
                    />
                  </div>
                  <div className="earn-content">
                    <h6 className="title">Latest Registered Partner</h6>
                    <ul className="mb--5">
                      <li>
                        <div className="cont w-100 p-0">
                          <span className="cl-1">Adrian54</span>
                          <a href="Mailto:demo@mail.com" className="cl-4">
                            Email: demo@mail.com
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="earn-item small-thumbs mb-30">
                  <div className="earn-thumb">
                    <img
                      src="./assets/images/dashboard/earn/07.png"
                      alt="dashboard-earn"
                    />
                  </div>
                  <div className="earn-content">
                    <h6 className="title">The last Referral Calculation</h6>
                    <ul className="mb--5">
                      <li>
                        <div className="icon">
                          <img
                            src="./assets/images/icon/Tether-USDT-icon.png"
                            alt="dashboard-earn"
                          />
                        </div>
                        <div className="cont">
                          <span className="cl-1">0.00</span>
                          <span className="cl-4">USDT</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="container-fluid sticky-bottom">
            <div className="dashboard-footer">
              <div className="d-flex flex-wrap justify-content-between m-0-15-none">
                <div className="left">
                  © 2023 <span className="version-09" >version - 0.9</span> &nbsp;&nbsp;&nbsp; <a href="#0">WYscale</a> | All right reserved.
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
