import React from 'react'

function Balance(props) {
console.log(props);
  return (
    <div className="row justify-content-center mt--85">
              <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                <div className="dashboard-item">
                  <div className="dashboard-inner">
                    <div className="cont">
                      <span className="title">Balance</span>
                      <h5 className="amount">{props.data?.invested_amount ?props.data?.invested_amount :0 } USDT</h5>
                    </div>
                    <div className="thumb">
                      <img
                        src="./assets/images/icon/Tether-USDT-icon.png"
                        alt="dashboard"
                        width={"51px"}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                <div className="dashboard-item">
                  <div className="dashboard-inner">
                    <div className="cont">
                      <span className="title">Balance</span>
                      <h5 className="amount">0 USDC</h5>
                    </div>
                    <div className="thumb">
                      <img
                        src="./assets/images/icon/USDCName.png"
                        alt="dashboard"
                        width={"51px"}
                      />
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                <div className="dashboard-item">
                  <div className="dashboard-inner">
                    <div className="cont">
                      <span className="title">Balance</span>
                      <h5 className="amount">{props.data?.invested_amount ? props.data?.invested_amount * 87.50:0} INR</h5>
                    </div>
                    <div className="thumb">
                      <img
                        src="./assets/images/icon/INR.png"
                        alt="dashboard"
                        width={"51px"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
  )
}

export default Balance