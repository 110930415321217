const network = {
    80001: {
        id: 80001,
        name: 'polygon Testnet',
        network: 'polygon_testnet',
        iconUrl: 'https://example.com/polygon-icon.svg',
        iconBackground: '#fff',
        nativeCurrency: {
          decimals: 18,
          name: 'polygon',
          symbol: 'MATIC',
        },
        rpcUrls: {
          default: {
            http: ['https://matic-mumbai.chainstacklabs.com'],
          },
        },
        blockExplorers: {
          default: { name: 'Mumbai polygon Testnet Explorer', url: 'https://mumbai.polygonscan.com' },
        },
        // 
        config: {
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: "0x13881",
              chainName: "mumbai polygon",
              nativeCurrency: {
                symbol: "MATIC",
                decimals: 18,
              },
              rpcUrls: ["https://matic-mumbai.chainstacklabs.com"],
              blockExplorerUrls: ["https://mumbai.polygonscan.com"],
            },
          ],
        },
        contractAddress:"0x3e57782363e49EAB8dbD1Ed0B838aFbDFAfA59d1",
        tokenAddress:["0x3da70078df5adf7cbea431d243f061f960fc97b1"]
      },

      137: {
        id: 137,
        name: 'polygon',
        network: 'polygon testnet',
        iconUrl: 'https://example.com/polygon-icon.svg',
        iconBackground: '#fff',
        nativeCurrency: {
          decimals: 18,
          name: 'polygon',
          symbol: 'MATIC',
        },
        rpcUrls: {
          default: {
            http: ['https://polygon.llamarpc.com'],
          },
        },
        blockExplorers: {
          default: { name: 'Mumbai polygon  Explorer', url: 'https://polygonscan.com/' },
        },
        // 
        config: {
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: "0x89",
              chainName: "mumbai polygon",
              nativeCurrency: {
                symbol: "MATIC",
                decimals: 18,
              },
              rpcUrls: ["https://polygon.llamarpc.com"],
              blockExplorerUrls: ["https://polygonscan.com/"],
            },
          ],
        },
        contractAddress:"0x3Aa47978a051E0457FB962d37a0E883CC5F9F1AA",
        tokenAddress:["0xc2132D05D31c914a87C6611C10748AEb04B58e8F"]
      },
      97: {
        id: 97,
        name: 'BSC',
        network: 'BSC testnet',
        iconUrl: 'https://example.com/polygon-icon.svg',
        iconBackground: '#fff',
        nativeCurrency: {
          decimals: 18,
          name: 'BSC TEST',
          symbol: 'BNB',
        },
        rpcUrls: {
          default: {
            http: ['https://data-seed-prebsc-1-s2.binance.org:8545'],
          },
        },
        blockExplorers: {
          default: { name: 'bsc testnet  Explorer', url: 'https://testnet.bscscan.com/' },
        },
        // 
        config: {
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: "0x61",
              chainName: "Binance Smart Chain Testnet",
              nativeCurrency: {
                symbol: "BNB",
                decimals: 18,
              },
              rpcUrls: ["https://data-seed-prebsc-1-s2.binance.org:8545"],
              blockExplorerUrls: ["https://testnet.bscscan.com"],
            },
          ],
        },
        contractAddress:"0xB5F92d76DAF523232412621C40320F61EB59dd1B",
        tokenAddress:["0xfA4d838711c5d1442e126DdBB06F22e4A5e99D18","0xBe7e08177B1c6d828dBF5aa51F737b44d9F1e215"] //on index 0 address for BEP20-USDT and on index 1 BEP20-BUSD

      },
      56: {
        id: 56,
        name: 'BSC',
        network: 'Binance Smart Chain mainnet',
        iconUrl: 'https://example.com/polygon-icon.svg',
        iconBackground: '#fff',
        nativeCurrency: {
          decimals: 18,
          name: 'BSC mainnet',
          symbol: 'BNB',
        },
        rpcUrls: {
          default: {
            http: ['https://bsc-dataseed3.binance.org'],
          },
        },
        blockExplorers: {
          default: { name: 'bsc mainnet  Explorer', url: 'https://bscscan.com/' },
        },
        // 
        config: {
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: "0x38",
              chainName: "Binance Smart Chain mainnet",
              nativeCurrency: {
                symbol: "BNB",
                decimals: 18,
              },
              rpcUrls: ["https://bsc-dataseed3.binance.org"],
              blockExplorerUrls: ["https://bscscan.com/"],
            },
          ],
        },
        contractAddress:"0x0ede92f8983db02364c621f2a99a04272aeb4367",
        tokenAddress:["0x55d398326f99059fF775485246999027B3197955","0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"] //on index 0 address for BEP20-USDT and on index 1 BEP20-BUSD
      }

}
export default network