
import { Dialog, Slide } from '@mui/material'
import React, { useState } from 'react'


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
function UserDepositeConfirmation({ onclose, onconfirm }) {
    const [selectedOption, setSelectedOption] = useState('');

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };
    return (
        <>
            <Dialog
                open={true}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <span className="text-right m-3" onClick={onclose} ><i className="fa-regular fa-circle-xmark"></i></span>
                <div className="col-md-12 px-3 payconfrim ">
                    <div style={{display:"flex", justifyContent:"space-around"}} >
                        <label className='d-flex'  >
                            <input
                                type="radio"
                                value="0"
                                checked={selectedOption === '0'}
                                onChange={handleOptionChange} style={{width:"35px"}}
                            />
                            <p>In Inr</p>
                        </label>

                        <label className='d-flex' >
                            <input
                                type="radio"
                                value="1"
                                checked={selectedOption === '1'}
                                onChange={handleOptionChange} style={{width:"35px"}}
                            />
                           <p>In Crypto</p>
                        </label>
                    </div>
                  <div className='d-flex'  >
                  <button className='cancelpayment p-2 m-3' style={{lineHeight:"10px", fontSize:"16px"}} onClick={()=>onclose()}>cancel</button>
                    <button className='custom-button  p-2 m-3' style={{lineHeight:"10px", fontSize:"16px"}} onClick={()=>onconfirm(selectedOption)}>confirm</button>
                  </div>
                </div>
            </Dialog>
        </>
    )
}

export default UserDepositeConfirmation