import React, { useEffect, useState } from "react";
import loadjs from "loadjs";
import axios from "axios";
import Spinner from "./Spinner";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Footer from "./Footer";
import Header from "./Header";
import apiService from "../services/ApiService";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import ForgetPassword from "./ForgetPassword";

const SignIn = () => {

  const [openModal, setModal] = useState(false);
  const [key, setKey] = useState("home");
  const [Data, setData] = useState({
    email: "",
    password: "",
  });
  const [spinner, setSpinner] = useState(false);
  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState([]);
  const [isRecoverPassword, setRecoverPassword] = useState(false);
  const navigate = useNavigate();
  const params = useLocation();
  const state = params.state;

  useEffect(() => {
    loadjs("./assets/js/main.js", () => { });
    console.log(params);
    setData(() => ({
      email: state !== null ? state.email : "",
      password: state !== null ? state.password : "",
    }));
  }, []);

  const handleChange = (event) => {
    const id = event.target.name;
    const value = event.target.value;
    setData((data) => ({ ...data, [id]: value }));
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setSpinner(true);
      const res = await apiService().post("userLogin", Data);
      setSpinner(false);
      localStorage.setItem("name", res.data.name);
      localStorage.setItem("token", res.data.token);
      localStorage.setItem('email', res.data.email)
      if (res.data.wallet_address) {
        localStorage.setItem('userAddress', res.data.wallet_address)
      }
      if (res.data.tron_wallet_address) {
        localStorage.setItem('tronAddress', res.data.tron_wallet_address)
      }
      localStorage.setItem("referral_code", res.data.referral_code)
      localStorage.setItem("role_name", res.data.role_name)
      localStorage.setItem("userId", res.data.user_id)
      toast.success("User Login successfully");
      navigate("/Portfolio");
    } catch (error) {
      setSpinner(false);
      console.log(error);
      toast.error(error);
    }
  };

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => signInGoogle(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  const signInGoogle = async (data) => {
    try {
      setSpinner(true)
      const res = await apiService().post('userGoogleLogin', data)
      console.log(res)
      localStorage.setItem("token", res.data.token)
      localStorage.setItem("name", res.data.name)
      localStorage.setItem("email", res.data.email)
      if (res.data.wallet_address) {
        localStorage.setItem('userAddress', res.data.wallet_address)
      }
      if (res.data.tron_wallet_address) {
        localStorage.setItem('tronAddress', res.data.tron_wallet_address)
      }
      localStorage.setItem("userId", res.data.user_id)
      localStorage.setItem("referral_code", res.data.referral_code)
      localStorage.setItem("role_name", res.data.role_name)
      setSpinner(false)
      toast.success("User Login successfully");
      navigate("/Portfolio");
    } catch (error) {
      console.log(error)
      setSpinner(false)
      toast.error(error)
    }
  }

  // ///////////////////////////forget password ///////////////////////////
  function _forgetPasswordOnClose() {
    setRecoverPassword(false)

  }

  return (
    <>
      <Header />
      {isRecoverPassword ? <ForgetPassword open={true} _forgetPasswordOnClose={_forgetPasswordOnClose} /> : null}
      <div className="account-section signupbanner padding-top">
        {spinner == true ? <Spinner /> : null}

        <div className="container">
          <div className="account-title text-center">
            {/* <a href="/" className="back-home">
            <i className="fas fa-angle-left" />
            <span>
              Back <span className="d-none d-sm-inline-block">To WYscale</span>
            </span>
          </a> */}
            {/* <a href="/" className="logo" style={{ width: "180px" }}>
            <img
              src="./assets/images/logo/logo2.png"
              alt="logo"
              style={{ width: "100%" }} />
          </a> */}
          </div>
          <div className="account-wrapper ">
            <h4 className="title mb-20 text-center signuptitle">Welcome To WYscale</h4>
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
            >
              <Tab eventKey="home" width="50">
                <div className="account-body">
                  <form className="account-form">
                    <div className="form-group">
                      <label htmlFor="email">Your Email / Mob Number </label>
                      <input
                        type="text"
                        placeholder="Enter Your Email "
                        id="email"
                        name="email"
                        onChange={(e) => handleChange(e)}
                        value={Data.email}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="pass">Password</label>
                      <input
                        type="password"
                        placeholder="Enter Your Password"
                        id="pass"
                        name="password"
                        onChange={(e) => handleChange(e)}
                        value={Data.password}
                      />
                      <span className="sign-in-recovery">
                        Forgot your password?{" "}
                        <a onClick={() => setRecoverPassword(true)}>recover password</a>
                      </span>
                    </div>
                    <div className="form-group text-center">
                      <button
                        type="submit"
                        className="mt-2 mb-2"
                        onClick={(e) => handleSubmit(e)}
                      >
                        Sign In
                      </button>
                    </div>
                  </form>
                </div>
                {/* <div className="or">
                  <span>OR</span>
                </div>
                <div className="account-header pb-0">
                  <span className="d-block mb-30 mt-2">
                    Sign up with your work email
                  </span>
                  <a className="sign-in-with">
                    <img src="./assets/images/icon/google.png" alt="icon" />
                    <span onClick={() => login()}>SignIn with Google</span>
                  </a>
                  <span className="d-block mt-15">
                    Don't have an account? <a href="/signup">Sign Up Here</a>
                  </span>
                </div> */}
              </Tab>
              {/* <Tab eventKey="profile" title="Advisor">
              <div className="account-body">
                <form className="account-form">
                  <div className="form-group">
                    <label htmlFor="email">Your Email </label>
                    <input
                      type="text"
                      placeholder="Enter Your Email "
                      id="email"
                      name="email"
                      onChange={(e) => handleChange(e)}
                      value={Data.email} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="pass">Password</label>
                    <input
                      type="password"
                      placeholder="Enter Your Password"
                      id="pass"
                      name="password"
                      onChange={(e) => handleChange(e)}
                      value={Data.password} />
                    <span className="sign-in-recovery">
                      Forgot your password? <a href="#0">recover password</a>
                    </span>
                  </div>
                  <div className="form-group text-center">
                    <button
                      type="submit"
                      className="mt-2 mb-2"
                      onClick={(e) => handleSubmit(e)}
                    >
                      Sign In
                    </button>
                  </div>
                </form>
              </div>
              <div className="or">
                <span>OR</span>
              </div>
              <div className="account-header pb-0">
                <span className="d-block mb-30 mt-2">
                  Sign up with your work email
                </span>
                <a href="#0" className="sign-in-with">
                  <img src="./assets/images/icon/google.png" alt="icon" />
                  <span>Sign Up with Google</span>
                </a>
                <span className="d-block mt-15">
                  Don't have an account? <a href="/signup">Sign Up Here</a>
                </span>
              </div>
            </Tab> */}
            </Tabs>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SignIn;
