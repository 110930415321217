import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import loadjs from "loadjs";
import Sidebar from "./Sidebar";
import DashboardHeader from "./DashboardHeader";
export default function Setting() {
  useEffect(() => {
    loadjs("./assets/js/main.js", () => {});
  }, []);
 

  return (
    <div>
      <div className="notify-overlay" />
      <section className="dashboard-section">
        <Sidebar></Sidebar>
        <div className="dasboard-body">
                <div className="dashboard-hero">
                    <div className="header-top">
                        <div className="container">
                            <div className="mobile-header d-flex justify-content-between d-lg-none align-items-center">
                                <div className="author">
                                    <img src="./assets/images/dashboard/author.png" alt="dashboard" />
                                </div>
                                <div className="cross-header-bar">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                            <DashboardHeader></DashboardHeader>
                        </div>
                    </div>
                    <div className="dashboard-hero-content text-white">
                        <h3 className="title">Settings</h3>
                        <ul className="breadcrumb">
                        <li>
                <Link to='/'>Home</Link>
                </li>
                            <li>
                                Settings
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="container-fluid">
                <div className="row justify-content-center mt--85">
                        <div className="col-sm-6 col-lg-4">
                            <div className="dashboard-item">
                                <div className="dashboard-inner">
                                    <div className="cont">
                                        <span className="title">Balance</span>
                                        <h5 className="amount">011 USDT</h5>
                                    </div>
                                    <div className="thumb">
                                        <img src="./assets/images/icon/Tether-USDT-icon.png" alt="dashboard" width={"51px"} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4">
                            <div className="dashboard-item">
                                <div className="dashboard-inner">
                                    <div className="cont">
                                        <span className="title">Balance</span>
                                        <h5 className="amount">0 USDC</h5>
                                    </div>
                                    <div className="thumb">
                                        <img src="./assets/images/icon/USDCName.png" alt="dashboard" width={"51px"} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4">
                            <div className="dashboard-item">
                                <div className="dashboard-inner">
                                    <div className="cont">
                                        <span className="title">Balance</span>
                                        <h5 className="amount">0 INR</h5>
                                    </div>
                                    <div className="thumb">
                                        <img src="./assets/images/icon/INR.png" alt="dashboard" width={"51px"} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className="partners">
                        <h3 className="main-title">Settings</h3>
                        <div className="row mb-30-none">
                            <div className="col-lg-6 mb-30">
                                <div className="create_wrapper mw-100">
                                    <h5 className="subtitle">Personal Info</h5>
                                    <div className="d-flex align-items-center mb-30">
                                        <div className="update_user">
                                            <img src="./assets/images/dashboard/user.png" alt="dashboard" />
                                        </div>
                                        <div className="pl-3">
                                            <span className="sub_subtitle cl-title fz-sm d-block">JPEG Or PNG 500 x 500px</span>
                                            <label for="update_profile" className="custom-button m-0 mt-2 lh-40 h-40">Browse Image</label>
                                            <input type="file" id="update_profile" className="profile_update_input" />
                                        </div>
                                    </div>
                                    <form className="create_ticket_form row mb-30-none">
                                        <div className="create_form_group col-sm-12">
                                            <label for="account_name">Account Name:</label>
                                            <input type="text" id="account_name" placeholder="Account Name" />
                                        </div>
                                        <div className="create_form_group col-sm-12">
                                            <label for="full_name">Full Name:</label>
                                            <input type="text" id="full_name" placeholder="Full Name" />
                                        </div>
                                        <div className="create_form_group col-sm-12">
                                            <label for="account_email">Your Email Address:</label>
                                            <input type="text" id="account_email" placeholder="Enter your Email" />
                                        </div>
                                        <div className="create_form_group col-sm-12">
                                            <label for="account_mobile">Mobile No:</label>
                                            <input type="text" id="account_mobile" placeholder="Enter your Mobile No" />
                                        </div>
                                        <div className="create_form_group col-sm-12">
                                            <label for="account_address">Address:</label>
                                            <input type="text" id="account_address" placeholder="Enter Address" />
                                        </div>
                                        <div className="create_form_group col-sm-6">
                                            <label for="account_city">City:</label>
                                            <input type="text" id="account_city" placeholder="Enter your City" />
                                        </div>
                                        <div className="create_form_group col-sm-6">
                                            <label for="account_state">State</label>
                                            <input type="text" id="account_state" placeholder="Enter your State" />
                                        </div>
                                        <div className="create_form_group col-sm-6">
                                            <label for="country_name">Country:</label>
                                            <input type="text" id="country_name" placeholder="Enter your Country" />
                                        </div>
                                        <div className="create_form_group col-sm-6 align-self-end">
                                            <button type="submit" className="custom-button border-0">Save</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-30">
                                <div className="create_wrapper mw-100">
                                    <h5 className="subtitle">payment system</h5>
                                    <form className="create_ticket_form row mb-30-none">
                                        <div className="create_form_group col-sm-12">
                                            <label for="perfect_money">Perfect Money:</label>
                                            <input type="text" id="perfect_money" value="U12\34567" />
                                        </div>
                                        <div className="create_form_group col-sm-12">
                                            <label for="payer_account">Your Payeer acc no:</label>
                                            <input type="text" id="payer_account" value="P1234567" />
                                        </div>
                                        <div className="create_form_group col-sm-12">
                                            <label for="payment_gateway">Paypal:</label>
                                            <input type="text" id="payment_gateway" value="Johndoe@gmail.com" />
                                        </div>
                                        <div className="create_form_group col-sm-12">
                                            <label for="bitcoin_account_no">Your Bitcoin acc no:</label>
                                            <input type="text" id="bitcoin_account_no" value="55550002220022" />
                                        </div>
                                        <div className="create_form_group col-sm-12">
                                            <label for="litecoin_account">Your Litecoin acc no:</label>
                                            <input type="text" id="litecoin_account" value="55550002220022" />
                                        </div>
                                        <div className="create_form_group col-sm-12">
                                            <label for="dogecoin_account">Your Dogecoin acc no:</label>
                                            <input type="text" id="dogecoin_account" value="55550002220022" />
                                        </div>
                                        <div className="create_form_group col-sm-12">
                                            <label for="ethereum_account">Your Ethereum acc no:</label>
                                            <input type="text" id="ethereum_account" value="55550002220022" />
                                        </div>
                                        <div className="create_form_group col-sm-6 align-self-end">
                                            <button type="submit" className="custom-button border-0">Save</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-30">
                                <div className="create_wrapper mw-100">
                                    <h5 className="subtitle">Other settings</h5>
                                    <form className="create_ticket_form mb-30-none">
                                        <div className="create_form_group">
                                            <label for="old_pass">Your time zone:</label>
                                            <div className="select-item mb-3">
                                                <select className="select-bar" style={{"display": "none"}}>
                                                    <option value="time_zone_1">+00.00</option>
                                                    <option value="time_zone_2">+02.00</option>
                                                    <option value="time_zone_3">+03.00</option>
                                                    <option value="time_zone_4">+04.00</option>
                                                    <option value="time_zone_5">+05.00</option>
                                                    <option value="time_zone_6">+06.00</option>
                                                    <option value="time_zone_7">+07.00</option>
                                                    <option value="time_zone_8">+08.00</option>
                                                    <option value="time_zone_9">+09.00</option>
                                                </select><div className="nice-select select-bar" tabindex="0"><span className="current">+00.00</span><ul className="list"><li data-value="time_zone_1" className="option selected">+00.00</li><li data-value="time_zone_2" className="option">+02.00</li><li data-value="time_zone_3" className="option">+03.00</li><li data-value="time_zone_4" className="option">+04.00</li><li data-value="time_zone_5" className="option">+05.00</li><li data-value="time_zone_6" className="option">+06.00</li><li data-value="time_zone_7" className="option">+07.00</li><li data-value="time_zone_8" className="option">+08.00</li><li data-value="time_zone_9" className="option">+09.00</li></ul></div>
                                            </div>
                                            <div className="check_box_group">
                                                <input type="checkbox" name="time-zone" className="fz-sm" id="time_zone" checked="" />
                                                <label for="time_zone">Not to get alerts by E-mail</label>
                                            </div>
                                        </div>
                                        <div className="create_form_group">
                                            <label for="old_pass">Control of IP address changes:</label>
                                            <div className="select-item mb-3">
                                                <select className="select-bar" style={{"display": "none"}}>
                                                    <option value="ip_control_1">+00.00</option>
                                                    <option value="ip_control_2">+02.00</option>
                                                    <option value="ip_control_3">+03.00</option>
                                                    <option value="ip_control_4">+04.00</option>
                                                    <option value="ip_control_5">+05.00</option>
                                                    <option value="ip_control_6">+06.00</option>
                                                    <option value="ip_control_7">+07.00</option>
                                                    <option value="ip_control_8">+08.00</option>
                                                    <option value="ip_control_9">+09.00</option>
                                                </select><div className="nice-select select-bar" tabindex="0"><span className="current">+00.00</span><ul className="list"><li data-value="ip_control_1" className="option selected">+00.00</li><li data-value="ip_control_2" className="option">+02.00</li><li data-value="ip_control_3" className="option">+03.00</li><li data-value="ip_control_4" className="option">+04.00</li><li data-value="ip_control_5" className="option">+05.00</li><li data-value="ip_control_6" className="option">+06.00</li><li data-value="ip_control_7" className="option">+07.00</li><li data-value="ip_control_8" className="option">+08.00</li><li data-value="ip_control_9" className="option">+09.00</li></ul></div>
                                            </div>
                                            <div className="check_box_group">
                                                <input type="checkbox" name="time-zone" className="fz-sm" id="ip_control_3" />
                                                <label for="ip_control_3">Bind session to IP address</label>
                                            </div>
                                            <div className="check_box_group">
                                                <input type="checkbox" name="time-zone" className="fz-sm" id="ip_control_2" />
                                                <label for="ip_control_2">To prevent concurrent sessions</label>
                                            </div>
                                        </div>
                                        <div className="create_form_group align-self-end">
                                            <button type="submit" className="custom-button border-0">Save</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-30">
                                <div className="create_wrapper mw-100">
                                    <h5 className="subtitle">Change Passowrd</h5>
                                    <form className="create_ticket_form row mb-30-none">
                                        <div className="create_form_group col-sm-12">
                                            <label for="old_pass">Old Passowrd:</label>
                                            <input type="password" id="old_pass" placeholder="Enter your Old Password" />
                                        </div>
                                        <div className="create_form_group col-sm-12">
                                            <label for="new_pass">New password::</label>
                                            <input type="password" id="new_pass" placeholder="Enter your new password:" />
                                        </div>
                                        <div className="create_form_group col-sm-12">
                                            <label for="repeat_pass">Repeat the new password::</label>
                                            <input type="password" id="repeat_pass" placeholder="Repeat your new password:" />
                                        </div>
                                        <div className="create_form_group col-sm-6 align-self-end">
                                            <button type="submit" className="custom-button border-0">Save</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid sticky-bottom">
                    <div className="dashboard-footer">
                        <div className="d-flex flex-wrap justify-content-between m-0-15-none">
                            <div className="left">
                                © 2023 <span className="version-09" >version - 0.9</span> &nbsp;&nbsp;&nbsp; <a href="#0">WYscale</a>  |  All right reserved.
                            </div>
                            <div className="right">
                                <ul>
                                    <li>
                                        <a href="#0">Terms of use</a>
                                    </li>
                                    <li>
                                        <a href="#0">Privacy policy</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      </section>
    </div>
  );
}
