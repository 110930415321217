

import { Dialog, DialogContent, DialogTitle, Slide } from '@mui/material'
import React from 'react'
import { ethers } from 'ethers'
import { toast } from 'react-toastify';
import { useState } from 'react';
import { TronWebContractService } from '../../services/TronWebContractService';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function AddWalletAddressTron({ open, onCloseWalletAddressTron }) {

    const [getAddress, setAddress] = useState('')

    async function onsubmit() {
        try {
            await TronWebContractService.getTronWeb()
            if (!window.tronLink.ready) return toast.error('tron not ready please unlock wallet')
            if (window.tronLink.tronWeb.isAddress(getAddress)) {
                onCloseWalletAddressTron(getAddress)
            } else {
                toast.error('please enter valid address')
            }
        } catch (e) {
           toast.error('tron wallet not install on your system')
        }
    }
    return (

        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
            id="3"
        >

            <DialogContent>
                <div className='card py-2 px-3 border-0' >
                    <div className='cardheadingflex' >
                        <h2>Tron Wallet Address</h2>
                        <span onClick={() => onCloseWalletAddressTron(null)}><i className="fa-regular fa-circle-xmark"></i></span>
                    </div>
                    <div className="row m-0 w-100 py-4">
                        <div className='container'>
                            <div className='form-group'>
                                <input type='text' placeholder='enter your wallet address' className='form-control' onChange={(e) => setAddress(e.target.value)} />
                            </div>
                            <div className='form-group'>
                                <button className='custom-button' onClick={onsubmit}>submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>

    )
}

export default AddWalletAddressTron