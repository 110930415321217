
import React from 'react'
import { Dialog, DialogContent, Slide } from '@mui/material'
import { useState } from 'react';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function SetAmountTron({ open, handleAmountDialogCloseT ,data}) {
    const [getAmount, setAmount] = useState(0)
    function onsubmit() {
        handleAmountDialogCloseT(getAmount)
    }
    const [errorMessage, setErrorMessage] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);


    const handleInputChange = (event) => {
        const value = event.target.value;
        setAmount(value)
        if (value < data.amount) {
          setErrorMessage('Minimum deposit amount is '+data.amount);
          setIsDisabled(true);
        } else {
          setErrorMessage('');
          setIsDisabled(false);
        }
      };

      

      

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
        >
           
            <DialogContent>
                <div className='card py-2 px-3 border-0' >
                    <div className='cardheadingflex'>
                    <h2>Amount</h2>
                    <span onClick={()=>handleAmountDialogCloseT(null)}><i className="fa-regular fa-circle-xmark"></i></span>

                    </div>
                </div>
                
                <div className="row m-0 w-100">
                    <div className='container'>
                        <div className='form-group'>
                            <input type='text' placeholder='enter amount' className='form-control' onChange={handleInputChange} />
                            {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
                        </div>
                        <div className='form-group'>
                            <button className='custom-button' disabled={isDisabled} onClick={onsubmit}>submit</button>
                        </div>
                    </div>
                </div>

            </DialogContent>
        </Dialog>
    )
}

export default SetAmountTron
