import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import DashboardHeader from "./DashboardHeader";
import Pagination from "@mui/material/Pagination";
import { toast } from "react-toastify";
import formatDate from "../utils/Dateformat";
import TableLoader from "./TableLoader";
import apiService from "../services/ApiService";

import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
export default function Oprations() {
  const [page, setPage] = useState(1);
  const [Data, setData] = useState([]);
  const [Loader, setLoader] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [perPageItem, setperPageItem] = useState(0);

  const UserWalletHistory = async () => {
    try {
      setLoader(true);
      const res = await apiService().get(`userWalletHistory?page=${page}`);
      setLoader(false);
      setData(res.data.data);
      setTotalPage(res.data.lastPage);
      setperPageItem(res.data.perPage);
    } catch (error) {
      setLoader(false);
      console.log(error)
      toast.error(error)
    }
  };

  const pageChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => { 
    UserWalletHistory();
  }, [page]);
/////==================tool tip ================//

const [hasTooltip, setHasTooltip] = useState([]);

  return (
    <div>
      <div className="notify-overlay" />
      <section className="dashboard-section">
        <Sidebar></Sidebar>
        <div className="dasboard-body">
          <div className="dashboard-hero ">
            <div className="header-top">
              <div className="container">
                <div className="mobile-header d-flex justify-content-between d-lg-none align-items-center">
                  <div className="author">
                    <img
                      src="./assets/images/dashboard/author.png"
                      alt="dashboard"
                    />
                  </div>
                  <div className="cross-header-bar">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
                <DashboardHeader></DashboardHeader>
              </div>
            </div>
            <div className="dashboard-hero-content text-white pb-1">
              <h3 className="title">Operations</h3>
              <ul className="breadcrumb">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Operations</li>
              </ul>
            </div>
          </div>
          <div className="container-fluid">
            {/* <Balance /> */}
            <div className="operations">
              <h3 className="main-title">Operations</h3>
              <form className="operation-filter">
                <div className="filter-item">
                  <label for="date">Date from:</label>
                  <input type="date" placeholder="Date from" />
                </div>
                <div className="filter-item">
                  <label for="date">Date To:</label>
                  <input type="date" placeholder="Date from" />
                </div>
                <div className="filter-item">
                  <label>Operation:</label>
                  <div className="select-item">
                    <select className="select-bar" style={{ display: "none" }}>
                      <option value="o1">Add funds</option>
                      <option value="o2">Withdraw funds</option>
                      <option value="o4">Deposit funds</option>
                      <option value="o3">Transfer funds</option>
                    </select>
                    <div className="nice-select select-bar" tabindex="1">
                      <span className="current">Add funds</span>
                      <ul className="list">
                        <li data-value="o1" className="option selected">
                          Add funds
                        </li>
                        <li data-value="o2" className="option">
                          Withdraw funds
                        </li>
                        <li data-value="o4" className="option">
                          Deposit funds
                        </li>
                        <li data-value="o3" className="option">
                          Transfer funds
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="filter-item">
                  <label>Status:</label>
                  <div className="select-item">
                    <select className="select-bar" style={{ display: "none" }}>
                      <option value="p1">Prepared</option>
                      <option value="p2">Prepared</option>
                      <option value="p3">Prepared</option>
                      <option value="p4">Prepared</option>
                      <option value="p5">Prepared</option>
                      <option value="p6">Prepared</option>
                    </select>
                    <div className="nice-select select-bar" tabindex="1">
                      <span className="current">Prepared</span>
                      <ul className="list">
                        <li data-value="p1" className="option selected">
                          Prepared
                        </li>
                        <li data-value="p2" className="option">
                          Prepared
                        </li>
                        <li data-value="p3" className="option">
                          Prepared
                        </li>
                        <li data-value="p4" className="option">
                          Prepared
                        </li>
                        <li data-value="p5" className="option">
                          Prepared
                        </li>
                        <li data-value="p6" className="option">
                          Prepared
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="filter-item">
                  <button type="submit" className="custom-button">
                    Filter
                  </button>
                </div>
              </form>
              <div className="table-wrapper">
                <table className="transaction-table">
                  <thead>
                    <tr>
                    <th>Sr.No.</th>
                      <th className="text-truncate" >Plan Name</th>
                      <th className="text-truncate">Investments Date</th>
                      <th className="text-truncate">Amount</th>
                      <th className="text-truncate">OPERATION</th>
                      <th className="text-truncate">payment method</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!Loader && Data?.length > 0 ? (
                      Data.map((item, idx) => (
                        <tr key={idx}>
                           <td> {page * perPageItem - perPageItem + idx + 1}</td>
                          <td className="planduration text-truncate">
                            <i className="fa-solid fa-language"></i>
                            {item.plan_name}
                          </td>
                          <td className="text-truncate">
                            {formatDate(item.date)}
                          </td>
                          <td className="text-truncate">${item.amount}</td>
                          <td className="text-truncate">
                            {item.type === 1 ? "Credit": item.type===2 ? "Debit":item.type===3?"Deposit":"withdraw"}
                          </td>
                          <td className="threeflex">
                            <img
                              src="./assets/images/dashboard/earn/btc2.png"
                              width={"20"} height={"20"}
                              alt="dashboard-earn"
                              className="my-auto"
                            />{" "}
                           <p className="my-auto" > USDT</p>
                            <div className="tooltipicon">
                                <IconButton aria-label="info">
                                  <InfoIcon
                                    onClick={() =>
                                      setHasTooltip(prevState => ({ ...prevState, [idx]: true }))
                                    }
                                  />
                                </IconButton>
                              </div>
                          </td>
                              
                             
                              {hasTooltip[idx] ? (
                                <div className="card tooltipcard">
                                  <span
                                    className="tooltipclose"
                                    onClick={() => setHasTooltip(prevState => ({ ...prevState, [idx]: false }))}
                                  >
                                    X
                                  </span>
                                  <div className="pt-4 px-2 "  >
                                    <div className="d-flex tooltipdflex">
                                      <h6>{item.message}</h6>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6">
                          {!Loader && Data?.length == 0 ? (
                            "No data available"
                          ) : (
                            <TableLoader />
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
               
              </div>
              {!Loader && Data?.length > 0 ? (
                  <Pagination
                    page={page}
                    count={totalPage}
                    onChange={pageChange}
                  />
                ) : null}
            </div>
          </div>
          <div className="container-fluid sticky-bottom">
            <div className="dashboard-footer">
              <div className="d-flex flex-wrap justify-content-between m-0-15-none">
                <div className="left">
                  © 2023 <span className="version-09">version - 0.9</span>{" "}
                  &nbsp;&nbsp;&nbsp; <a href="#0">WYscale</a> | All right
                  reserved.
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
