import React from "react";
import { ClipLoader } from "react-spinners";

export default function Spinner() {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(255, 255, 255, 0.4)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1,
      }}
    >
       <img src="./assets/images/loader-2-bg.gif" alt="Loader"/>
    </div>
  );
}
