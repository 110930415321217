import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Bottomheader from "./Components/Bottomheader"
// import Dashboard from './Dashboard';
import { GoogleOAuthProvider } from '@react-oauth/google';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  
  <GoogleOAuthProvider 
      clientId={process.env.REACT_APP_GOOGLE_CLIENTID}
    >
    <App />
  
  </GoogleOAuthProvider>
    // {/* <Dashboard /> */}
  // </React.StrictMode>
);
serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
