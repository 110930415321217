import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import loadjs from "loadjs";
import Modals from "./Modals";
function Bottomheader({id}) {
  const [isLogin, setLogin] = useState(false);
  const [openModal, setModal] = useState(false);
  const [displayName,setDisplayName] = useState(id)
  const navigate = useNavigate();
  useEffect(() => {
    loadjs("./assets/js/main.js", () => {});
    if (localStorage.getItem("token")) {
      setLogin(true);
    }
  }, []);

  const logout = () => {
    localStorage.clear();
    setLogin(false);
    navigate("/");
  };

  const handleClick = (id) => {
     if(id==0){
      navigate("/Portfolio")

     }else if(id==1)
     {
      navigate("/Strategies")
     }else{
      navigate("/Dashboard")
     }
  }

  return (
    <>
      
    
        {/*=======Header-Section Starts Here=======*/}
       
        <div className="Fixed-bottom">
        {isLogin == false ? (
          <>
            <p className="d-flex mb-0" style={{justifyContent:"space-evenly"}} >If you are not Login Please Login First <Link to="/signin" ><button className="custom-button"  >Login</button></Link> </p>
          </>
        ) : (
          <header >
            <div className="container">
              <div className="row">
                <div className="col-md-4 col-4 text-center m-auto">
                  <li className="headerfont">
                  {
                         displayName == 0 ? <p className="text-center bottomheadtext"> Portfolio</p>  : 
                         <img
                         src="/assets/images/icon/Portpolio.png"
                         width={"50px"}
                         className="m-auto"
                         onClick={() => handleClick(0)}
                       ></img>
                    }
                   
                    
                  </li>
                </div>
                <div className="col-md-4 col-4 text-center m-auto">
                  <li className="headerfont">
                    {
                         displayName == 1 ?<p className="text-center bottomheadtext"> Strategies</p>
                         : 
                         <img
                         src="/assets/images/icon/Stratery.png"
                         width={"50px"}
                         className="m-auto"
                         onClick={() => handleClick(1)}
                       ></img>
                    }
                  </li>
                </div>
                <div className="col-md-4 col-4 text-center m-auto">
                  <li  className="headerfont">
                    {
                      displayName ==2  ?<p className="text-center bottomheadtext">Dashboard </p>
                      : <img
                      src="/assets/images/icon/dashboardicon.png"
                      width={"50px"}
                      className="m-auto"
                      onClick={() => handleClick(2)}
                    ></img> 
                    }
                  </li>
                </div>
              </div>
            </div>
          </header>
        )}
        </div>
     
    </>
  );
}

export default Bottomheader;
