import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  
    return (
        <footer className="footer-section">
        <div className="newslater-section ">
          <div className="container">
            {/* <div className="newslater-area">
              <div className="newslater-content padding-bottom padding-top">
                <span className="cate">SUBSCRIBE TO WYscale</span>
                <h3 className="title">To Get Exclusive Benefits</h3>
                <form className="newslater-form">
                  <input type="text" placeholder="Enter Your Email Here" required />
                  <button type="submit">subscribe</button>
                </form>
              </div>
              <div className="newslater-thumb">
                <img src="./assets/images/footer/footer.png" alt="footer" />
                <div className="coin-1">
                  <img src="./assets/images/footer/Coin_01.png" alt="footer" />
                </div>
                <div className="coin-2">
                  <img src="./assets/images/footer/Coin_02.png" alt="footer" />
                </div>
                <div className="coin-3">
                  <img src="./assets/images/footer/Coin_03.png" alt="footer" />
                </div>
                <div className="coin-4">
                  <img src="./assets/images/footer/Coin_04.png" alt="footer" />
                </div>
                <div className="coin-5">
                  <img src="./assets/images/footer/Coin_05.png" alt="footer" />
                </div>
                <div className="coin-6">
                  <img src="./assets/images/footer/Coin_06.png" alt="footer" />
                </div>
              </div>
            </div> */}
            <ul>
            <li className="exeptions" >There are substantial risks in investing in a Crypto Portfolio. Persons interested in investing in a Crypto Portfolio should carefully note the following:</li>
            </ul>
            <ul>
              
              <li className="exeptions1">
               WYscale is not a broker-dealer, exchange, custodian, or wallet provider, and is not intended for frequent trading activity. Investing in digital assets is highly speculative and volatile and WYscale is only suitable for investors who are willing to bear the risk of loss and experience sharp drawdowns.

              </li>
              <li className="exeptions1">
              WYscale do not offer tax, financial, accounting or legal advice. Carefully consider the risk factors, investment objectives, fees, expenses, and other information associated with before making an investment decision. All investments are speculative, may be illiquid and involve a high degree of risk, uncertainty, and there is risk of the complete loss of the investment. There is no guarantee that any specific outcome will be achieved. Since each individual's situation is unique, a qualified professional should always be consulted before making any financial decisions.

              </li>
              <li className="exeptions1">
              The information provided within this website within it is not intended as an offer to sell, or the solicitation of an offer to buy/invest in any digital assets & funds. Such offerings may only be made by prospectus and offering documents.
              </li>
              <li className="exeptions1">
               WYscale would like to remind you that the data contained in this website is not necessarily real-time nor accurate. All cryptocurrencies, baskets, ETF, and fund prices are not provided by us but rather by market makers, and so prices may not be accurate and may differ from the actual market price, meaning prices are indicative and not appropriate for trading purposes. Images, charts & stats on this page include illustrations of user experiences intended to demonstrate the format of WYscale's interface. These images do not reflect actual or performance of our recommendations. Past performance is not a guarantee of future results.

              </li>
              <li className="exeptions1">
             For more complete disclosures and information about how WYscale works, please visit our Terms of Service and Help Center.

              </li>
            </ul>
            <hr  ></hr>
          </div>
        </div>
        <div className="container">
          <div className="footer-top">
            <div className="logo">
              <a href="index.html">
                <img src="./assets/images/logo/logo2.png" alt="logo" />
              </a>
            </div>
            {/* <p><b className="text-white" >Contact Us : </b>
            <a href="tel:18001204099 " className="text-white" > 18001204099 </a>
            </p> */}
            
            <ul className="links">
              <li>
                <Link to="/About" >About</Link>
              </li>
              <li>
                <Link to="/FAQ" >FAQ</Link>
              </li>
              <li>
                <Link to="/TermsCondition" href="#0">Terms & Conditions</Link>
              </li>
             
              <li>
                <Link to="/PrivacyPolicy">Privacy Policy</Link>
              </li>
            </ul>
          </div>
          <div className="footer-bottom">
            <div className="footer-bottom-area">
              <div className=" d-block">
                <p>© 2023   <a href="#0">WYscale</a> | All right reserved</p>
                <span className="version-09" >version - 0.9</span>
              </div>
              <ul className="social-icons">
               
                <li>
                  <a href="https://twitter.com/wy_scale" target={"_blank"} className="active">
                    <i className="fab fa-twitter" />
                  </a>
                </li>
                <li>
                  <a href="https://t.me/wyscale" target={"_blank"}>
                  <i className="fa-brands fa-telegram"></i>
                  </a>
                </li>
                <li className="mobilehide" >
               
                  <a href=" https://testflight.apple.com/join/D88p82G2" target="_blank" style={{width:"95px"}} >
                  <img src="/assets/images/icon/ios-store.png" width={"100px"} ></img>
                  </a>
                </li>
                <li className="mobilehide" >
                <a href="https://play.google.com/store/apps/details?id=com.wyscale.twa" target="_blank" style={{width:"95px"}}><img src="/assets/images/icon/play-store.png" width={"100px"} ></img></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    )
}

export default Footer