import { ethers } from 'ethers';
import network from '../utils/chainlist.config';
import { toast } from 'react-toastify';


let signer = null
let address = ""
let contractWithSigner = null
let provider = null
let tokenContract = null



export const ContractService = {
  async connectWallet(typeForTokenIndex,chainId) {
    const abi = require('../abi/abi.json')
    const tokenAbi = require('../abi/token.abi.json')
    try {
      provider = new ethers.providers.Web3Provider(window.ethereum);
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      signer = provider.getSigner();
      address = await signer.getAddress()
      const network1 = await provider.getNetwork()
   
 
      if (network1.chainId !== chainId) {
        await this.addNetwork(  chainId)
        await this.switchNetwork(typeForTokenIndex,chainId)
        const contractObject = new ethers.Contract(network[chainId].contractAddress, abi, provider);
        const tokenContractObj = new ethers.Contract(network[chainId].tokenAddress[typeForTokenIndex], tokenAbi, provider);
        tokenContract = tokenContractObj.connect(signer);
        contractWithSigner = contractObject.connect(signer);
      }else{
        const contractObject = new ethers.Contract(network[chainId].contractAddress, abi, provider);
        const tokenContractObj = new ethers.Contract(network[chainId].tokenAddress[typeForTokenIndex], tokenAbi, provider);
        tokenContract = tokenContractObj.connect(signer);
        contractWithSigner = contractObject.connect(signer);
      }
    } catch (error) {
      console.error(error);
    }
    return address
  },

  async switchNetwork(typeForTokenIndex,chainId) {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: `0x${chainId.toString(16)}` }], // chainId must be in hexadecimal numbers
    })
    await this.connectWallet(typeForTokenIndex,chainId)
  },

  async addNetwork(chainId) {
    await window.ethereum.request(network[chainId].config);
  },


  async deposit(typeForTokenIndex,chainId, amount, userIDs, planID) {
    try {
      const address = await this.connectWallet(typeForTokenIndex,chainId);
      if (address.toLowerCase() !== localStorage.getItem('userAddress').toLowerCase()) return toast.error(`please connect with this wallet address ${localStorage.getItem('userAddress')} `)
      const result = await contractWithSigner.deposit(network[chainId].tokenAddress[typeForTokenIndex], amount, userIDs, planID);
      return result;
    } catch (error) {
      throw error
    }
  },

  async getEthersAmount(amount) {
    const result = await tokenContract.decimals()
    const bigNum = parseFloat(amount) * (10 ** result)
    return  bigNum.toLocaleString('en-US', { useGrouping: false });
  },

  async _getApproval(typeForTokenIndex,chainId, address, amount) {
    try {
      const addressConnected = await this.connectWallet(typeForTokenIndex,chainId);
      if (addressConnected.toLowerCase() !== address.toLowerCase()) return toast.error(`please connect with this wallet address ${localStorage.getItem('userAddress')} `)
      const value = await this.getEthersAmount(amount)
      const checkAllowance = await tokenContract.allowance(address, network[chainId].contractAddress)
      if (parseFloat(checkAllowance, 16) <= parseFloat(value, 16)) {
        const result = await tokenContract.approve(network[chainId].contractAddress, value)
        return result
      }
      return false;
    } catch (error) {
      throw error
    }
  }
};
