import React, { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function CardLoader() {


  return (
    <div className="container d-flex">
      <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 ">
        <div className="card fundcard">
          <Skeleton count={7} style={{"marginBottom":"10px"}}  height="15px"/>
        </div>
      </div>

      <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4  ">
        <div className="card fundcard">
          <Skeleton count={7} style={{"marginBottom":"10px"}}  height="15px"/>
        </div>
      </div>

      <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4  ">
        <div className="card fundcard">
          <Skeleton count={7} style={{"marginBottom":"10px"}}  height="15px"/>
        </div>
      </div>
    </div>
  );
}

export default CardLoader;
