import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header2 from "./Header2";
import Modals from "./Modals";
import { useLocation } from "react-router-dom";

export default function Innerstrategy() {
  const [openModal, setModal] = useState(false);
  const location = useLocation();
  const [Data, setData] = useState({});
  const [ReadMore, setReadMore] = useState(false);

  useEffect(() => {
    setData(location.state.data);
  }, []);

  return (
    <>
      {openModal === true ? (
        <Modals open={true} setModal={setModal} id="3" data={Data} />
      ) : null}

      {Object.keys(Data).length != 0 ? (
        <div>
          <Header2></Header2>
          {/* header */}

          {/*=======Banner-Section Starts Here=======*/}
          <section className="hero-section-3">
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div className="col-12 col-sm-12 col-md-6 col-xl-8">
                  <div className="call-item text-center text-sm-left justify-content-center">
                    <div className="call-content text-center">
                      <h1>{Data.name}</h1>
                      {/* <p className="title text-center">
                        
                        {Data.description}
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="hero-shape">
    <img
    className="wow slideInUp"
    src="./assets/images/about/hero-shape1.png"
    alt="about"
    data-wow-duration="1s"
    />
    </div> */}
          </section>
          {/*=======Banner-Section Ends Here=======*/}
          <section className="get-section padding-top ">
            <div className="container">
              <div className="row justify-content-center ">
                <div className="col-12 col-sm-12  col-md-12 col-lg-10">
                  <div className="card innerstrategycard">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-6 my-2 mx-auto">
                        <div className="grow bg-white rounded-xl border-gray-2100  text-center">
                          <div className="card card1">
                            <div className="row ">
                              <div className="col-6 col-sm-6 col-md-6 m-auto">
                                <p className="innercard-para text-truncate">
                                  Base APY
                                </p>
                              </div>
                              <div className="col-6 col-sm-6 col-md-6">
                                <div className="flex items-center gap-1 font-semibold text-green-1200  p-2">
                                  <span className="fundcard-span">
                                    {Data?.base_apy}%
                                  </span>
                                  <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    strokeWidth="0"
                                    viewBox="0 0 1024 1024"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M868 545.5L536.1 163a31.96 31.96 0 0 0-48.3 0L156 545.5a7.97 7.97 0 0 0 6 13.2h81c4.6 0 9-2 12.1-5.5L474 300.9V864c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V300.9l218.9 252.3c3 3.5 7.4 5.5 12.1 5.5h81c6.8 0 10.5-8 6-13.2z"></path>
                                  </svg>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 my-2 mx-auto">
                        <div className="grow bg-white rounded-xl border-gray-2100  text-center">
                          <div className="card card1">
                            <div className="row">
                              <div className="col-6 col-sm-6 col-md-6 m-auto">
                                <p className="innercard-para text-truncate">
                                  Max APY
                                </p>
                              </div>
                              <div className="col-6 col-sm-6 col-md-6">
                                <div className="flex items-center gap-1 font-semibold text-green-1200  p-2">
                                  <span className="fundcard-span">
                                    {Data.max_apy}%
                                  </span>
                                  <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    strokeWidth="0"
                                    viewBox="0 0 1024 1024"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M868 545.5L536.1 163a31.96 31.96 0 0 0-48.3 0L156 545.5a7.97 7.97 0 0 0 6 13.2h81c4.6 0 9-2 12.1-5.5L474 300.9V864c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V300.9l218.9 252.3c3 3.5 7.4 5.5 12.1 5.5h81c6.8 0 10.5-8 6-13.2z"></path>
                                  </svg>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mobileviewbreak ">
                      <div className="col-12 col-sm-12 col-md-6 my-3 mx-auto">
                        <div className="grow bg-white rounded-xl border-gray-2100  text-center">
                          <div className="row">
                            <div className="col-12 col-sm-12  col-md-3 m-auto">
                              <p className="innercard-para text-truncate text-left">
                                Risk Involved
                              </p>
                              <div className="flex items-left gap-1 p-0 font-semibold text-green-1200 ">
                                <span className="fundcard-span p-0">
                                  Very Low
                                </span>
                              </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-9">
                              <img
                                src="./assets/images/investor/risk1.png"
                                className="w-100"
                              ></img>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 m-auto">
                        <div className="grow bg-white rounded-xl border-gray-2100  text-center">
                          <div className="row">
                            <div className="col-4 col-sm-4  col-md-4 m-auto">
                              <p className="innercard-para text-truncate">
                                Current AUM
                              </p>
                              <p>
                                {" "}
                                ${Data?.current_aum
                                  ? Data?.current_aum.toFixed(2)
                                  : "0"}{" "}
                              </p>
                            </div>
                            {/* <div className="col-4 col-sm-4  col-md-4">
                              <p className="innercard-para text-truncate">
                                Max AUM
                              </p>
                              <p>$200,000</p>
                            </div> */}
                            <div className="col-4 col-sm-4  col-md-4">
                              <p className="innercard-para text-truncate">
                                Investors
                              </p>
                              <p>{Data?.invester ? Data?.invester : "0"}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer bg-white mobileviewbreak">
                      <div className="row">
                        <div className="col-4 col-sm-4  col-md-3 gridrows">
                          <p className="innercard-para text-truncate">
                            Minimum Investment
                          </p>
                          <p>${Data.amount}</p>
                        </div>
                        <div className="col-4 col-sm-4  col-md-3 gridrows">
                          <p className="innercard-para text-truncate">
                            Percentage
                          </p>
                          <p>{Data.percentage}%</p>
                        </div>
                        <div className="col-4 col-sm-4  col-md-3  gridrows">
                          <p className="innercard-para text-truncate">
                            Recommended Holding Period
                          </p>
                          <p className="text-truncate">
                            {Data.days_month}{" "}
                            {Data.is_month_day == 1 ? "Months" : "Days"}
                          </p>
                        </div>
                        <div className="col-12 col-sm-12  col-md-3 gridrows">
                          <div className="w-full md:w-auto flex justify-between md:justify-start items-start gap-4">
                            <div className="w-3/4 md:w-[240px] mx-auto">
                              <button
                                disabled
                                type="button"
                                className="custom-button disabled-btn"
                                onClick={() => setModal(true)}
                              >
                                Invest Now
                              </button>
                              <div className="flex justify-center pt-1 justify-content-center">
                                <a
                                  href="tel:18001204099 "
                                  rel="noreferrer"
                                  className="text-purple-1000 font-bold text-sm underline text-truncate"
                                >
                                  Talk to Investment Advisor
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="text-purple-1000 mt-3 text-center ">
                    {`Earn up to ${Data.percentage}% per ${Data.is_month_day === 1 ? "Months" : "Days"
                      } for every $${Data.amount} invested`}
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="container">
              <div className="row padding-top m-0">
                <div className="col-12 col-sm-12 col-md-6">
                  <div className="row text-center mb-5 ">
                    <div className="col-4 col-sm-4  col-md-4 m-auto">
                      <p className="innercard-para text-truncate">
                        Current value of $100 invested
                      </p>
                      <p className="innercard-para3 text-truncate">
                        once at launch would be
                      </p>
                    </div>
                    <div className="col-4 col-sm-4  col-md-4">
                      <p
                        className="innercard-para text-truncate"
                        style={{ color: "#5f18f8" }}
                      >
                        Lowest Risk, Good Returns
                      </p>
                      <p className="innercard-para3 text-truncate">$122.61</p>
                    </div>
                    <div className="col-4 col-sm-4  col-md-4">
                      <p
                        className="innercard-para text-truncate"
                        style={{ color: "#f7931a" }}
                      >
                        Bitcoin
                      </p>
                      <p className="innercard-para3 text-truncate">$104.82</p>
                    </div>
                  </div>
                  <div className="tab transaction-tab d-flex flex-wrap justify-content-center ">
                    <div className="tab-area">
                      <div className="container">
                        <div className="tab-item active">
                          <div className="row justify-content-center mb-30-none">
                            <div className=" col-lg-12">
                              <img
                                src="https://www150.statcan.gc.ca/edu/power-pouvoir/c-g/c-g05-5-1-eng.png"
                                className="w-100"
                              ></img>
                            </div>
                          </div>
                        </div>
                        <div className="tab-item">
                          <div className="row justify-content-center mb-30-none">
                            <div className="col-lg-12">
                              <img
                                src="https://www150.statcan.gc.ca/edu/power-pouvoir/c-g/c-g05-5-1-eng.png"
                                className="w-100"
                              ></img>
                            </div>
                          </div>
                        </div>
                        <div className="tab-item">
                          <div className="row justify-content-center mb-30-none">
                            <div className="col-lg-12">
                              <img
                                src="https://www150.statcan.gc.ca/edu/power-pouvoir/c-g/c-g05-5-1-eng.png"
                                className="w-100"
                              ></img>
                            </div>
                          </div>
                        </div>
                        <div className="tab-item">
                          <div className="row justify-content-center mb-30-none">
                            <div className="col-lg-12">
                              <img
                                src="https://www150.statcan.gc.ca/edu/power-pouvoir/c-g/c-g05-5-1-eng.png"
                                className="w-100"
                              ></img>
                            </div>
                          </div>
                        </div>
                        <div className="tab-item">
                          <div className="row justify-content-center mb-30-none">
                            <div className="col-lg-12">
                              <img
                                src="https://www150.statcan.gc.ca/edu/power-pouvoir/c-g/c-g05-5-1-eng.png"
                                className="w-100"
                              ></img>
                            </div>
                          </div>
                        </div>
                        <div className="tab-item">
                          <div className="row justify-content-center mb-30-none">
                            <div className="col-lg-12">
                              <img
                                src="https://www150.statcan.gc.ca/edu/power-pouvoir/c-g/c-g05-5-1-eng.png"
                                className="w-100"
                              ></img>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ul className="tab-menu mt-5">
                      <li className="active">
                        <div className="content p-0">
                          <span className="d-block">1W</span>
                        </div>
                      </li>
                      <li>
                        <div className="content p-0">
                          <span className="d-block">1M</span>
                        </div>
                      </li>
                      <li>
                        <div className="content p-0">
                          <span className="d-block">1Y</span>
                        </div>
                      </li>
                    </ul>
                    <div className="col-12 col-sm-12 col-md-6 my-3 mx-auto">
                      <div className="grow bg-white rounded-xl border-gray-2100  text-center verylowmobile">
                        <div className="row">
                          <div className="col-12 col-sm-12  col-md-3 m-auto">
                            <p className="innercard-para text-truncate text-left">
                              Risk Involved
                            </p>
                            <div className="flex items-left gap-1 p-0 font-semibold text-green-1200 ">
                              <span className="fundcard-span p-0">
                                Very Low
                              </span>
                            </div>
                          </div>
                          <div className="col-12 col-sm-12 col-md-9">
                            <img
                              src="./assets/images/investor/risk1.png"
                              className="w-100"
                            ></img>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h5 className="mb-3">Strategy Coin Mix</h5>
                  <div className="row mb-5">
                    <div className="col-6 col-sm-6  col-md-6  p-0">
                      <div className="grow bg-white rounded-xl border-gray-2100 mx-0 text-center">
                        <div className="card card2">
                          <div className="row">
                            <div className="col-6 col-sm-6  col-md-6 m-auto d-flex">
                              <img
                                src="https://tykhe-static.s3.ap-south-1.amazonaws.com/Placeholder+Token.png"
                                className=" width-40 "
                              ></img>
                              <p className="innercard-para1 text-truncate">
                                {" "}
                                Atom
                              </p>
                            </div>
                            <div className="col-6 col-sm-6  col-md-6">
                              <div className="flex items-center text-success gap-1 font-semibold text-green-1200 justify-content-center">
                                <span className="fundcard-span  text-truncate">
                                  9.25%
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-sm-6  col-md-6  p-0">
                      <div className="grow bg-white rounded-xl border-gray-2100 mx-0 text-center">
                        <div className="card card2">
                          <div className="row">
                            <div className="col-6 col-sm-6  col-md-6 m-auto d-flex">
                              <img
                                src="https://www.gemini.com/images/currencies/icons/default/ETH.png"
                                className=" width-40 "
                              ></img>
                              <p className="innercard-para1 text-truncate">
                                ETH
                              </p>
                            </div>
                            <div className="col-6 col-sm-6  col-md-6">
                              <div className="flex items-center text-success gap-1 font-semibold text-green-1200 justify-content-center">
                                <span className="fundcard-span   text-truncate">
                                  9.25%
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-sm-6  col-md-6 p-0">
                      <div className="grow bg-white rounded-xl border-gray-2100 mx-0 text-center">
                        <div className="card card2">
                          <div className="row">
                            <div className="col-6 col-sm-6  col-md-6 m-auto d-flex">
                              <img
                                src="https://tykhe-static.s3.ap-south-1.amazonaws.com/Placeholder+Token.png"
                                className=" width-40 "
                              ></img>
                              <p className="innercard-para1 text-truncate">
                                ART
                              </p>
                            </div>
                            <div className="col-6 col-sm-6  col-md-6  p-0">
                              <div className="flex items-center gap-1 font-semibold text-green-1200 justify-content-center">
                                <span className="fundcard-span text-truncate">
                                  9.25%
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-sm-6  col-md-6  p-0">
                      <div className="grow bg-white rounded-xl border-gray-2100 mx-0  text-center">
                        <div className="card card2">
                          <div className="row">
                            <div className="col-6 col-sm-6  col-md-6 m-auto d-flex">
                              <img
                                src="https://tykhe-static.s3.ap-south-1.amazonaws.com/More+Tokens.png"
                                className=" width-40 "
                              ></img>
                              <p className="innercard-para1 text-truncate">
                                Others
                              </p>
                            </div>
                            <div className="col-6 col-sm-6  col-md-6">
                              <div className="flex items-center gap-1 font-semibold text-green-1200 justify-content-center">
                                <span className="fundcard-span text-truncate">
                                  9.25%
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div id="Description-section ">
                    <h6 className="mb-3">Description</h6>
                    <p className="description-para text-truncate">
                      {Data.description.split(" ").length > 20
                        ? Data.description.split(" ").slice(0, 20).join()
                        : Data.description}
                    </p>
                    <p className="moretext description-para">
                      {ReadMore == true &&
                        Data.description.split(" ").length > 20
                        ? Data.description
                          .split(" ")
                          .slice(20, Data.description.split(" ").length)
                          .join()
                        : ""}
                    </p>
                    <a
                      className="moreless-button"
                      onClick={() => setReadMore(true)}
                    >
                      {ReadMore == false &&
                        Data.description.split(" ").length > 20
                        ? "Read More"
                        : ""}
                    </a>
                  </div>
                  <hr></hr>
                </div>
              </div>
            </div>
          </section>
          <section className="Fixed-bottom py-3">
            <div className="container">
              <div className="row">
                <div className="col-md-4 col-4">
                  <div className="gridrows">
                    <p className="innercard-para text-truncate">
                      Minimum Investment
                    </p>
                    <p>${Data.amount}</p>
                  </div>
                </div>
                <div className="col-md-8 col-8">
                  <div className=" gridrows">
                    <div className="w-full md:w-auto flex justify-between md:justify-start items-start gap-4">
                      <div className="w-3/4 md:w-[240px]">
                        <button
                          type="button"
                          className="custom-button"
                          onClick={() => setModal(true)}
                        >
                          Invest Now
                        </button>
                        <div className="flex justify-center pt-1 justify-content-center">
                          <a
                            href="tel:18001204099 "
                            rel="noreferrer"
                            className="text-purple-1000 font-bold text-sm underline text-truncate"
                          >
                            Talk to Investment Advisor
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : null}
      <Footer />
    </>
  );
}
