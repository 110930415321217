import { Dialog, Slide } from '@mui/material'
import React from 'react'
import { useEffect } from 'react';
const onMetaWidget = window.onMetaWidget
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
function WithdrawFromOnMeta({onclose,is_withdraw,data}) {
   
  async function handleWithdrawPaymentGatway() {
    const metaData = JSON.stringify({
        token: localStorage.getItem("token"),
        amount: data.amount,
        user_plan_id: data.user_plan_id,
        user_plan_detail_id:data.user_plan_detail_id,
        order_id: data.order_id
      });
    try {
      // data.amount*87.50,
     const createWidget = new onMetaWidget({
        environment: process.env.REACT_APP_PRODUCTION,
        elementId: "widget",
        apiKey: process.env.REACT_APP_PAYMENT_APP_KEY,
        walletAddress:process.env.REACT_APP_WALLET_ADDRESS_WITHDRAW_ONMETA,
        fiatAmount: data.amount, // Optional (If passed then minimum amount is 100 inr)
        userEmail: localStorage.getItem("email"), // Optional (if passed user don't have to register in meta platform)
        metaData: metaData,
        offRamp: 'enabled',
        onRamp: 'disabled',
        metamask:'disabled',
        tokenAddress:process.env.REACT_APP_USDT_TOKEN_ADDRESS,
        chainId:process.env.REACT_APP_CHAINID_POLYGON,
        sellCryptoType: "coins",
      });
      createWidget.init();
      createWidget.on(
        "ORDER_COMPLETED_EVENTS",
        (data) => (
          console.log(data)
        )
      );
    } catch (e) {
      console.log(e);
    }
  }
    useEffect(()=>{
        if(is_withdraw){
            setTimeout(() => {
                handleWithdrawPaymentGatway()
            }, 500);
        }
    },[is_withdraw])
    return (
        <>
            <Dialog
                open={true}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <span className="text-right m-3" onClick={onclose} ><i className="fa-regular fa-circle-xmark"></i></span>
                <div className="col-md-6 px-3">
                    <div id="widget"></div>
                </div>
            </Dialog>
        </>
    )
}

export default WithdrawFromOnMeta
