import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import 'react-loading-skeleton/dist/skeleton.css'
import Routers from './routers/Routers';
import $ from 'jquery'

function App() {
useEffect(()=>{

(function () {
  "user strict";
  // Preloader Js
  $(document).ready(function () {
    // Nice Select
    // PoPuP 
    

    // aos js active
    // new WOW().init()
    //Faq

    //Menu Dropdown Icon Adding
    $("ul>li>.submenu").parent("li").addClass("menu-item-has-children");
    // drop down menu width overflow problem fix
    $('ul').parent('li').hover(function () {
      var menu = $(this).find("ul");
      var menupos = $(menu).offset();
      if (menupos.left + menu.width() > $(window).width()) {
        var newpos = -$(menu).width();
        menu.css({
          left: newpos
        });
      }
    });
    $('.menu li a, .dashboard-menu li a').on('click', function (e) {
      var element = $(this).parent('li');
      if (element.hasClass('open')) {
        element.removeClass('open');
        element.find('li').removeClass('open');
        element.find('ul').slideUp(300, "swing");
      } else {
        element.addClass('open');
        element.children('ul').slideDown(300, "swing");
        element.siblings('li').children('ul').slideUp(300, "swing");
        element.siblings('li').removeClass('open');
        element.siblings('li').find('li').removeClass('open');
        element.siblings('li').find('ul').slideUp(300, "swing");
      }
    })
    // Scroll To Top 
    var scrollTop = $(".scrollToTop");
    $(window).on('scroll', function () {
      if ($(this).scrollTop() < 500) {
        scrollTop.removeClass("active");
      } else {
        scrollTop.addClass("active");
      }
    });
    //Click event to scroll to top
    $('.scrollToTop').on('click', function () {
      $('html, body').animate({
        scrollTop: 0
      }, 500);
      return false;
    });
    $('.dashboard-right-menus>li').on('click', function(){
      $('.dashboard-right-menus>li').children('.notification-area').removeClass('active');
      $('.notify-overlay').addClass('active');
      $(this).children('.notification-area').addClass('active');
    })
    $('.notify-overlay').on('click', function(){
      $('.dashboard-right-menus>li').children('.notification-area').removeClass('active');
      $(this).removeClass('active');
    })
    //Header Bar
    $('.header-bar').on('click', function () {
      $(this).toggleClass('active');
      $('.overlay').toggleClass('active');
      $('.menu').toggleClass('active');
    })
    $('.overlay').on('click', function () {
      $(this).removeClass('active');
      $('.header-bar').removeClass('active');
      $('.menu').removeClass('active');
      $('.cross-header-bar').removeClass('active');
      $('.side-header').removeClass('active');
      $('.mobile-header-content').removeClass('active');
      $('.dashboard-right-menus>li').children('.notification-area').removeClass('active');
    })
    //Header Bar
    $('.cross-header-bar').on('click', function () {
      $('.cross-header-bar').toggleClass('active');
      $('.side-header').toggleClass('active');
      $('.overlay').toggleClass('active');
    })
    $('.mobile-header .author').on('click', function(){
      $('.mobile-header-content').addClass('active');
      $('.overlay').addClass('active');
    })
    // Header Sticky Herevar prevScrollpos = window.pageYOffset;
    var scrollPosition = window.scrollY;
    if (scrollPosition >= 1) {
      $(".header-bottom").addClass('active');
      $(".header-section-2").removeClass('plan-header');
    }
    var header = $(".header-bottom");
    var headerT = $(".header-section-2");
    $(window).on('scroll', function () {
      if ($(this).scrollTop() < 1) {
        headerT.addClass("plan-header");
        header.removeClass("active");
      } else {
        headerT.removeClass("plan-header");
        header.addClass("active");
      }
    });
 
  });

})();

})
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        closeButton={ <button style={{ width: "30px", backgroundColor : "inherit", border : "none", color : "white"}}>X</button> }
      />
       <Routers/>
    </>
  );
}

export default App;
