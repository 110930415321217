import React, { useEffect} from "react";
import { Link, NavLink } from "react-router-dom";
import loadjs from 'loadjs';
import { useNavigate } from "react-router-dom";

export default function Sidebar() {
  const navigate = useNavigate();

  const logout = () => {
    localStorage.clear();
  }

  
  return (
    <div>
      <div className="notify-overlay" />
      <section className="dashboard-section">
        <div className="side-header oh">
          <div className="cross-header-bar">
            <span />
            <span />
            <span />
          </div>
          <div className="site-header-container">
            <div className="side-logo">
              <NavLink activeClassName="active" to='/'>
                <img src="./assets/images/logo/logo.png" alt="logo" />
              </NavLink>
            </div>
            <ul className="dashboard-menu">
              <li>
                <NavLink activeClassName="active" to="/Dashboard">
                  <i className="flaticon-man" />
                  Dashboard
                </NavLink>
              </li>
             
              <li>
                <NavLink activeClassName="active" to="/Deposit">
                  <i className="flaticon-interest" />
                  Deposits
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName="active" to="/Withdraw">
                  <i className="flaticon-atm" />
                  Withdraw
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName="active" to="/Operations" >
                  <i className="flaticon-coin" />
                  Operations
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName="active" to="/Earlymaturity" >
                  <i className="flaticon-coin" />
                Early Maturity
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName="active" to="/Referral">
                  <i className="flaticon-deal" />
                  Referral
                </NavLink>
              </li>
              {/* <li>
                <NavLink activeClassName="active" to="/Setting">
                  <i className="flaticon-gears" />
                  Settings
                </NavLink>
              </li> */}
              <li>
                <NavLink activeClassName="active" to="/Notifications">
                  <i className="flaticon-bell" />
                  Notifications
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName="active" to="/Support">
                  <i className="flaticon-sms" />
                  Support
                </NavLink>
              </li>
              
              <li>
                <NavLink activeClassName="active" to='/' onClick={() => logout()}>
                  <i className="flaticon-right-arrow" />
                  Logout
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
       
      </section>
    </div>
  );

  }