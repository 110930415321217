import { Dialog, DialogContent, DialogContentText, Slide } from '@mui/material'
import React from 'react'
import apiService from '../services/ApiService';
import { useState } from 'react';
import { toast } from 'react-toastify';
import OtpInput from 'react-otp-input';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function ForgetPassword({ open, _forgetPasswordOnClose }) {
    const [getEmail, setEmail] = useState('')
    const [error, setError] = useState('');
    const [isSubmit, setIsSubmit] = useState(false);
    const [isOtpSend, setOtpSend] = useState(false)
    const [getOtp, setOtp] = useState()
    const [otpError, setOtpError] = useState({})
    const [passwordChangeForm, setPasswordChangeForm] = useState({
        password: '',
        confPassword: '',
    })

    const [errors, setErrors] = useState({});

    function handleOtpField(value) {
        setOtp(value)
        if (!value) {
            setOtpError({ otp: 'OTP is required' })
        } else {
            setOtpError({ otp: '' })

        }
        if (value.length !== 4) {
            setOtpError({ otp: 'OTP at least 4 digit.' })

        } else {
            setOtpError({ otp: '' })
        }
    }



    function validateForm(formData) {
        const { password, confPassword, otp } = formData;
        const errors = {};
        if (!password?.trim()) {
            errors.password = 'Password is required';
        }
        if (!confPassword?.trim()) {
            errors.confPassword = 'Confirm password is required';
        } else if (password !== confPassword) {
            errors.confPassword = 'Passwords must match';
        }

        return errors;
    }

    function handleFormData(e) {
        const { name, value } = e.target;
        setPasswordChangeForm((prevFormData) => ({ ...prevFormData, [name]: value }));
        setErrors(validateForm({ ...passwordChangeForm, [name]: value }));
    }

    const validateEmail = (value) => {
        const rex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if (!value) {
            return 'email is required';
        }
        if (!rex.test(value)) {
            return 'Please enter a valid email address.';
        }

        return '';
    }

    const handleInputChange = (event) => {
        const { value } = event.target;
        const validationError = validateEmail(value);
        setEmail(value);
        setError(validationError);
    }

    async function _forgetPassowrdOTP() {
        setIsSubmit(true)
        const validationError = validateEmail(getEmail);
        if (!validationError) {
            // submit the form
            try {
                const result = await apiService().post('forgetPassowrdOTP', { email: getEmail })
                toast.success(result.data.message)
                setIsSubmit(false)
                setOtpSend(true)
            } catch (error) {
                console.log(error);
                setIsSubmit(false)
            }
        } else {
            setError(validationError);
            setIsSubmit(false)
        }

    }

    async function verifyOtpAndSetPassword() {
        setIsSubmit(true)
        const validationErrors = validateForm(passwordChangeForm);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        try {
            if (getOtp && otpError.otp.length == 0) {
                const result = await apiService().post('verifyForgetPassowrdOTP', { email: getEmail, ...passwordChangeForm, otp: getOtp })
                toast.success(result.data.message)
                setIsSubmit(false)
                _forgetPasswordOnClose()  
            }
        } catch (error) {
            setIsSubmit(false)
        }
    }

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={_forgetPasswordOnClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <div className="mx-3 mt-3">
                            {isOtpSend ?
                                <div className="card border-0 p-4">
                                    <h6>Change Password</h6>
                                    <form className="account-form mt-4">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                placeholder="Enter Password "
                                                name='password'
                                                autoComplete='off'
                                                value={passwordChangeForm.password}
                                                onChange={handleFormData}
                                            />
                                            {errors.password && <span className="text-danger">{errors.password}</span>}
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                placeholder="Confirm Password"
                                                name='confPassword'
                                                value={passwordChangeForm.confPassword}
                                                onChange={handleFormData}
                                            />
                                            {errors.confPassword && <span className="text-danger">{errors.confPassword}</span>}
                                        </div>
                                        <div className="form-group">
                                            <label>Otp</label>
                                            <OtpInput
                                                className="optinputs"
                                                value={getOtp}
                                                onChange={handleOtpField}
                                                numInputs={4}
                                                separator={<span>-</span>}
                                                name="otp"
                                            />
                                            {otpError.otp && <span className="text-danger">{otpError.otp}</span>}
                                        </div>
                                    </form>
                                    <button
                                        type="submit"
                                        className={`action-btn ${isSubmit ? 'loading' : ""}`}
                                        onClick={verifyOtpAndSetPassword}
                                        disabled={isSubmit}
                                    >
                                        Submit
                                    </button>
                                </div>
                                :
                                <div className="card border-0 p-4">
                                    <h6>Enter recovery Email Address</h6>
                                    <form className="account-form mt-4">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                placeholder="Enter Your Email "
                                                id="email"
                                                name="email"
                                                autoComplete='off'
                                                onChange={handleInputChange}
                                            />
                                            {error && <div>{error}</div>}
                                        </div>
                                    </form>
                                    <button
                                        type="submit"
                                        className={`action-btn ${isSubmit ? 'loading' : ""}`}
                                        onClick={_forgetPassowrdOTP}
                                        disabled={isSubmit}
                                    >
                                        Submit
                                    </button>
                                </div>
                            }


                        </div>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default ForgetPassword