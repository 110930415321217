
let signer = null
let address = ""
let contractWithSigner = null
let provider = null
let tokenContract = null
let withdrawalContract = null
const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS
const withdrawalContractAddress = process.env.REACT_APP_WITHDRAWAL_CONTRACT_ADDRESS
const contractTokenAddress = process.env.REACT_APP_TOKEN_ADDRESS
let tronWeb;
export const TronWebContractService = {

    async getTronWeb() {
        try {
            const abi = require('../abi/abi.json')
            const abiToken = require('../abi/token.abi.json')
            const withdrawalContractAbi = require('../abi/withdrawal.abi.json')
            if (window.tronLink.ready) {
                tronWeb = window.tronLink.tronWeb;
            } else {
                const res = await window.tronLink.request({ method: 'tron_requestAccounts' });
                if (res.code === 200) {
                    tronWeb = window.tronLink.tronWeb;
                }
            }

            contractWithSigner = await tronWeb.contract(abi, contractAddress)
            tokenContract = await tronWeb.contract(abiToken, contractTokenAddress)
            withdrawalContract = await tronWeb.contract(withdrawalContractAbi, withdrawalContractAddress)
            return tronWeb.defaultAddress.base58
        } catch (error) {
            console.log(error)
            throw error
        }
    },

    async getEthersAmount(amount) {
        const result = await tokenContract.methods.decimals().call()
        const bigNum = parseFloat(amount) * (10 ** result)
        return bigNum.toLocaleString('en-US', { useGrouping: false });
    },
    async getApproval(amount, address) {
        try {
            const checkAllowance = await tokenContract.methods.allowance(address, contractAddress).call()
            if (parseFloat(checkAllowance._hex, 16) <= parseFloat(amount, 16)) {
                const result = await tokenContract.methods.approve(contractAddress, amount).send({ callValue: amount })
                return true
            }
            return false
        } catch (error) {
            console.log(error)
            throw error
        }
    },

    async deposite(amount, userId, plan_id) {
        try {
            const result = await contractWithSigner.methods.deposit(contractTokenAddress, amount, userId, plan_id).send({ callValue: amount })
            // const confirm = await this.waitForConfirmation(result)
            console.log(result);
            // return { hash: result, confirm: confirm }
        } catch (error) {
            console.log(error)
            throw error
        }
    },
    async _userWithdraw(tokenAddress,item) {
        try {
            const value = await this.getEthersAmount(item.amount)
            const result = await withdrawalContract.methods._userWithdraw(tronWeb.address.fromHex(tokenAddress),value, item.userId, item.planId).send({ callValue: value })
            // const confirm = await this.waitForConfirmation(result)
            console.log(result);
            // return { hash: result, confirm: confirm }
        } catch (error) {
            console.log(error)
            throw error
        }
    },

    async waitForConfirmation(transactionId) {
        let transactionConfirmed = false;
        while (!transactionConfirmed) {
            const transaction = await window.tronLink.tronWeb.trx.getTransactionInfo(transactionId);
            if (transaction && transaction.blockNumber) {
                transactionConfirmed = true;
                return true
            }
        }
    }
};

