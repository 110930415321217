import React from "react";

export default function Affiliate() {
  return (
    <div>
      {/*=======Check-Section Starts Here=======*/}
      <section
        className="call-section call-overlay bg_img"
        data-background="./assets/images/call/call-bg.jpg"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 col-xl-6">
              <div className="call-item text-center text-sm-left">
                <div className="call-icon">
                  <img src="./assets/images/call/icon01.png" alt="call" />
                </div>
                <div className="call-content">
                  <h5 className="title">
                    Ready To Start Your Earnings Through Crypto Currency
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-md-5 col-xl-6 text-center text-sm-left text-md-right">
              <a href="#0" className="custom-button">
                learn more <i className="flaticon-right" />
              </a>
            </div>
          </div>
        </div>
      </section>
      {/*=======Check-Section Ends Here=======*/}
      {/* ==========Total-Affiliate-Section Starts Here========== */}
      <section className="total-affiliate-section padding-bottom padding-top">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-header">
                <span className="cate">You’re Part of something Big</span>
                <h2 className="title">$50,257,285.47</h2>
                <p>Total Commissions Paid to Our Affiliates</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 d-lg-block d-none">
              <div className="total-thumb rtl">
                <img
                  src="./assets/images/affiliate/total-1.png"
                  alt="affiliate"
                />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="total-content">
                <div className="total-bg">
                  <img
                    src="./assets/images/affiliate/affiliate-bg2.png"
                    alt="affiliate"
                  />
                </div>
                <div className="tot-wrapper">
                  <div className="tot-area">
                    <div className="tot-item">
                      <div className="tot-thumb">
                        <img
                          src="./assets/images/affiliate/tot1.png"
                          alt="affiliate"
                        />
                      </div>
                      <div className="tot-content">
                        <div className="counter--item">
                          <div className="counter-header">
                            <h2
                              className="title odometer"
                              data-odometer-final={20}
                            >
                              0
                            </h2>
                          </div>
                          <p>Supported Languages</p>
                        </div>
                      </div>
                    </div>
                    <div className="tot-item">
                      <div className="tot-thumb">
                        <img
                          src="./assets/images/affiliate/tot3.png"
                          alt="affiliate"
                        />
                      </div>
                      <div className="tot-content">
                        <div className="counter--item">
                          <div className="counter-header">
                            <h2
                              className="title odometer"
                              data-odometer-final="4.5"
                            >
                              0
                            </h2>
                            <h2 className="title">M</h2>
                          </div>
                          <p>Users Worldwide</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tot-area">
                    <div className="tot-item">
                      <div className="tot-thumb">
                        <img
                          src="./assets/images/affiliate/tot3.png"
                          alt="affiliate"
                        />
                      </div>
                      <div className="tot-content">
                        <div className="counter--item">
                          <div className="counter-header">
                            <h2
                              className="title odometer"
                              data-odometer-final={800}
                            >
                              0
                            </h2>
                            <h2 className="title">k</h2>
                          </div>
                          <p>Popular Investors</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ==========Total-Affiliate-Section Ends Here========== */}
      {/* ==========How-Section Starts Here========== */}
      <section
        className="how-section bg_img padding-top padding-bottom pt-max-md-0"
        data-background="./assets/images/affiliate/affiliate-bg.png"
      >
        <div
          className="ball-3"
          data-paroller-factor="-0.30"
          data-paroller-factor-lg="0.60"
          data-paroller-type="foreground"
          data-paroller-direction="horizontal"
        >
          <img src="./assets/images/balls/ball-group9.png" alt="balls" />
        </div>
        <div
          className="ball-2"
          data-paroller-factor="0.30"
          data-paroller-factor-lg="-0.30"
          data-paroller-type="foreground"
          data-paroller-direction="horizontal"
        >
          <img src="./assets/images/balls/ball-group10.png" alt="balls" />
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-header">
                <span className="cate">Here’s how it works</span>
                <h2 className="title">Getting started? It’s simple</h2>
                <p>
                  The affiliate program is our special feature for loyal
                  Investors.Invite users and earn 40% of the fee on their
                  exchange transactions!
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center mb-30-none">
            <div className="col-md-6 col-lg-4 col-sm-10">
              <div className="how-item">
                <div className="how-thumb-area">
                  <div className="how-thumb">
                    <img src="./assets/images/how/how4.png" alt="how" />
                  </div>
                </div>
                <div className="how-content">
                  <h5 className="title">Join Program</h5>
                  <a href="#0">
                    Join Now <i className="flaticon-right" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-sm-10">
              <div className="how-item active">
                <div className="how-thumb-area">
                  <div className="how-thumb">
                    <img src="./assets/images/how/how5.png" alt="how" />
                  </div>
                </div>
                <div className="how-content">
                  <h5 className="title">Promote</h5>
                  <a href="#0">
                    Tracking Link <i className="flaticon-right" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-sm-10">
              <div className="how-item">
                <div className="how-thumb-area">
                  <div className="how-thumb">
                    <img src="./assets/images/how/how6.png" alt="how" />
                  </div>
                </div>
                <div className="how-content">
                  <h5 className="title">Earn</h5>
                  <a href="#0">
                    Commission Model <i className="flaticon-right" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ==========How-Section Ends Here========== */}
      {/* ==========Why-Affiliate-Section Starts Here========== */}
      <section className="why-affiliate-section padding-bottom padding-top pt-max-lg-0">
        <div className="why--thumb">
          <img src="./assets/images/why/how.png" alt="why" />
        </div>
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-6">
              <div className="why-affiliate-content">
                <div className="section-header left-style">
                  <span className="cate">Why Should You</span>
                  <h2 className="title">Join affiliate?</h2>
                  <p>
                    The affiliate program is our special feature for loyal
                    Investors.
                  </p>
                </div>
                <div className="why-area">
                  <div className="why-item">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src="./assets/images/why/why1.png" alt="why" />
                      </div>
                      <div className="why-content">
                        <h6 className="title">Joining free </h6>
                      </div>
                    </div>
                  </div>
                  <div className="why-item">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src="./assets/images/why/why2.png" alt="why" />
                      </div>
                      <div className="why-content">
                        <h6 className="title">Instant Payout</h6>
                      </div>
                    </div>
                  </div>
                  <div className="why-item">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src="./assets/images/why/why3.png" alt="why" />
                      </div>
                      <div className="why-content">
                        <h6 className="title">Performance Bonues</h6>
                      </div>
                    </div>
                  </div>
                  <div className="why-item">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src="./assets/images/why/why4.png" alt="why" />
                      </div>
                      <div className="why-content">
                        <h6 className="title">Unlimited affiliates</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <a href="#0" className="custom-button">
                  join Now!
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ==========Why-Affiliate-Section Ends Here========== */}
    </div>
  );
}
