import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import loadjs from "loadjs";
import { Link } from "react-router-dom";
import Select from "react-select";
import Modals from "./Modals";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import FormControl from "@mui/material/FormControl";
import { toast } from "react-toastify";
import apiService from "../services/ApiService";
import ProfileModals from "./ProfileModals";
import ConfirmationDialog from "./ConfirmationDialog";
import { ethers } from "ethers";



export default function Profile() {

  const [openModal, setModal] = useState(false);
  const [isOpenBy, setIsOpenBy] = useState();
  const [Data, setData] = useState({});
  const [showDialog, setShowDialog] = useState(false);
  const [user_bank_detail_id, setUser_bank_detail_id] = useState(null)

  const handleConfirm = async () => {
    // perform the action
    setShowDialog(false);
    try {
      const result = await apiService().get(`removeBank?user_bank_detail_id=${user_bank_detail_id}`)
      toast.success(result.data.message)
      User_dashboard()
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = () => {
    // cancel the action
    setShowDialog(false);
  };

  const User_dashboard = async () => {
    try {
      const res = await apiService().get(`userProfile`);
      console.log(res);
      setData(res.data.data);
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };

  useEffect(() => {
    loadjs("./assets/js/main.js", () => { });
  }, []);

  useEffect(() => {
    if (!openModal) {
      User_dashboard();
    }
  }, [openModal])

  ///////add wallet address //////

  const userAddress = localStorage.getItem('userAddress') ?? null
  const [walletAddress, setWalletAddress] = useState('')
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [errors, setErrors] = useState('')

  function handleOnchangeStoreAgent(value) {

    if (ethers.utils.isAddress(value)) {
      setWalletAddress(value)
      setErrors('')
    } else {
      setErrors('invalid wallet address')
    }
  }

  async function storeWalletAddress() {
    try {
      if (errors.length !== 0 && walletAddress.length == 0) return
      setIsSubmit(true)
      const result = await apiService().post('storeWalletAddress', { wallet_address: walletAddress })
      localStorage.setItem('userAddress', walletAddress)
      localStorage.setItem('name',result.data.name)
      toast.success(result.data.message)
      setIsSubmit(false)

    } catch (error) {
      setIsSubmit(false)

    }
  }

  return (
    <>
      {showDialog && (
        <ConfirmationDialog
          message="Are you sure you want to perform this action?"
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />)}
      {openModal === true ? (
        <ProfileModals isOpenBy={isOpenBy} userData={Data} openModal={openModal} setModal={setModal} />
      ) : null}
      <Header></Header>
      <section className="Profile-section">
        <div className="container">
          <h1 className="profilehead">Your Profile</h1>
          <div className="row">
            <div className="col-md-5">
              <div className="card Profilecard">
                <div className="row">
                  <div className="col-md-3 text-center p-0">
                    <img src={Data.user_image ? Data.user_image : "./assets/images/icon/user.png"} width={"50"} />
                  </div>
                  <div className="col-md-9 d-flex p-0 profilemobileview">
                    <h2 className="Ownername ">{Data.name ? Data.name : "--"}</h2>
                    <a className="mx-1 my-1" >
                      <span
                        className="Editprofile my-auto"
                        onClick={() => (setModal(true), setIsOpenBy(1))}
                      >
                        <i className="fa-solid fa-pen"></i>
                      </span>
                    </a>
                  </div>
                  <div className="col-md-4 p-0"></div>
                </div>
                <hr></hr>

                <div className="d-flex">
                  <i className="fa-solid fa-phone m-2"></i>
                  <span className="my-auto"  >{Data.mobile_no ? Data.mobile_no : "--"}</span>
                </div>
                <div className="d-flex">
                  <i className="fa-solid fa-envelope m-2"> </i>
                  <span className="my-auto" >{Data.email ? Data.email : "--"}</span>
                </div>
              </div>

              {
               userAddress && userAddress.length==0 ?
                  <div className="profile_img bg-1 p-4 mt-4  px-2">
                    <div className="d-flex profile-income text-center justify-content-center m-auto">
                      <div className="right-side d-block text-center">
                        <p className="profile-label">Add Wallet Address</p>
                        <input type="text" placeholder="enter wallet aadress" onChange={(e) => handleOnchangeStoreAgent(e.target.value)} />
                        {errors}
                        <button className={`action-btn ${isSubmit ? 'loading' : ''}`} onClick={() => storeWalletAddress()} disabled={isSubmit}>
                          submit
                        </button>
                      </div>
                    </div>
                  </div> : <div className="profile_img bg-1 p-4 mt-4  px-2">
                    <div className="d-flex profile-income text-center justify-content-center m-auto">
                      <div className="right-side d-block text-center">
                        <p className="profile-label"> Wallet Address</p>
                         <span>{userAddress}</span>
                      </div>
                    </div>
                  </div>
              }
            </div>
            <div className="col-md-7">
              <div className="address-book">
                <h6 className="">Address Book</h6>
                {
                  Data.address ? (
                    <div>
                      <div className="card profileeditcard mt-2">
                        <div className="">
                          <h6>Address :</h6>
                          <div className="d-flex mb-3">
                            <p>
                              {Data.address}
                            </p>
                          </div>
                          {/* <div className="text-center mt-3">
                            <p className="text-danger">remove</p>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>

                      <img src="./assets/images/icon/empty-folder.png"></img>
                      <span className="m-2 feilds">No Address Found</span>
                    </>
                  )
                }

                <div className="">
                  <span
                    className="Additems"
                    onClick={() => (setModal(true), setIsOpenBy(2))}
                  >
                    <i className="fa-solid fa-plus"></i>
                    <span>Edit Address</span>
                  </span>
                </div>
              </div>

              <div className="address-book">
                <h6 className="">Banks List</h6>
                {
                  Data.bank_info?.length > 0 ? Data.bank_info.map((item, index) =>
                    <div>
                      <div className="card profileeditcard mt-2">
                        <div className="mb-3">
                          <h6>Nickname</h6>
                          <p>{item.account_holder_name}</p>
                        </div>
                        <div className="d-flex mb-3 ">
                          <div className="d-block">
                            <h6>Account Number :</h6>
                            <div className=" mb-3">
                              <p>{item.account_no}</p>
                            </div>
                          </div>
                          <div className="d-block">
                            <h6>IFSC Code:</h6>
                            <div className=" mb-3">
                              <p>{item.ifsc_code}</p>
                            </div>
                          </div>
                          <div className="d-block">
                            <h6>Country</h6>
                            <div className=" mb-3">
                              <p>{item.country_name}</p>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mb-3 justify-content-center" onClick={() => (setShowDialog(true), setUser_bank_detail_id(item.user_bank_detail_id))}>
                          <button className="text-danger removebutton">remove</button>
                        </div>
                      </div>
                    </div>
                  )

                    : (
                      <>
                        <img src="./assets/images/icon/empty-folder.png"></img>
                        <span className="m-2 feilds">No Address Found</span>
                      </>
                    )
                }

                <div className="">
                  <span
                    className="Additems"
                    onClick={() => (setModal(true), setIsOpenBy(3))}
                  >
                    <i className="fa-solid fa-plus"></i>
                    <span>Add Bank Account</span>
                  </span>
                </div>
              </div>
              <div className="address-book">
                <h6 className="">Password</h6>

                <div className="">
                  <span
                    className="Additems"
                    onClick={() => (setModal(true), setIsOpenBy(4))}
                  >
                    <i className="fa-solid fa-plus"></i>
                    <span>Change Password</span>
                  </span>
                </div>
              </div>
              <div className="address-book">
                <h6 className="">Two Factor Authentication</h6>
                <FormGroup>
                  <FormControlLabel control={<Switch defaultChecked />} />
                </FormGroup>
              </div>
              <div className="address-book">
                <h3 className="">Google Authenticator</h3>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      className="feilds"
                      value="female"
                      control={<Radio />}
                      label="Switched On"
                    />
                    <FormControlLabel
                      className="feilds"
                      value="male"
                      control={<Radio />}
                      label="Switched Off"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
}
