import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import DashboardHeader from "./DashboardHeader";
import Balance from "./Balance";
import { toast } from "react-toastify";
import formatDate from "../utils/Dateformat";
import TableLoader from "./TableLoader";
import apiService from "../services/ApiService";
import Pagination from "@mui/material/Pagination";

export default function Referral() {
  const [page, setPage] = useState(1);
  const [Data, setData] = useState([]);
  const [Loader, setLoader] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [perPageItem, setperPageItem] = useState(0);
  const [total,setTotal] = useState(0)

  const UserReferrals = async () => {
    try {
      setLoader(true);
      const res = await apiService().get(`userReferrals?page=${page}`);
      setLoader(false);
      setData(res.data.data);
      setTotalPage(res.data.lastPage);
      setperPageItem(res.data.perPage);
      setTotal(res.data.total)
    } catch (error) {
      setLoader(false);
      console.log(error);
      toast.error(error);
    }
  };

  const pageChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    UserReferrals();
  }, [page]);

  return (
    <div>
      <div className="notify-overlay" />
      <section className="dashboard-section">
        <Sidebar></Sidebar>
        <div className="dasboard-body">
          <div className="dashboard-hero">
            <div className="header-top">
              <div className="container">
                <div className="mobile-header d-flex justify-content-between d-lg-none align-items-center">
                  <div className="author">
                    <img
                      src="./assets/images/dashboard/author.png"
                      alt="dashboard"
                    />
                  </div>
                  <div className="cross-header-bar">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
                <DashboardHeader></DashboardHeader>
              </div>
            </div>
            <div className="dashboard-hero-content text-white pb-1">
              <h3 className="title">Referrals </h3>
              <ul className="breadcrumb">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Referrals</li>
              </ul>
            </div>
          </div>
          <div className="container-fluid">
            {/* <Balance /> */}
            <div className="partners">
              <h3 className="main-title">Referrals </h3>
              <div className="referral-group">
                <div className="refers">
                  <div className="referral-links">
                    <div className="oh">
                      <div className="referral-left">
                        <span className="left-icon">
                          <i className="fas fa-link"></i>
                        </span>
                        <h6>Referral Link:</h6>
                        <div className="copy-button">
                          <a
                            onClick={() => (
                              navigator.clipboard.writeText(
                                localStorage.getItem("referral_code")
                              ),
                              toast.success("Referral code copied!")
                            )}
                            className="custom-button"
                            id="copy"
                          >
                            Copy Link
                          </a>
                        </div>
                        <input
                          type="text"
                          id="copyLinks"
                          readonly=""
                          value={localStorage.getItem("referral_code") || ""}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-30-none">
                <div className="col-lg-6">
                  <div className="earn-item mb-30">
                    <div className="earn-thumb">
                      <img
                        src="./assets/images/dashboard/earn/03.png"
                        alt="dashboard-earn"
                      />
                    </div>
                    <div className="earn-content">
                      <h6 className="title">Earned Referral</h6>
                      <ul className="mb--5">
                        <li>
                          <div className="icon">
                            <img
                              src="./assets/images/icon/Tether-USDT-icon.png"
                              alt="dashboard-earn"
                              width={"33px"}
                            />
                          </div>
                          <div className="cont">
                            <span className="cl-1">0.00</span>
                            <span className="cl-4">USDT</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="earn-item mb-30">
                    <div className="earn-thumb">
                      <img
                        src="./assets/images/dashboard/earn/08.png"
                        alt="dashboard-earn"
                      />
                    </div>
                    <div className="earn-content">
                      <h6 className="title">
                        Number of clicks on the referral link
                      </h6>
                      <div className="click-number">
                        <p>
                      {total}</p></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="partners">
              <h3 className="main-title">Your Referrals :</h3>
              <div className="table-wrapper">
                <table className="transaction-table">
                  <thead>
                    <tr>
                      <th className="text-truncate" >Sr.No.</th>
                      <th className="text-truncate">DATE</th>
                      <th className="text-truncate">Level</th>
                      <th className="text-truncate">Username</th>
                      <th className="text-truncate">E-mail</th>
                      <th className="text-truncate">Invested AMT</th>
                      <th className="text-truncate">Referral AMT</th>
                      <th className="text-truncate">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!Loader && Data?.length > 0 ? (
                      Data.map((item, idx) => (
                        <tr key={idx}>
                          <td> {page * perPageItem - perPageItem + idx + 1}</td>
                          <td>{formatDate(item.date)}</td>
                          <td>User</td>
                          <td>{item.name}</td>
                          <td>
                            <a href={`Mailto:${item.name}`}>
                              {item.email}
                              <span
                                className="__cf_email__"
                                data-cfemail="d6efeee296b1bbb7bfbaf8b5b9bb"
                              ></span>
                            </a>
                          </td>

                          <td>
                            <img
                              src="./assets/images/dashboard/earn/btc2.png"
                              alt="dashboard-earn"
                              width={"20"}
                            />{" "}
                            {item.total_invested_amount}
                          </td>
                          <td>
                            <img
                              src="./assets/images/dashboard/earn/btc2.png"
                              alt="dashboard-earn"
                              width={"20"}
                            />{" "}
                            {item.referral_amount}
                          </td>
                          <td>
                            <button type="submit" className="custom-button">
                              Withdraw
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="8">
                          {!Loader && Data?.length == 0 ? (
                            "No data available"
                          ) : (
                            <TableLoader />
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {!Loader && Data?.length > 0 ? (
                  <Pagination
                    page={page}
                    count={totalPage}
                    onChange={pageChange}
                  />
                ) : null}
              </div>
             
            </div>
          </div>
          <div className="container-fluid sticky-bottom">
            <div className="dashboard-footer">
              <div className="d-flex flex-wrap justify-content-between m-0-15-none">
                <div className="left">
                  © 2023 <span className="version-09">version - 0.9</span>{" "}
                  &nbsp;&nbsp;&nbsp; <a href="#0">WYscale</a> | All right
                  reserved.
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
