import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import loadjs from "loadjs";
import Sidebar from "./Sidebar";
import DashboardHeader from "./DashboardHeader";
import Tab from "react-bootstrap/Tab";
import Modals from "./Modals";
import Tabs from "react-bootstrap/Tabs";
import Pagination from "@mui/material/Pagination";
import { toast } from "react-toastify";
import formatDate from "../utils/Dateformat";
import TableLoader from "./TableLoader";
import apiService from "../services/ApiService";
import Balance from "./Balance";

export default function Earlymaturity() {
  const [key, setKey] = useState("1");
  const [openModal, setModal] = useState(false);
  const [page, setPage] = useState(1);
  const [Data, setData] = useState([]);
  const [Loader, setLoader] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [userPlanId, setUserPlanId] = useState(0);
  const [PlanList, setPlanList] = useState([]);
  const [perPageItem, setperPageItem] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    GetplanList();
  }, []);

  const fetchUserPlanList = async () => {
    try {
      setLoader(true);
      const res = await apiService().get(
        `EarlyMatureUserPlanList?page=${page}&plan_id=${key}`
      );
      setLoader(false);
      setTotalPage(res.data.lastPage);
      setData(res.data.data);
      setperPageItem(res.data.perPage);
    } catch (error) {
      console.log(error);
      setLoader(false);
      toast.error(error);
    }
  };

  const GetplanList = async () => {
    try {
      setLoader(true);
      const res = await apiService().get(`planList`);
      setLoader(false);
      setPlanList(res.data.data);
      setKey(res.data.data[0].plan_id.toString());
    } catch (error) {
      setLoader(false);
      console.log(error);
      toast.error(error);
    }
  };

  const pageChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    if (key === 0) return;
    fetchUserPlanList();
  }, [page, key]);

  return (
    <>
      {openModal === true ? (
        <Modals
          open={true}
          setModal={setModal}
          id="7"
          userPlanId={userPlanId}
        />
      ) : null}
      
      <div>
        <div className="notify-overlay" />
        <section className="dashboard-section">
          <Sidebar></Sidebar>
          <div className="dasboard-body">
            <div className="dashboard-hero">
              <div className="header-top">
                <div className="container">
                  <div className="mobile-header d-flex justify-content-between d-lg-none align-items-center">
                    <div className="author">
                      <img
                        src="./assets/images/dashboard/author.png"
                        alt="dashboard"
                      />
                    </div>
                    <div className="cross-header-bar">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                  <DashboardHeader></DashboardHeader>
                </div>
              </div>
              <div className="dashboard-hero-content text-white pb-1">
                <h3 className="title">Early Maturity</h3>
                <ul className="breadcrumb">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Early Maturity</li>
                </ul>
              </div>
            </div>
            <div className="container-fluid">
             
              <div className="operations">
                <h3 className="main-title">Early Maturity</h3>
                {/* <form className="operation-filter">
                <div className="filter-item">
                  <label for="date">Date from:</label>
                  <input type="date" placeholder="Date from" />
                </div>
                <div className="filter-item">
                  <label for="date">Date To:</label>
                  <input type="date" placeholder="Date from" />
                </div>
                <div className="filter-item">
                  <label>Operation:</label>
                  <div className="select-item">
                    <select className="select-bar" >
                      <option value="o1">Add funds</option>
                      <option value="o2">Withdraw funds</option>
                      <option value="o4">Deposit funds</option>
                      <option value="o3">Transfer funds</option>
                    </select>
                    <div className="nice-select select-bar" tabindex="0">
                      <span className="current">Add funds</span>
                      <ul className="list">
                        <li data-value="o1" className="option selected">
                          Add funds
                        </li>
                        <li data-value="o2" className="option">
                          Withdraw funds
                        </li>
                        <li data-value="o4" className="option">
                          Deposit funds
                        </li>
                        <li data-value="o3" className="option">
                          Transfer funds
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="filter-item">
                  <label>Status:</label>
                  <div className="select-item">
                    <select className="select-bar" >
                      <option value="p1">Prepared</option>
                      <option value="p2">Prepared</option>
                      <option value="p3">Prepared</option>
                      <option value="p4">Prepared</option>
                      <option value="p5">Prepared</option>
                      <option value="p6">Prepared</option>
                    </select>
                    <div className="nice-select select-bar" tabindex="0">
                      <span className="current">Prepared</span>
                      <ul className="list">
                        <li data-value="p1" className="option selected">
                          Prepared
                        </li>
                        <li data-value="p2" className="option">
                          Prepared
                        </li>
                        <li data-value="p3" className="option">
                          Prepared
                        </li>
                        <li data-value="p4" className="option">
                          Prepared
                        </li>
                        <li data-value="p5" className="option">
                          Prepared
                        </li>
                        <li data-value="p6" className="option">
                          Prepared
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="filter-item">
                  <button type="submit" className="custom-button">
                    Filter
                  </button>
                </div>
              </form> */}

                <div className="mt-2">
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                  >
                    {PlanList.map((item, idx) => (
                      <Tab
                        key={idx}
                        eventKey={item.plan_id}
                        title={item.name}
                        width="50"
                      ></Tab>
                    ))}
                  </Tabs>

                  <div className="row">
                    <div className="col-md-9">
                      <div className="account-body">
                        <div className="table-wrapper">
                          <table className="transaction-table">
                            <thead>
                              <tr>
                                <th className="text-truncate" >Sr.No.</th>
                                <th className=" text-truncate">Plan Name</th>
                                <th className="  text-truncate">Start Date</th>
                                <th className=" text-truncate">End Date</th>

                                <th className="text-truncate">Principle AMT</th>
                                <th className="text-truncate">
                                  Received Intrest 
                                </th>
                                <th className="text-truncate">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {!Loader && Data.length > 0 ? (
                                Data.map((item, idx) => (
                                  <tr key={idx}>
                                    <td>
                                      {page * perPageItem -
                                        perPageItem +
                                        idx +
                                        1}
                                    </td>
                                    <td
                                      onClick={() => (
                                        setUserPlanId(item.user_plan_id),
                                        setModal(true)
                                      )}
                                      className="planduration text-truncate"
                                    >
                                      <i className="fa-solid fa-language"></i>
                                      {item.plan_name}
                                    </td>
                                    <td className="text-truncate">
                                      {formatDate(item.start_date)}
                                    </td>
                                    <td className="text-truncate">
                                      {" "}
                                      {formatDate(item.end_date)}
                                    </td>
                                    <td className="text-truncate">
                                      ${item.amount}
                                    </td>
                                    <td className="text-truncate">
                                      ${item.amount_received}
                                    </td>
                                    <td
                                      onClick={() => (
                                        setUserPlanId(item.user_plan_id),
                                        setModal(true)
                                      )}
                                      className="planduration text-truncate text-info"
                                    >
                                      Check Maturity
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="7">
                                    {!Loader && Data?.length == 0 ? (
                                      "No data available"
                                    ) : (
                                      <TableLoader />
                                    )}
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                          {!Loader && Data?.length > 0 ? (
                            <Pagination
                              page={page}
                              count={totalPage}
                              onChange={pageChange}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      {(() => {
                        switch (key) {
                          case "1":
                            return (
                              <>
                                <h5>Early Maturity deduction Slab</h5>
                                <div className="card mt-3 p-2">
                                  <div className="maturity d-flex">
                                    <h6>weekly Deduction :</h6>
                                    <h6>5%</h6>
                                  </div>
                                </div>
                              </>
                            );
                          case "2":
                            return (
                              <>
                                <h5>Early Maturity deduction Slab</h5>
                                <div className="card mt-3 p-2">
                                  <div className="maturity d-flex">
                                    <h6>Monthly Deduction :</h6>
                                    <h6>20%</h6>
                                  </div>
                                </div>
                              </>
                            );
                          case "3":
                            return (
                              <>
                                <h5>Early Maturity deduction Slab</h5>
                                <div className="card mt-3">
                                  <table className="transaction-table Maturity-table">
                                    <thead>
                                      <tr>
                                        <th className="text-truncate  Maturity-table">
                                          Months
                                        </th>
                                        <th className="text-truncate  Maturity-table">
                                          deduction %
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td className="planduration text-truncate">
                                          Within 3 Months
                                        </td>
                                        <td className="text-truncate">90%</td>
                                      </tr>
                                      <tr>
                                        <td className="planduration text-truncate">
                                          Within 6 Months
                                        </td>
                                        <td className="text-truncate">80%</td>
                                      </tr>
                                      <tr>
                                        <td className="planduration text-truncate">
                                          Within 9 Months
                                        </td>
                                        <td className="text-truncate">70%</td>
                                      </tr>
                                      <tr>
                                        <td className="planduration text-truncate">
                                          Within 12 Months
                                        </td>
                                        <td className="text-truncate">60%</td>
                                      </tr>
                                      <tr>
                                        <td className="planduration text-truncate">
                                          Within 15 Months
                                        </td>
                                        <td className="text-truncate">50%</td>
                                      </tr>
                                      <tr>
                                        <td className="planduration text-truncate">
                                          Within 18 Months
                                        </td>
                                        <td className="text-truncate">40%</td>
                                      </tr>
                                      <tr>
                                        <td className="planduration text-truncate">
                                          Within 21 Months
                                        </td>
                                        <td className="text-truncate">30%</td>
                                      </tr>
                                      <tr>
                                        <td className="planduration text-truncate">
                                          Within 21 - 23 Months
                                        </td>
                                        <td className="text-truncate">20%</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </>
                            );
                        }
                      })()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid sticky-bottom">
              <div className="dashboard-footer">
                <div className="d-flex flex-wrap justify-content-between m-0-15-none">
                  <div className="left">
                    © 2023 <span className="version-09">version - 0.9</span>{" "}
                    &nbsp;&nbsp;&nbsp; <a href="#0">WYscale</a> | All right
                    reserved.
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
