import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header2 from "./Header2";
import loadjs from "loadjs";
export default function Investmentplan() {
  useEffect(() => {
    loadjs("./assets/js/main.js", () => {});
  }, []);
  return (
    <>
      <div>
        <Header2></Header2>
        {/* header */}

        {/*=======Banner-Section Starts Here=======*/}
        <section className="hero-section-2">
          {/* <div className="hero-shape">
<img
className="wow slideInUp"
src="./assets/images/about/hero-shape1.png"
alt="about"
data-wow-duration="1s"
/>
</div> */}
        </section>
        {/*=======Banner-Section Ends Here=======*/}
        <section
          className="get-section padding-top "
         
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12 col-lg-10">
                <div className="card">
                  <h3>Your WYscale Portfolio</h3>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-4 ">
                      <div className="grow bg-white rounded-xl border border-gray-2100 p-3 text-center">
                        <p className="text-xl text-purple-1000">
                          Invested Value
                        </p>

                        <p className="font-semibold">$86.41</p>
                      </div>
                      <div className="grow bg-white rounded-xl border border-gray-2100 p-3 text-center">
                        <p
                          className="text-xl text-purple-1000"
                          style={{ color: "#ffb100" }}
                        >
                          Current Value
                        </p>

                        <p className="font-semibold">$86.63</p>
                      </div>
                      <div className="grow bg-white rounded-xl border border-gray-2100 p-3 text-center">
                        <p
                          className="text-xl text-purple-1000"
                          style={{ color: "green" }}
                        >
                          Gain
                        </p>

                        <p className="font-semibold">-0.00%</p>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <img
                        src="https://www150.statcan.gc.ca/edu/power-pouvoir/c-g/c-g05-5-1-eng.png"
                        style={{ width: "100%" }}
                      ></img>
                    </div>
                  </div>
                  <div className="card-footer bg-white">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-4 m-auto">
                        <div className="flex gap-2 items-center">
                          <img
                            src="https://d7uawb6sjrgc8.cloudfront.net/icons/secure.svg"
                            alt="secure"
                          />
                          <p className="text-sm opacity-60">
                            Fully Compliant &amp; Secure
                          </p>
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-4 m-auto ">
                        <div className="flex gap-2 items-center">
                          <img
                            src="https://d7uawb6sjrgc8.cloudfront.net/icons/bank-transfer.svg"
                            alt="secure"
                          />
                          <p className="text-sm opacity-60">
                            Easy Bank Transfers
                          </p>
                        </div>{" "}
                      </div>
                      <div className="col-12 col-sm-12 col-md-4">
                        <div className="w-full md:w-auto flex justify-between md:justify-start items-start gap-4">
                          <div className="w-3/4 md:w-[240px] mx-auto">
                            <button type="button" className="custom-button">
                              Invest Now
                            </button>
                            <div className="flex justify-center pt-1 justify-content-center">
                              <a
                                href="https://wa.me/919958048899?text=Hi%20Team%20Heru,%20I%20have%20some%20queries&amp;locale=en"
                                target="_blank"
                                rel="noreferrer"
                                className="text-purple-1000  text-sm underline"
                              >
                                Talk to Investment Advisor
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section
              className="offer-section padding-top  pb-max-md-0"
              id="plan"
          >
             
              <div className="container">
                  <div className="row justify-content-center">
                      <div className="col-lg-8 col-xl-7">
                          <div className="section-header padding-bottom">
                              <span className="cate">Strategy Breakdown</span>
                              
                              <p>Your portfolio is diversified across 2 strategies</p>
                          </div>
                      </div>
                  </div>
                  <div className="offer-wrapper">
                      <div className="">
                          <div className="row">
                              <div className="col-md-4">
                                  <div className="card strategycard bg-white z-1 cursor-pointer rounded-3xl px-6 pt-[10px]  w-full relative shadow-4xl">
                                      <div className="card-header1">
                                          <img
                                              src="https://d2nypvkdltc2i5.cloudfront.net/basket_images/btc_options_fixed_income.png"
                                              style={{ width: "60px" }}
                                              className="strategyimage"
                                          ></img>
                                      </div>
                                      <div className="card-body text-center mt-3">
                                          <h6>Lowest Risk, Good Returns</h6>
                                          <p className="pp">
                                              Risk-Less arbitrage strategy offering Capital Protection
                                          </p>
                                          <div className="mt-3 mb-4 py-3 border-t border-b border-black border-opacity-10 flex justify-center text-center gap-10">
                                              <div className="right-border" >
                                                  <p className="text-xs opacity-60">% Investment</p>
                                                  <p className="font-semibold">60%</p>
                                              </div>
                                              <div>
                                                  <p className="text-xs opacity-60">1Y return</p>
                                                  <p className="font-semibold">29.75%</p>
                                              </div>
                                          </div>
                                          <div className="flex justify-center justify-content-center">
                                              <Link to='/Innerstrategy'
                                                  className="text-purple-1000 font-bold text-sm"
                                                  style={{ color: "#ea5050;" }}
                                              >
                                                  View Strategy <i className="flaticon-right"></i>
                                              </Link>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-md-4">
                                  <div className="card strategycard bg-white z-1 cursor-pointer rounded-3xl px-6 pt-[10px]  w-full relative shadow-4xl">
                                      <div className="card-header1">
                                          <img
                                              src="https://tykhe-static.s3.ap-south-1.amazonaws.com/layer1.png"
                                              style={{ width: "60px" }}
                                              className="strategyimage"
                                          ></img>
                                      </div>
                                      <div className="card-body text-center mt-3">
                                          <h6>Stable Growth Fund</h6>
                                          <p className="pp">
                                              Generate 15-20% yield on your stable coins
                                          </p>
                                          <div className="mt-3 mb-4 py-3 border-t border-b border-black border-opacity-10 flex justify-center text-center gap-10">
                                              <div className="right-border">
                                                  <p className="text-xs opacity-60">% Investment</p>
                                                  <p className="font-semibold">40%</p>
                                              </div>
                                              <div>
                                                  <p className="text-xs opacity-60">1Y return</p>
                                                  <p className="font-semibold">14.86%</p>
                                              </div>
                                          </div>
                                          <div className="flex justify-center justify-content-center">
                                          <Link to='/Innerstrategy'
                                                  className="text-purple-1000 font-bold text-sm"
                                                  style={{ color: "#ea5050;" }}
                                              >
                                                  View Strategy <i className="flaticon-right"></i>
                                              </Link>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section> */}
        {/*=======Feature-Section Starts Here=======*/}
        <section className="offer-section padding-top  pb-max-md-0" id="plan">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-xl-7">
                <div className="section-header">
                  <span className="cate">Explore all strategies</span>
                  {/* <h2 className="title">OUR INVESTMENT PLANS</h2> */}
                </div>
              </div>
            </div>
            {/* <div className="offer-wrapper">
                      <div className="offer-item">
                          <div className="row">
                              <div className=" col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 ">
                                  <div className="row offer-header1">
                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                          <h6>DeFi Innovation</h6>
                                          <p>
                                              Generate long-term wealth by investing in Decentralized
                                              Finance Fund that is actively managed
                                          </p>
                                      </div>
                                  </div>
                                  <div className="row offer-header1 py-0">
                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                          <div className="row">
                                              <div className=" col-6 col-sm-6  col-md-2 col-lg-2 col-xl-2">
                                                  <div className="w-[calc(50%_-_12px)]">
                                                      <div className="bg-gray-3000 rounded-xl py-2 px-3 w-full">
                                                          <p className="text-xxs opacity-60">
                                                              Since Inception return
                                                          </p>
                                                          <div className="flex items-center gap-1 font-semibold text-green-1200">
                                                              <p>56.43%</p>
                                                              <svg
                                                                  stroke="currentColor"
                                                                  fill="currentColor"
                                                                  strokeWidth="0"
                                                                  viewBox="0 0 1024 1024"
                                                                  height="1em"
                                                                  width="1em"
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                              >
                                                                  <path d="M868 545.5L536.1 163a31.96 31.96 0 0 0-48.3 0L156 545.5a7.97 7.97 0 0 0 6 13.2h81c4.6 0 9-2 12.1-5.5L474 300.9V864c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V300.9l218.9 252.3c3 3.5 7.4 5.5 12.1 5.5h81c6.8 0 10.5-8 6-13.2z"></path>
                                                              </svg>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="col-6 col-sm-6  col-md-2 col-lg-2 col-xl-2">
                                                  <div className="w-[calc(50%_-_12px)]">
                                                      <div className="bg-gray-3000 rounded-xl py-2 px-3 w-full">
                                                          <p className="text-xxs opacity-60">
                                                              Since Inception return
                                                          </p>
                                                          <div className="flex items-center gap-1 font-semibold text-green-1200">
                                                              <p>56.43%</p>
                                                              <svg
                                                                  stroke="currentColor"
                                                                  fill="currentColor"
                                                                  strokeWidth="0"
                                                                  viewBox="0 0 1024 1024"
                                                                  height="1em"
                                                                  width="1em"
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                              >
                                                                  <path d="M868 545.5L536.1 163a31.96 31.96 0 0 0-48.3 0L156 545.5a7.97 7.97 0 0 0 6 13.2h81c4.6 0 9-2 12.1-5.5L474 300.9V864c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V300.9l218.9 252.3c3 3.5 7.4 5.5 12.1 5.5h81c6.8 0 10.5-8 6-13.2z"></path>
                                                              </svg>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="col-6 col-sm-6  col-md-2 col-lg-2 col-xl-2">
                                                  <div className="w-[calc(50%_-_12px)]">
                                                      <div className="bg-gray-3000 rounded-xl py-2 px-3 w-full">
                                                          <p className="text-xxs opacity-60">
                                                              Since Inception return
                                                          </p>
                                                          <div className="flex items-center gap-1 font-semibold text-green-1200">
                                                              <p>56.43%</p>
                                                              <svg
                                                                  stroke="currentColor"
                                                                  fill="currentColor"
                                                                  strokeWidth="0"
                                                                  viewBox="0 0 1024 1024"
                                                                  height="1em"
                                                                  width="1em"
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                              >
                                                                  <path d="M868 545.5L536.1 163a31.96 31.96 0 0 0-48.3 0L156 545.5a7.97 7.97 0 0 0 6 13.2h81c4.6 0 9-2 12.1-5.5L474 300.9V864c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V300.9l218.9 252.3c3 3.5 7.4 5.5 12.1 5.5h81c6.8 0 10.5-8 6-13.2z"></path>
                                                              </svg>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="col-6 col-sm-6  col-md-2 col-lg-2 col-xl-2">
                                                  <div className="w-[calc(50%_-_12px)]">
                                                      <div className="bg-gray-3000 rounded-xl py-2 px-3 w-full">
                                                          <p className="text-xxs opacity-60">
                                                              Since Inception return
                                                          </p>
                                                          <div className="flex items-center gap-1 font-semibold text-green-1200">
                                                              <p>56.43%</p>
                                                              <svg
                                                                  stroke="currentColor"
                                                                  fill="currentColor"
                                                                  strokeWidth="0"
                                                                  viewBox="0 0 1024 1024"
                                                                  height="1em"
                                                                  width="1em"
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                              >
                                                                  <path d="M868 545.5L536.1 163a31.96 31.96 0 0 0-48.3 0L156 545.5a7.97 7.97 0 0 0 6 13.2h81c4.6 0 9-2 12.1-5.5L474 300.9V864c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V300.9l218.9 252.3c3 3.5 7.4 5.5 12.1 5.5h81c6.8 0 10.5-8 6-13.2z"></path>
                                                              </svg>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="col-6 col-sm-6  col-md-2 col-lg-2 col-xl-2">
                                                  <div className="w-[calc(50%_-_12px)]">
                                                      <div className="bg-gray-3000 rounded-xl py-2 px-3 w-full">
                                                          <p className="text-xxs opacity-60">
                                                              Since Inception return
                                                          </p>
                                                          <div className="flex items-center gap-1 font-semibold text-green-1200">
                                                              <p>56.43%</p>
                                                              <svg
                                                                  stroke="currentColor"
                                                                  fill="currentColor"
                                                                  strokeWidth="0"
                                                                  viewBox="0 0 1024 1024"
                                                                  height="1em"
                                                                  width="1em"
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                              >
                                                                  <path d="M868 545.5L536.1 163a31.96 31.96 0 0 0-48.3 0L156 545.5a7.97 7.97 0 0 0 6 13.2h81c4.6 0 9-2 12.1-5.5L474 300.9V864c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V300.9l218.9 252.3c3 3.5 7.4 5.5 12.1 5.5h81c6.8 0 10.5-8 6-13.2z"></path>
                                                              </svg>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="row offer-header1 pb-0">
                                      <div className="col-md-12 d-flex">
                                          <img
                                              src="./assets/images/icon/AAVE.png"
                                              className="w-8 h-8 rounded-lg"
                                          ></img>
                                          <img
                                              src="./assets/images/icon/LINK.png"
                                              className="w-8 h-8 rounded-lg"
                                          ></img>
                                          <img
                                              src="./assets/images/icon/MKR.png"
                                              className="w-8 h-8 rounded-lg"
                                          ></img>
                                          <img
                                              src="./assets/images/icon/UNI.png"
                                              className="w-8 h-8 rounded-lg"
                                          ></img>
                                          <img
                                              src="./assets/images/icon/USDC.png"
                                              className="w-8 h-8 rounded-lg"
                                          ></img>
                                      </div>
                                  </div>
                                  <div className="row offer-header1 py-2">
                                      <div className="col-md-12 d-flex">
                                          <p>
                                              Earn up to <b>$100.00 </b> per month for every $1,000
                                              invested.
                                          </p>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-md-3">
                                  <div className="offer-footer">
                                      <a href="#0" className="custom-button">
                                          invest now
                                      </a>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div> */}
          </div>
        </section>
        <section
          className="offer-section padding-top padding-bottom pb-max-md-0"
          id="plan"
        >
          <div className="container">
            <div className="row">
              <div className=" col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
                <div className="card fundcard">
                  <div className="card-header1">
                    <img
                      src="https://d2nypvkdltc2i5.cloudfront.net/basket_images/defi_innovation.png"
                      className="strategyimage"
                      style={{ width: "60px" }}
                    ></img>
                  </div>
                  <div className="row offer-header1">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0">
                      <h6>DeFi Innovation</h6>
                      <p>
                        Generate long-term wealth by investing in Decentralized
                        Finance Fund that is actively managed
                      </p>
                    </div>
                  </div>
                  <div className="row offer-header1 py-0">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0">
                      <div className="row">
                        <div className=" col-6 col-sm-6  col-md-6 col-lg-6 col-xl-6 ">
                          <div className="w-[calc(50%_-_12px)]">
                            <div className="bg-gray-3000 rounded-xl py-2 px-2 w-full">
                              <p className="text-xxs opacity-60">
                                Since Inception return
                              </p>
                              <div className="flex items-center gap-1 font-semibold text-green-1200">
                                <span className="fundcard-span">56.43%</span>
                                <svg
                                  stroke="currentColor"
                                  fill="currentColor"
                                  stroke-width="0"
                                  viewBox="0 0 1024 1024"
                                  height="1em"
                                  width="1em"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M868 545.5L536.1 163a31.96 31.96 0 0 0-48.3 0L156 545.5a7.97 7.97 0 0 0 6 13.2h81c4.6 0 9-2 12.1-5.5L474 300.9V864c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V300.9l218.9 252.3c3 3.5 7.4 5.5 12.1 5.5h81c6.8 0 10.5-8 6-13.2z"></path>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className=" col-6 col-sm-6  col-md-6 col-lg-6 col-xl-6">
                          <div className="w-[calc(50%_-_12px)]">
                            <div className="bg-gray-3000 rounded-xl py-2 px-2 w-full">
                              <p className="text-xxs opacity-60">
                                Since Inception return
                              </p>
                              <div className="flex items-center gap-1 font-semibold text-green-1200">
                                <span className="fundcard-span">56.43%</span>
                                <svg
                                  stroke="currentColor"
                                  fill="currentColor"
                                  stroke-width="0"
                                  viewBox="0 0 1024 1024"
                                  height="1em"
                                  width="1em"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M868 545.5L536.1 163a31.96 31.96 0 0 0-48.3 0L156 545.5a7.97 7.97 0 0 0 6 13.2h81c4.6 0 9-2 12.1-5.5L474 300.9V864c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V300.9l218.9 252.3c3 3.5 7.4 5.5 12.1 5.5h81c6.8 0 10.5-8 6-13.2z"></path>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row offer-header1 pb-0">
                    <div className="col-md-12 d-flex p-0 justify-content-center">
                      <img
                        src="./assets/images/icon/AAVE.png"
                        className="w-8 h-8 rounded-lg"
                      ></img>
                      <img
                        src="./assets/images/icon/LINK.png"
                        className="w-8 h-8 rounded-lg"
                      ></img>
                      <img
                        src="./assets/images/icon/MKR.png"
                        className="w-8 h-8 rounded-lg"
                      ></img>
                      <img
                        src="./assets/images/icon/UNI.png"
                        className="w-8 h-8 rounded-lg"
                      ></img>
                      <img
                        src="./assets/images/icon/USDC.png"
                        className="w-8 h-8 rounded-lg"
                      ></img>
                    </div>
                  </div>
                  <div className="row offer-header1 py-2">
                    {/* <div className="col-md-12 d-flex">
                                      <p>
                                          Earn up to <b>$100.00 </b> per month for every $1,000
                                          invested.
                                      </p>
                                  </div> */}
                    <div className="offer-footer mt-3 mb-2 w-100">
                      <div className="d-flex flex1">
                        <div className="d-block my-auto mx-0">
                          <p className="ppp">Current AUM:</p>
                          <p className="pppp">$68,516</p>
                        </div>

                        <Link to="/Innerstrategy" className="investa">
                          View Details<i className="flaticon-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
                <div className="card fundcard">
                  <div className="card-header1">
                    <img
                      src="https://tykhe-static.s3.ap-south-1.amazonaws.com/layer1.png"
                      className="strategyimage"
                      style={{ width: "60px" }}
                    ></img>
                  </div>
                  <div className="row offer-header1">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0">
                      <h6>DeFi Innovation</h6>
                      <p>
                        Generate long-term wealth by investing in Decentralized
                        Finance Fund that is actively managed
                      </p>
                    </div>
                  </div>
                  <div className="row offer-header1 py-0">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0">
                      <div className="row">
                        <div className=" col-6 col-sm-6  col-md-6 col-lg-6 col-xl-6 ">
                          <div className="w-[calc(50%_-_12px)]">
                            <div className="bg-gray-3000 rounded-xl py-2 px-2 w-full">
                              <p className="text-xxs opacity-60">
                                Since Inception return
                              </p>
                              <div className="flex items-center gap-1 font-semibold text-green-1200">
                                <span className="fundcard-span">56.43%</span>
                                <svg
                                  stroke="currentColor"
                                  fill="currentColor"
                                  stroke-width="0"
                                  viewBox="0 0 1024 1024"
                                  height="1em"
                                  width="1em"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M868 545.5L536.1 163a31.96 31.96 0 0 0-48.3 0L156 545.5a7.97 7.97 0 0 0 6 13.2h81c4.6 0 9-2 12.1-5.5L474 300.9V864c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V300.9l218.9 252.3c3 3.5 7.4 5.5 12.1 5.5h81c6.8 0 10.5-8 6-13.2z"></path>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className=" col-6 col-sm-6  col-md-6 col-lg-6 col-xl-6">
                          <div className="w-[calc(50%_-_12px)]">
                            <div className="bg-gray-3000 rounded-xl py-2 px-2 w-full">
                              <p className="text-xxs opacity-60">
                                Since Inception return
                              </p>
                              <div className="flex items-center gap-1 font-semibold text-green-1200">
                                <span className="fundcard-span">56.43%</span>
                                <svg
                                  stroke="currentColor"
                                  fill="currentColor"
                                  stroke-width="0"
                                  viewBox="0 0 1024 1024"
                                  height="1em"
                                  width="1em"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M868 545.5L536.1 163a31.96 31.96 0 0 0-48.3 0L156 545.5a7.97 7.97 0 0 0 6 13.2h81c4.6 0 9-2 12.1-5.5L474 300.9V864c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V300.9l218.9 252.3c3 3.5 7.4 5.5 12.1 5.5h81c6.8 0 10.5-8 6-13.2z"></path>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row offer-header1 pb-0">
                    <div className="col-md-12 d-flex p-0 justify-content-center">
                      <img
                        src="./assets/images/icon/AAVE.png"
                        className="w-8 h-8 rounded-lg"
                      ></img>
                      <img
                        src="./assets/images/icon/LINK.png"
                        className="w-8 h-8 rounded-lg"
                      ></img>
                      <img
                        src="./assets/images/icon/MKR.png"
                        className="w-8 h-8 rounded-lg"
                      ></img>
                      <img
                        src="./assets/images/icon/UNI.png"
                        className="w-8 h-8 rounded-lg"
                      ></img>
                      <img
                        src="./assets/images/icon/USDC.png"
                        className="w-8 h-8 rounded-lg"
                      ></img>
                    </div>
                  </div>
                  <div className="row offer-header1 py-2">
                    {/* <div className="col-md-12 d-flex">
                                      <p>
                                          Earn up to <b>$100.00 </b> per month for every $1,000
                                          invested.
                                      </p>
                                  </div> */}
                    <div className="offer-footer mt-3 mb-2 w-100">
                      <div className="d-flex flex1">
                        <div className="d-block my-auto mx-0">
                          <p className="ppp">Current AUM:</p>
                          <p className="pppp">$68,516</p>
                        </div>

                        <Link to="/Innerstrategy" className="investa">
                          View Details<i className="flaticon-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
                <div className="card fundcard">
                  <div className="card-header1">
                    <img
                      src="https://d2nypvkdltc2i5.cloudfront.net/basket_images/eth_options_max_yield.png"
                      className="strategyimage"
                      style={{ width: "60px" }}
                    ></img>
                  </div>
                  <div className="row offer-header1">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0">
                      <h6>DeFi Innovation</h6>
                      <p>
                        Generate long-term wealth by investing in Decentralized
                        Finance Fund that is actively managed
                      </p>
                    </div>
                  </div>
                  <div className="row offer-header1 py-0">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0">
                      <div className="row">
                        <div className=" col-6 col-sm-6  col-md-6 col-lg-6 col-xl-6 ">
                          <div className="w-[calc(50%_-_12px)]">
                            <div className="bg-gray-3000 rounded-xl py-2 px-2 w-full">
                              <p className="text-xxs opacity-60">
                                Since Inception return
                              </p>
                              <div className="flex items-center gap-1 font-semibold text-green-1200">
                                <span className="fundcard-span">56.43%</span>
                                <svg
                                  stroke="currentColor"
                                  fill="currentColor"
                                  stroke-width="0"
                                  viewBox="0 0 1024 1024"
                                  height="1em"
                                  width="1em"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M868 545.5L536.1 163a31.96 31.96 0 0 0-48.3 0L156 545.5a7.97 7.97 0 0 0 6 13.2h81c4.6 0 9-2 12.1-5.5L474 300.9V864c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V300.9l218.9 252.3c3 3.5 7.4 5.5 12.1 5.5h81c6.8 0 10.5-8 6-13.2z"></path>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className=" col-6 col-sm-6  col-md-6 col-lg-6 col-xl-6">
                          <div className="w-[calc(50%_-_12px)]">
                            <div className="bg-gray-3000 rounded-xl py-2 px-2 w-full">
                              <p className="text-xxs opacity-60">
                                Since Inception return
                              </p>
                              <div className="flex items-center gap-1 font-semibold text-green-1200">
                                <span className="fundcard-span">56.43%</span>
                                <svg
                                  stroke="currentColor"
                                  fill="currentColor"
                                  stroke-width="0"
                                  viewBox="0 0 1024 1024"
                                  height="1em"
                                  width="1em"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M868 545.5L536.1 163a31.96 31.96 0 0 0-48.3 0L156 545.5a7.97 7.97 0 0 0 6 13.2h81c4.6 0 9-2 12.1-5.5L474 300.9V864c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V300.9l218.9 252.3c3 3.5 7.4 5.5 12.1 5.5h81c6.8 0 10.5-8 6-13.2z"></path>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row offer-header1 pb-0">
                    <div className="col-md-12 d-flex p-0 justify-content-center">
                      <img
                        src="./assets/images/icon/AAVE.png"
                        className="w-8 h-8 rounded-lg"
                      ></img>
                      <img
                        src="./assets/images/icon/LINK.png"
                        className="w-8 h-8 rounded-lg"
                      ></img>
                      <img
                        src="./assets/images/icon/MKR.png"
                        className="w-8 h-8 rounded-lg"
                      ></img>
                      <img
                        src="./assets/images/icon/UNI.png"
                        className="w-8 h-8 rounded-lg"
                      ></img>
                      <img
                        src="./assets/images/icon/USDC.png"
                        className="w-8 h-8 rounded-lg"
                      ></img>
                    </div>
                  </div>
                  <div className="row offer-header1 py-2">
                    {/* <div className="col-md-12 d-flex">
                                      <p>
                                          Earn up to <b>$100.00 </b> per month for every $1,000
                                          invested.
                                      </p>
                                  </div> */}
                    <div className="offer-footer mt-3 mb-2 w-100">
                      <div className="d-flex flex1">
                        <div className="d-block my-auto mx-0">
                          <p className="ppp">Current AUM:</p>
                          <p className="pppp">$68,516</p>
                        </div>

                        <Link to="/Innerstrategy" className="investa">
                          View Details<i className="flaticon-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
       
        {/*=======Feature-Section Ends Here=======*/}
      </div>
      <Footer />
    </>
  );
}
